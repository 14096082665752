import { useDispatch } from "react-redux";
import devicesInfo from "../helper/devicesInfos.json";
import {
  getBetweenParenthesis,
  getConnectedVideoDevices,
} from "../helper/mediaDeviceHelper";

import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { updateAvailableVideoDevices } from "../features/Examen/mediaDevicesSlice";

export const useAvailableCameras = () => {
  const dispatch = useDispatch();
  const [uncategorizedDevices, setUncategorizedDevices] = useState<any>([]);
  const [availableVideoDevices, setAvailableVideoDevices] = useState<any>({});

  const getKeyName = (obj: any): string => {
    return Object.keys(obj)[0];
  };
  useEffect(() => {
    // Check if mainCamera is set, if not, set one from uncategorizedDevices
    if (!availableVideoDevices.mainCamera && uncategorizedDevices.length > 0) {
      console.log("uncategorizedDevices", uncategorizedDevices);
      const mainCameraDevice = uncategorizedDevices[0];
      setAvailableVideoDevices((prev: any) => ({
        ...prev,
        mainCamera: mainCameraDevice.deviceId,
      }));
      dispatch(
        updateAvailableVideoDevices({
          category: "mainCamera",
          deviceId: mainCameraDevice.deviceId,
        })
      );
      console.log("Set as main camera:", uncategorizedDevices);
    } else if (!availableVideoDevices.mainCamera) {
      console.log("No available device could be set as main camera.");
    }
  }, [uncategorizedDevices, availableVideoDevices.mainCamera, dispatch]);


  const labelExists = (label: string) => {
    return devicesInfo.some((category) => {
      return Object.values(category).some((camerasArray) => {
        return camerasArray.some((camera: any) => camera.label.includes(label));
      });
    });
  };

  const handleDeviceChange = useCallback(async () => {
    try {
      const connectedDevices = await getConnectedVideoDevices("videoinput");
      const connectedDeviceIds = connectedDevices.map((device: any) => device.deviceId);

      // Remove devices that are no longer connected
      setAvailableVideoDevices((prev: any) => {
        const updatedDevices = { ...prev };
        Object.keys(prev).forEach((key) => {
          if (!connectedDeviceIds.includes(prev[key])) {
            updatedDevices[key] = null;
            dispatch(
              updateAvailableVideoDevices({
                category: key,
                deviceId: null,
              })
            );
          }
        });
        return updatedDevices;
      });

      // Categorize new connected devices
      let uncategorizedDevicesList = [];

      console.log("connectVideoDevices",connectedDevices)

      for (const device of connectedDevices) {
        const deviceLabel = getBetweenParenthesis(device.label);
        if (deviceLabel) {
          if (!labelExists(deviceLabel)) {
            uncategorizedDevicesList.push(device);
          } else {
            devicesInfo.forEach((category) => {
              Object.values(category).forEach((camerasArray: any) => {
                camerasArray.forEach((camera: any) => {
                  if (camera.label.includes(deviceLabel)) {
                    const categoryName = getKeyName(category);
                    setAvailableVideoDevices((prev: any) => ({
                      ...prev,
                      [categoryName]: device.deviceId,
                    }));
                    dispatch(
                      updateAvailableVideoDevices({
                        category: categoryName,
                        deviceId: device.deviceId,
                      })); 
                    console.log(
                      "[*]category",
                      categoryName,
                      "[*]deviceId",
                      device.deviceId
                    );
                  }
                });
              });
            });
          }
        }
      }

      setUncategorizedDevices(uncategorizedDevicesList);
    } catch (e) {
      console.log(e);
      toast.warn("Veuillez autoriser l'accès à la caméra");
    }
  }, [dispatch]);

  useEffect(() => {
    handleDeviceChange();
    if (navigator.mediaDevices && navigator.mediaDevices.addEventListener) {
      navigator.mediaDevices.addEventListener("devicechange", handleDeviceChange);
    } else {
      console.error("MediaDevices API is not supported in this browser.");
      toast.warning("veillez utiliser un navigateur compatible comme : Chrome");
    }

    return () => {
      if (navigator.mediaDevices && navigator.mediaDevices.removeEventListener) {
        navigator.mediaDevices.removeEventListener("devicechange", handleDeviceChange);
      }
    };
  }, [handleDeviceChange]);


  return { availableVideoDevices };
};
