import React, { useCallback, useState, useRef } from "react";
import { useResizeObserver } from "@wojtekmaj/react-hooks";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

import type { PDFDocumentProxy } from "pdfjs-dist";
import Loader from "./Loader";
import zoomInSvg from "../assets/zoomIn.svg";
import zoomOutSvg from "../assets/zoomOut.svg";
import resetView from "../assets/resetView.svg";
import nextPage from "../assets/nextPage.svg";
import prevPage from "../assets/prevPage.svg";
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
} from "react-zoom-pan-pinch";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

const options = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: "/standard_fonts/",
};

const resizeObserverOptions = {};

const maxWidth = 800;

type PDFFile = string | File | null;

export default function PdfViewer({ File }) {
  const [file, setFile] = useState<PDFFile>(File);
  const [numPages, setNumPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>();

  const onResize = useCallback<ResizeObserverCallback>((entries) => {
    const [entry] = entries;

    if (entry) {
      setContainerWidth(entry.contentRect.width);
    }
  }, []);

  const Controls = ({ zoomIn, zoomOut, resetTransform }) => (
    <>
      <div className="flex flex-col absolute gap-2 top-4 right-4 z-10">
        <button onClick={() => zoomIn()}>
          <img src={zoomInSvg} width={24} />
        </button>
        <button onClick={() => zoomOut()}>
          <img src={zoomOutSvg} width={24} />
        </button>
        <button onClick={() => resetTransform()} className="mt-2">
          <img src={resetView} width={24} />
        </button>


        <button onClick={handlePreviousPage}
                  disabled={currentPage === 1} className={currentPage===1?'opacity-50 cursor-not-allowed mt-2':'mt-2'} >
          <img src={prevPage} width={24} />
        </button>

        <button  onClick={handleNextPage}
                  disabled={currentPage === numPages} className={currentPage===numPages?'opacity-50 cursor-not-allowed':''} >
          <img src={nextPage} width={24} />
        </button>
      </div>
    </>
  );

  useResizeObserver(containerRef, resizeObserverOptions, onResize);

  function onFileChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const { files } = event.target;

    if (files && files[0]) {
      setFile(files[0] || null);
    }
  }

  function onDocumentLoadSuccess({
    numPages: nextNumPages,
  }: PDFDocumentProxy): void {
    setNumPages(nextNumPages);
  }

  function handleNextPage() {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, numPages));
  }

  function handlePreviousPage() {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  }
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);

  return (
    <TransformWrapper initialScale={1} ref={transformComponentRef} doubleClick={{mode:'toggle'}} pinch={{disabled:false}}  centerOnInit={true} limitToBounds={false}>
      {(utils) => (
        <React.Fragment>
          <Controls {...utils} />
          <TransformComponent wrapperClass="rounded-md border  !w-full !h-full  cursor-[zoom-in]" contentClass="!w-full !h-full ">
            <div
              className=" !w-full !h-full  pdfContent absolute"
              ref={setContainerRef}
            >
              <Document
                file={file}
                // onLoadSuccess={onDocumentLoadSuccess}
                options={options}
                loading={<Loader />}
              >
                <Page
                  key={`page_${currentPage}`}
                  pageNumber={currentPage}
                  width={
                    containerWidth
                      ? Math.min(containerWidth, maxWidth)
                      : maxWidth
                  }
                />
              </Document>
            </div>
          </TransformComponent>
        </React.Fragment>
      )}
    </TransformWrapper>
  );
}
