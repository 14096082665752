import React, { useEffect, useRef, useState } from "react";
import { app, role } from "../../enums/app";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AuthLogin, getBoxes, getUnits } from "../../axiosAPI/Network";
import { useDispatch, useSelector } from 'react-redux';
import { loginRequest, loginSuccess, setCredentials } from '../../features/auth/authSlice';

import { getDoctorSpecialite } from "../../axiosAPI/params";
import { setDoctorId } from "../../features/doctor/doctorSlice";
import { RootState } from "../../store";
import { useLoginMutation } from "../../features/auth";
import { toast } from "react-hot-toast";
import APP_CONSTANTS from "../../constants";
import { generateUniqueId } from "../../services/utils.service";
import { useGetNotificationsQuery } from "../../features/notifications/notificationsApi";
import { ForgotPassword } from "./ForgotPassword";
import { Divider } from "antd";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { Spinner } from "@material-tailwind/react";

const GlobalStyle = styled.div`
  width: 500px;
  height: 40rem;
  background: #ffffff;
  border-radius: 11.7554px;
  border-top: 12px solid #20a8d3;
  /* margin-left: 4rem; */
  margin-top: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  .content {
    width: 85%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 2rem;
    box-sizing: border-box;
    .title {
      font-family: "Ubuntu";
      font-style: normal;
      font-weight: 400;
      font-size: 27.0939px;
      line-height: 32px;
      color: #20a8d3;
    }
  }
`;

const IpnpuStyle = styled.input`
  background: #ffffff;
  border: 0.619965px solid #adbfcf;
  border-radius: 7.43958px;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18.5718px;
  line-height: 25px;
  color: #1c3935;
  padding-left: 10px;
`;

const ConexionButton = styled.button`
  background: linear-gradient(108.18deg, #1aa5c0 4.63%, #0ea1aa 122.22%);
  box-shadow: 0px 3.35869px 3.35869px rgba(0, 0, 0, 0.25);
  border-radius: 5.87771px;
  width: 26rem;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 20.5108px;
  line-height: 25px;
  color: #ffffff;
  box-sizing: border-box;

  ${(props: { isLoading: boolean }) =>
    props.isLoading &&
    `
      background: #ccc; /* Change this to the desired color when loading */
      cursor: not-allowed;
      /* Add any other styles you want when loading here */
    `}
`;

const AccessToDevicesButton = styled.button`
background: linear-gradient(108.18deg, #e25a24 4.63%, #aa350e 122.22%);
box-shadow: 0px 3.35869px 3.35869px rgba(0, 0, 0, 0.25);
border-radius: 5.87771px;
width: 26rem;
height: 60px;
display: flex;
align-items: center;
justify-content: center;
gap: 10px;
font-family: "Ubuntu";
font-style: normal;
font-weight: 500;
font-size: 20.5108px;
line-height: 25px;
color: #ffffff;
box-sizing: border-box;
`;

const Memorize = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  .checkbox-input {
    background: #ffffff;
    border: 0.619965px solid #adbfcf;
    border-radius: 7.43958px;
    width: 25px;
    height: 25px;
  }
  .title-memorize {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    color: #5e5e5e;
  }
`;

const InputLogin = () => {

  const nav = useNavigate();

  const dispatch = useDispatch();
  const accessLevels = useSelector((state: RootState) => state.auth.access);

  const currentApp = useSelector((state: RootState) => state.auth.currentApp);

  const [loginUserMutation, { isLoading, isError }] = useLoginMutation();

  const [units, setUnits] = useState([]);
  const [boxes, setBoxes] = useState([]);

  const [selectedUnit, setSelectedUnit] = useState(null);

  const [selectedBox, setSelectedBox] = useState(null);

  const [switchType, setSwitchType] = useState<boolean>(false);


  const [login, setLogin] = useState('');

  const [password, setPassword] = useState('');

  const sanitize = (input: string): string => {
    // Remove spaces
    const sanitized = input.replace(/\s/g, "");

    return sanitized;
  }


  const handleLoginChange = (e: any) => {
    const unsanitizedLogin = e.target.value;
    const sanitizedLogin = sanitize(unsanitizedLogin);
    setLogin(sanitizedLogin);
  };


  /**
   * 
   * @param e 
   */
  const handlePasswordChange = (e: any) => {
    const unisanitizedPassword = e.target.value;
    const sanitizedPassword = sanitize(unisanitizedPassword);
    setPassword(sanitizedPassword);
  }

  useEffect(() => {
    if (selectedUnit) {
      const unit = units.filter((unit: any) => unit.id == selectedUnit)[0];
      console.log("unit is", unit);
      setBoxes(unit?.stations);
      console.log("boxes are", boxes);
    }
  }, [selectedUnit]);



  useEffect(() => {

    getUnits().then((res: any) => {
      setUnits(res.data.results)
      console.log(res)
    })

    // getBoxes().then((res:any)=> {
    //   console.log(res)
    //       })
    // get units and boxes
    // Promise.all([
    //   getUnits(),
    //   getBoxes()
    // ])
    //   .then((res) => {
    //     console.log(res)
    //     // setUnits(res[0].data.results)
    //     // setBoxes(res[1].data.results)

    //   })
  }, [])

  // useEffect(() => {
  //   const handleKeyDown = (event: any) => {
  //     if (event.key === 'Enter') {
  //       event.preventDefault(); // Prevent form submission
  //       handleLogin()
  //     }
  //   };

  //   window.addEventListener('keydown', handleKeyDown);

  //   return () => {
  //     window.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, []);

  // clear local
  // useEffect(() => {

  //   // Get all keys from localStorage
  //   const keys = Object.keys(localStorage);

  //   // Iterate through the keys and remove the items with the desired prefix
  //   keys.forEach((key) => {
  //     if (key.startsWith(APP_CONSTANTS.LOCALSTORAGE_PREFIX)) {
  //       localStorage.removeItem(key);
  //     }
  //   });
  // }, [])

  useEffect(() => {
    // REDIRECTING USER TO THE APPROPRIATE APP
    if (currentApp) {
      if (currentApp === app.SPECIALIST) { console.log('navigating to:', APP_CONSTANTS.ROUTES.SPECIALISR_CALENDAR); window.location.pathname = APP_CONSTANTS.ROUTES.SPECIALISR_CALENDAR }

      if (currentApp === app.APPOINTMENTS)
        nav(APP_CONSTANTS.ROUTES.CALENDAR)
      else if (currentApp === app.PATIENT_RECORD)
        nav(APP_CONSTANTS.ROUTES.PATIENTS_LIST);
      else if (currentApp === app.VISIO_STATION)
        nav(APP_CONSTANTS.ROUTES.DEVICES);
      else nav(APP_CONSTANTS.ROUTES.LOGIN);
    }
  }, [accessLevels])

  const buttonRef = useRef(null);

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission
      handleLogin();
    }
  };

  /**
   *
   * @param e
   */
  const handleSelectedUnitChange = (e: any) => {
    if (e.target.value == '-1') {
      setSelectedUnit(null);
      localStorage.removeItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}center`);
    }

    else {
      setSelectedUnit(e.target.value);
      const unit = units.filter((u: any) => (u.id == e.target.value))[0];
      if (unit) localStorage.setItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}center`, 
      JSON.stringify({ id: unit.id, name: unit.name }));


    }
  }
  

  /**
   *
   * @param e
   */
  const handleSelectedBoxChange = (e: any) => {
    if (e.target.value == "-1") {
      setSelectedBox(null);
      localStorage.removeItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}box`);
    } else {
      setSelectedBox(e.target.value);
      const box = boxes.filter((b: any) => (b.id == e.target.value))[0]
      if (box) localStorage.setItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}box`, JSON.stringify({ id: box.id, name: box.user?.firstName }))

    }
  };


  const handleAccessToDevicesButtonClick = () => {
    nav(`${APP_CONSTANTS.ROUTES.DEVICES_WITHOUT_AUTHENTICATION}`)
  }

  /**
   * 
   */
  const handleLogin = async () => {
    try {
      // if (!selectedUnit || !selectedBox) throw new Error(`Veuillez choisir l'unité et le box où vous êtes`);

      const response: any = await loginUserMutation({
        login,
        password: password,
        centerId: selectedUnit ? parseInt(selectedUnit, 10) : undefined,
        stationId: selectedBox ? parseInt(selectedBox, 10) : undefined
      })
        .unwrap();


      const accessToken = response.tokens.accessToken;
      const refreshToken = response.tokens.accessToken;
      const user = response.data.user;
      dispatch(loginSuccess({ accessToken, refreshToken, user }));

      const credentialsInfo = {
        user,
        accessToken,
        refreshToken,
      };
      dispatch(setCredentials(credentialsInfo));

      const { data } = response;
      localStorage.setItem(
        `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}token`,
        accessToken
      );
      localStorage.setItem(
        `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}refreshToken`,
        refreshToken
      );
      localStorage.setItem(
        `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}roles`,
        JSON.stringify(response.data.user.roles)
      );
      localStorage.setItem(
        `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}userId`,
        response.data.userId
      );
      localStorage.setItem(
        `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}doctorId`,
        response?.data?.user?.doctor?.id
      );

      if (response.data.user.roles[0].name == role.SPECIALIST)
        localStorage.setItem(
          `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}notifiedConsultations`,
          JSON.stringify([])
        );
      // const accessLevels : Array<any>= data.data.user.roles.flatMap((role:any)=>role.accessibilities.map((accessibility:any)=>accessibility.resource.name));

      console.log("access Levels are");
      console.log(accessLevels);
    } catch (error: any) {
      console.log(error);
      const message =
        "Login ou mot de passe invalide, en cas de persistance du problème, veuillez contacter le support";
      const message_2 = "Veuillez choisir juste votre centre";
      const message_3 = "Veuillez choisir votre centre et votre box";
      const message_4 =
        "Votre accès ne nécessite pas de centre ou de box, veuillez les supprimer";

      if (error?.status === 401)
        toast.error(`${message}`, { id: "100" });

      if (error?.status === 400) {
        if (error?.data?.message.includes('you must choose your center') && error?.data?.message.endsWith('center')) toast.error(`${message_2}`, { id: "200" });
        if (error?.data?.message.includes('you must choose your center') && error?.data?.message.endsWith('station')) toast.error(`${message_3}`, { id: "300" });
        if (error?.data?.message.includes('you must not choose center or station')) toast.error(`${message_4}`, { id: "400" });
      }

      else toast.error(`${error?.data?.message}`, { id: "500" });
    }
  };

  const handleSwitch = () => {
    setSwitchType(!switchType)
  }











  return (
    <GlobalStyle>
      <div className="content">
        <div className="title">Connectez-vous à votre compte</div>



        {/* box selection */}
        <div className="gap-3 w-full">
          <select style={{ display: 'block' }} onChange={handleSelectedUnitChange}
            className="bg-gray-50 border border-[#50799E] text-[#1AA5C0] mb-5 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option value={"-1"}>{"-"}</option>
            {units?.map((unite: any) => (
              <option value={unite.id}>{unite.name}</option>
            ))}
          </select>
          <select style={{ display: 'block' }} onChange={handleSelectedBoxChange}
            className="bg-gray-50 border border-[#50799E] text-[#1AA5C0]  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option value={"-1"}>{"-"}</option>
            {boxes?.map((box: any) => (
              <option value={box.id}>{box.user?.firstName}</option>
            ))}
          </select>
        </div>

        {/* box selection */}

        <IpnpuStyle
          onChange={handleLoginChange}
          required
          style={{
            fontFamily: "Ubuntu",
            color: "#3232329c",
          }}
          placeholder="Entrez votre identifiant"
          type="text"
        />
        <div className="flex w-full">
          <IpnpuStyle
            onChange={handlePasswordChange}
            style={{
              fontFamily: "Ubuntu",
              color: "#3232329c",
            }}
            required
            placeholder="Tapez votre mot de passe"
            type={switchType ? "text" : "password"}
          />
          {switchType ? <AiFillEye className="ml-[-2em] mt-[1em] cursor-pointer" onClick={handleSwitch} size={20} />
            :
            <AiFillEyeInvisible className="ml-[-2em] mt-[1em] cursor-pointer" onClick={handleSwitch} size={20} />}
        </div>
        <div>
          {/* <Link to={""}>  <span className="text-gray-500">Mot de passe oublié ?</span>  </Link> */}
        </div>

        {/* normal connexion button */}
        {!isLoading && (
          <ConexionButton isLoading={false} onClick={handleLogin}>
            Connexion
            <img src="/loginPage/connexion.svg" alt="" />
          </ConexionButton>
        )}

        {/* loading button */}
        {isLoading && (
          <ConexionButton
            isLoading={isLoading}
            disabled={isLoading}
            onClick={handleLogin}
          >
            <div className="flex items-center gap-2">
              <Spinner />
              <span>Connexion</span>
            </div>
            <img src="/loginPage/connexion.svg" alt="" />
          </ConexionButton>
        )}
        {/* <Divider className="mt-6"/>
        <AccessToDevicesButton onClick={handleAccessToDevicesButtonClick}>Mode offline</AccessToDevicesButton> */}
      </div>
    </GlobalStyle>
  );
};

export default InputLogin;
