import React from "react";

function DebounceAnimation() {
  return (
    <>
      <div
        className={`flex bg-[#f0eeee] rounded-full focus:text-white  space-x-2 dounded-full mr-3 p-4`}
      >
        <div className="h-2 w-2 bg-black rounded-full animate-bounce [animation-delay:-0.2s]"></div>
        <div className="h-2 w-2 bg-black rounded-full animate-bounce [animation-delay:-0.15s]"></div>
        <div className="h-2 w-2 bg-black rounded-full animate-bounce"></div>
      </div>
    </>
  );
}

export default DebounceAnimation;
