import zoomInSvg from "../assets/zoomIn.svg";
import zoomOutSvg from "../assets/zoomOut.svg";
import resetView from "../assets/resetView.svg";
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
} from "react-zoom-pan-pinch";

import React, { useCallback, useEffect, useRef, useState } from "react";

export default function PhotoViewer({selectedImage, ReF}){


  // Case Images
  const Controls = ({ zoomIn, zoomOut, resetTransform }) => (
    <>
      <div className="flex flex-col absolute gap-2 top-4 right-4 z-10">
        <button onClick={() => zoomIn()}>
          <img src={zoomInSvg} width={30} />
        </button>
        <button onClick={() => zoomOut()}>
          <img src={zoomOutSvg} width={30} />
        </button>
        <button onClick={() => resetTransform()} className="mt-2">
          <img src={resetView} width={30} />
        </button>
      </div>
    </>
  );



  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);

    return <>
    <TransformWrapper initialScale={1} ref={transformComponentRef}>
          {(utils) => (
            <React.Fragment>
              <Controls {...utils} />
              <TransformComponent wrapperClass="rounded-md border  cursor-[zoom-in]">
                <img
                 
                  className="object-cover object-center h-full w-full rounded-md "
                  src={URL.createObjectURL(selectedImage)}
                  ref={ReF}
                />
              </TransformComponent>
            </React.Fragment>
          )}
        </TransformWrapper>
    </>

}