
import axios from 'axios';

const postAttachment = async (accessToken:any, formData:FormData) => {
  try {

    const response = await axios.post(`${ process.env.REACT_APP_SERVER_URL}/attachement/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        "authorization": `Bearer ${accessToken}`
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error posting attachment', error);
    throw error;
  }
};

export default postAttachment;
