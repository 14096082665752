import { useEffect, useState } from "react";

export const useCreateMediaStream = (
  streamDeviceId: string | null,
  deviceRef: React.RefObject<HTMLVideoElement>
) => {
  const [MultimediaStream, setMultimediaStream] = useState<MediaStream | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const createStream = async () => {
      try {
        // Check for the mediaDevices API
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
          throw new Error("MediaDevices API not supported");
        }

        // Attempt to create the media stream
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: {
            deviceId: streamDeviceId!,
          },
        });

        setMultimediaStream(stream);

        if (deviceRef.current) {
          console.log("[deviceStream] deviceRef.current", deviceRef.current);
          deviceRef.current.srcObject = stream;
        }
      } catch (error: any) {
        console.error("useCreateMediaStream -> error", error);
        setError(error.message || "An unknown error occurred while starting the media source");
      }

      console.log("[deviceStream] deviceId", streamDeviceId);
    };

    if (streamDeviceId) createStream();

    return () => {
      // Cleanup function to stop all tracks
      if (MultimediaStream) {
        MultimediaStream.getTracks().forEach(track => track.stop());
      }
    };
  }, [streamDeviceId]);

  return { MultimediaStream, setMultimediaStream };
};
