// slices/counterSlice.ts
import { RootState } from "../../store";
import { SLICE } from "../../enums/app/store.enums";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { advancedMeasuresToolsIds } from "../../enums/advancedParams";

//
export interface attachementType {
  id: number | null;
  type: advancedMeasuresToolsIds;
  image: File;
  name: string;
  userId: number;
  specialityId: number;
  isScreenShot: boolean;
  consultationId: number;
  doctorComment: string | null;
  aiResults: [] | null;
}
interface SidebarState {
  [category: string]: attachementType[];
}

const initialState: SidebarState = {};

const advancedMeasuresSlice = createSlice({
  name: SLICE.MEDICAL_IMAGES,
  initialState,
  reducers: {
    startMeasuring: () => {

    },
    updateAdvancedMeasureData: (
      state,
      action: PayloadAction<{ id: number; measure: string; data: any }>
    ) => {
      // find the object with the corresponding id
      const attachement = state.attachement.find(
        (object) => object.id === action.payload.id
      );
      if (attachement) {
        // Use bracket notation to access dynamic keys
        attachement[action.payload.measure] = action.payload.data;
      }
    },

    // add AdvancedMeasureData to the list
    addAdvancedMeasureData: (
      state,
      action: PayloadAction<{ attachement: attachementType }>
    ) => {
      // if the attachement.type equale the category add to the attachement an id equales category length + 1,else create a new attachement with id equales 0
      if (state[action.payload.attachement.type]) {
        state[action.payload.attachement.type].push({
          ...action.payload.attachement,
          id: state[action.payload.attachement.type].length + 1,
        });
      } else {
        state[action.payload.attachement.type] = [
          { ...action.payload.attachement, id: 1 },
        ];
      }
    },

    // // remove Examination with the passed it
    // removeExamination: (state, action: PayloadAction<{ id: number }>) => {
    //   // remove the object with the same id from the examinations data list,
    //   // if the length of the list is 1, then set the list to the initial state
    //   if (state.data.length === 1) {
    //     state.data = initialState.data;
    //   } else {
    //     state.data = state.data.filter((item) => item.id !== action.payload.id);
    //     state.isUpdated = true;
    //   }
    // },
  },
});

export const { addAdvancedMeasureData, updateAdvancedMeasureData } =
  advancedMeasuresSlice.actions;
export const advancedMeasuresReducer = advancedMeasuresSlice.reducer;
// selectors.js
export const selectAdvancedMeasures = (state: RootState) =>
  state.advancedMeasures;
