// formSlice.ts
import { RootState } from "../../../store";
import { SLICE } from "../../../enums/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type vitalSignsType = {
  index:number,
  weight: number;
  height: number;
  temperature: number;
  sys: number; // syastolic
  dia: number; // dialoc
  spo2: number; // saturation
  pulse: number; // rythm_cardiac
  bloodSugar: number;
  respiratoryRythm: number;
  //   imc: number;
  //   SMM: number;
  //   graisse: number;
  //   gresse_porcentage: number;
};

interface IVitalSignsType {
  data: vitalSignsType;
  error: string | null;
  isLoading: boolean;
  isUpdated: boolean;
  isRefetchRequired: boolean;
}

const initialState: IVitalSignsType = {
  data: {
    index: 1,
    weight: 0, //
    height: 0, //
    temperature: 0, //
    sys: 0, //
    dia: 0, //
    spo2: 0, //
    pulse: 0, // pulse in the API
    bloodSugar: 0, //
    respiratoryRythm: 0, //
    // imc: 0, //
    // SMM: 0,
    // graisse: 0,
    // gresse_porcentage: 0,
  },
  isLoading: false,
  error: null,
  isRefetchRequired: false, // to refetch data after update
  isUpdated: false,
};

const examinationDataSlice = createSlice({
  name: SLICE.BASEPARAMS_DATA,
  initialState,
  reducers: {
    updateBaseParams: (
      state,
      action: PayloadAction<{ step:number, measure: string; value: number }>
    ) => {
      const { measure, value } = action.payload;
      (state.data as any)[measure] = value;
    },

    // updateBaseParams: (
    //   state,
    //   action: PayloadAction<{ data: vitalSignsType }>
    // ) => {
    //   state.data = action.payload.data;
    // },
  },
});

export const { updateBaseParams } =
  examinationDataSlice.actions;
export const examinationDataReducer = examinationDataSlice.reducer;

// export Selectors
export const SelectExaminationData = (state: RootState) =>
  state.examinationData.data;

