import React, { useEffect, useState } from "react";
import { Drawer, IconButton, Card, Spinner } from "@material-tailwind/react";

import { useSelector } from "react-redux";
import PerdConnexionIcon from "../../../../../assets/perdConnexionIcon.svg";
import GallerySaving from "./GallerySaving";
import { AdvancedParamsTools } from "./../../constants";
import { advancedMeasuresToolsIds } from "./../../enums/advancedParams";
import { attachementType, selectAdvancedMeasures } from "./../../features/Examen/advancedMeasuresSlice";
import { NoRecordFound } from "./../../utils/NoRecordFound";
import FileThumbnail from "./../../FileViewer/FileThumbnail";
import { useGetConsultationAttachementsQuery } from "../../features/Examen/vitalSigns/fileApi";
import ReportGallerie from "../shared/ReportGallerie";
import { useParams } from "react-router-dom";


export default function GalleryMedicaleImages({
  openRight,
  setOpenRight,
}: any) {
  

  const {consultationId}= useParams();
  // States
  const [selectedPicture, setSelectedPicture] = useState<attachementType>();
  const [detailsPic, setDetailsPic] = useState<boolean>(false);
  const [caterogyIndex, setCaterogyIndex] = useState<any>();
  // const [shouldFetch, setShouldFetch] = useState(false);
  const [images, setImages] = useState<any>([]);

  // Selectors
  const GalleryImages = useSelector(selectAdvancedMeasures);


  
  useEffect(() => {
    if (Object.values(GalleryImages).length !== 0) {
      const category = AdvancedParamsTools.find((item) =>
        Object.keys(GalleryImages).includes(item.ToolsIds)
      );
      if (category) {
        setImages(GalleryImages[category.ToolsIds]);
        setCaterogyIndex(AdvancedParamsTools.indexOf(category));
      }
    }
  }, [GalleryImages]);

  // Handlers
  const closeDrawerRight = () => setOpenRight(false);
  const handleOpen = (imageDetails: attachementType) => {
    setDetailsPic(!detailsPic);
    setSelectedPicture(imageDetails);
  };

  const handelSelectCategory = (
    selectedId: advancedMeasuresToolsIds,
    index: number
  ) => {
    if (Object.values(GalleryImages).length !== 0) {
      setImages(GalleryImages[selectedId]);
    }
    setCaterogyIndex(index);
    setDetailsPic(false);
  };

  return (
    <>
      <Drawer
        placeholder={""}
        size={900}
        placement="right"
        open={openRight}
        onClose={closeDrawerRight}
        className="p-2 w-full"
      >
        {/* close Drawer button */}
        <div className="mb-6 flex items-center gap-3">
          <IconButton
            placeholder={""}
            variant="text"
            color="blue-gray"
            onClick={closeDrawerRight}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </IconButton>
          <div className="bg-[#E6F0FA] py-1 flex justify-between my-2 rounded-lg w-full">
            {/* <button onClick={() => setTypeTab('capture')} autoFocus={typeTab === 'capture'} className={`${typeTab === 'capture' ? "text-[#50799E] bg-white px-2 py-1 mx-2 text-md w-full rounded-lg" : "text-[#50799E]  px-2 py-1 mx-2 text-md w-full rounded-lg"}`}>Capture d’écran</button> */}
            <button
              className={`text-[#50799E] font-semibold px-2 py-1 mx-2 text-md w-full rounded-lg"`}
            >
              Galerie d’images
            </button>
          </div>
        </div>

        <div className="h-full w-full justify-items-center">
          {consultationId && (
            <ReportGallerie consultationId={Number(consultationId)} openRight={openRight} />
          )} 
        </div>
      </Drawer>
    </>
  );
}