import React, { useEffect } from "react";
import { GlobalStyle } from "../SearchPatient/SearchPatient";
import { ReactComponent as PeriDeBase } from "../../assets/periDeBase.svg";
import { ReactComponent as ArrRight } from "../../assets/arrowRight.svg";
import { ReactComponent as SaveIcon } from "../../assets/save-base-consult.svg";
import { useState } from "react";
import PeriphiriqueLeft from "./PeriphiriqueLeft";
import PeriphiriqueRight from "./PeriphiriqueRight";
import SocketIOContextProvider from "../../services/socketIOContext";
import { vitalSignsType } from "@interfaces/baseParams";
import { postVitalSigns } from "../../axiosAPI/params";
import { useNavigate, useParams } from "react-router-dom";
import PeriphiriqueHeader from "./PeriphiriqueHeader";
import { ArrowLeftCircleIcon } from "@heroicons/react/24/solid";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { CONSULTATION_STATUSES } from "../../enums/consultation";
import { setVitalSignsConsultation } from "../../features/consultationData/consultationDataSlice";
import PeriphiriquesAvancee from "../advancedDevices";
import { ToastContainer } from "react-toastify";
import BasicDevices from "../basicDevices";
import { useGetPatientVitalSignsHistoryByIdQuery } from "../../features/Examen/vitalSigns/ExaminationDataApi";
import {
  SelectExaminations,
  SelectExaminationsWithoutId,
} from "../../features/Examen/vitalSigns/ExaminationsSlice";
import { getPatient } from "../../axiosAPI/Network";

type baseProps = {
  isConsultation: boolean;
  setSelectedPeripherique?: any;
  isAdvancedParams: boolean;
};
const ClinicalExams = (props: baseProps) => {
  const { isConsultation, isAdvancedParams } = props;

  // useParams
  const { id:patientId, consultationId } = useParams();

  const navigat = useNavigate();

  const dispatch = useDispatch();

  const ExaminationsList = useSelector(SelectExaminationsWithoutId);
  const patchExaminationsList = useSelector(SelectExaminations);

  const [patient, setPatient] = useState<any>(null);

  // useSelector
  const consultationStatus = useSelector(
    (state: RootState) => state.consultationStatus.status
  );

  const currentStep = useSelector(
    (state: any) => state.currentStep.currentStep
  );

  // const {
  //   data: HistoryData,
  //   error: HistoryDataError,
  //   isLoading: HistoryDataIsLoading,
  //   refetch: HistoryDataRefetch,
  //   isSuccess: HistoryDataIsSuccess,
  // } = useGetPatientVitalSignsHistoryByIdQuery(consultationId);

  // handleres
  const handleBackButtonClick = () => {
    console.log("consultaion status", consultationStatus);
    if (
      currentStep !== 0 &&
      consultationStatus == CONSULTATION_STATUSES.ON_GOING
    ) {
      try {
        props.setSelectedPeripherique(-1);
      } catch (error) {
        navigat(-1);
      }
    } else {
      navigat(-1);
    }
  };

  useEffect(() => {
    if (patientId) {
      console.log("patientId", patientId);
      getPatient(Number(patientId))
        .then((result) => {
          setPatient(result.data);
        })
        .catch((err) => {
          setPatient({});
          console.log(err);
        });
    }
  }, []);

  return (
    <GlobalStyle>
      <SocketIOContextProvider>
        <div className="flex flex-col   items-center justify-start h-full px-4 py-2  ">
          <div className="title flex gap- items-center  w-full mt-6">
            <div className="flex items-center">
              <button onClick={handleBackButtonClick}>
                <ArrowLeftCircleIcon className="w-11" />
              </button>
            </div>
            <div className="text-primary text-xl font-bold ">
              {!isAdvancedParams
                ? "Périphériques de base"
                : "Périphériques avancées"}
            </div>
          </div>
          <div className={`flex w-full h-full justify-center items-center`}>
            {isAdvancedParams ? (
              <PeriphiriquesAvancee isConsultation={isConsultation!} />
            ) : (
              <BasicDevices
                isConsultation={isConsultation}
                setSelectedPeripherique={props.setSelectedPeripherique}
                patientId={Number(patientId)}
                consultationId={Number(consultationId)}
                patient={patient}
              />
            )}
          </div>
        </div>
        <ToastContainer />
      </SocketIOContextProvider>
    </GlobalStyle>
  );
};

export default ClinicalExams;
