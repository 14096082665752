import React, { useEffect, useState } from "react";

import RangeInput from "../shared/RangeInput";

import IMC from "./IMC";
import { useDispatch } from "react-redux";
import { isUpdating, updateExaminations, vitalSignsType } from "../../features/Examen/vitalSigns/ExaminationsSlice";
import { BaseParamsDeviceIds, connectedBasicDeviceIds } from "../../enums/vitalSigns";


interface signObject {
  deviceId: string;
  unite: string;
  title: string;
  maxValue: number;
  minValue: number;
  measureValue: number;
  setMeasureValue: (value: number) => void;
}
interface card {
  id: string;
  icon: any;
  title: string;
  measures: signObject[];
  escapeLastValue: boolean;
  sensorAvailable: boolean;
  setSelectedDevice: (value: string) => void;
  selectedDevice: string | null;
  setStopDevice: (value: boolean) => void;
  measureSelected: vitalSignsType;
  isConsultation: boolean;
  increaseAmountSelected: number;
}

const CardWithTwoValues = ({
  id,
  icon,
  title,
  measures,
  escapeLastValue,
  setSelectedDevice,
  selectedDevice,
  setStopDevice,
  sensorAvailable,
  measureSelected,
  isConsultation,
  increaseAmountSelected,
}: card) => {

  // States
  const [isChecked, setIsChecked] = useState(true);
  const [valueIndex, setValueIndex] = useState(0);

  // dispatch
  const dispatch = useDispatch();

  // handlers
  const handleSwitchChange = (event: any) => {
    if (!event.target.checked) {
      measures.map((measure) => {
        measure.setMeasureValue(0);
      });
    }
    setIsChecked(event.target.checked);
  };

  const handleIncrementMeasure = () => {
    if (measures[valueIndex].measureValue <= measures[valueIndex].minValue) {
      measures[valueIndex].deviceId == BaseParamsDeviceIds.WEIGHT
        ? measures[valueIndex].setMeasureValue(
            parseFloat(
              Math.min(
                measures[valueIndex].minValue + Number(increaseAmountSelected),
                measures[valueIndex].maxValue
              ).toFixed(2)
            )
          )
        : measures[valueIndex].setMeasureValue(
            Math.min(
              measures[valueIndex].minValue + 1,
              measures[valueIndex].maxValue
            )
          );
    } else {
      measures[valueIndex].deviceId == BaseParamsDeviceIds.WEIGHT
        ? measures[valueIndex].setMeasureValue(
            parseFloat(
              Math.min(
                measures[valueIndex].measureValue +
                  Number(increaseAmountSelected),
                measures[valueIndex].maxValue
              ).toFixed(2)
            )
          )
        : measures[valueIndex].setMeasureValue(
            Math.min(
              measures[valueIndex].measureValue + 1,
              measures[valueIndex].maxValue
            )
          );
    }
  };

  const handleDecrementMeasure = () => {
    if (measures[valueIndex].measureValue > 0) {
      measures[valueIndex].deviceId == BaseParamsDeviceIds.WEIGHT
        ? measures[valueIndex].setMeasureValue(
            parseFloat(
              Math.max(
                measures[valueIndex].measureValue -
                  Number(increaseAmountSelected),
                measures[valueIndex].minValue
              ).toFixed(2)
            )
          )
        : measures[valueIndex].setMeasureValue(
            Math.max(
              measures[valueIndex].measureValue - 1,
              measures[valueIndex].minValue
            )
          );
    }
  };

  const handleMeasureChange = () => {
    if (valueIndex < measures.length - (escapeLastValue ? 2 : 1)) {
      setValueIndex(valueIndex + 1);
    } else if (valueIndex === measures.length - (escapeLastValue ? 2 : 1)) {
      setValueIndex(0);
    } else {
      setValueIndex(0);
    }
  };

  // const formatedData = (data) => {
  //   return measures[valueIndex].deviceId == BaseParamsDeviceIds.WEIGHT
  //     ? data.measureValue.toFixed(2)
  //     : data.measureValue;
  // };


  // dispatch the changed valueIndex value
  useEffect(() => {
    // dispatch state that is not saved yet
    dispatch(isUpdating(true));

    const timer = setTimeout(() => {
      dispatch(
        updateExaminations({
          index: measureSelected.index,
          measure: measures[valueIndex].deviceId,
          data: measures[valueIndex].measureValue,
        })
      );
    }, 1000);
    return () => clearTimeout(timer);
  }, [measures[valueIndex].measureValue]);

  useEffect(() => {
    measures.map((measure) => {
      measure.setMeasureValue(measureSelected[measure.deviceId]);
    });
  }, [measureSelected]);

  return (
    <>
      <div className="grid grid-rows-3 border rounded-lg mx-3 px-3 py-1 bg-white">
        <div className="grid grid-cols-2">
          {/* firstColumn */}
          <div className="flex justify-start gap-2 items-center">
            <img src={icon} alt="icon" className="w-10 h-10" />
            <p className="text-[#335D84] text-sm font-semibold">{title}</p>
          </div>
          {/* secondColumn */}
          {sensorAvailable && id !== connectedBasicDeviceIds.WEIGHT_HEIGHT ? (
            <div className="flex flex-col p-0 mt-3">
              <div className="flex justify-center gap-5 items-center">
                <span className="font-semibold">M</span>
                <div>
                  <div className="inline-flex items-center">
                    <div className="relative inline-block w-8 h-4 rounded-full cursor-pointer">
                      <input
                        id={id}
                        type="checkbox"
                        className="absolute w-8 h-4 transition-colors duration-300 rounded-full appearance-none cursor-pointer peer bg-blue-gray-100 checked:bg-[#335D84] peer-checked:border-gray-900 peer-checked:before:bg-gray-900"
                        onChange={handleSwitchChange}
                        checked={isChecked}
                      />
                      <label
                        htmlFor={id}
                        className="before:content[''] absolute top-2/4 -left-1 h-5 w-5 -translate-y-2/4 cursor-pointer rounded-full border border-blue-gray-100 bg-white shadow-md transition-all duration-300 before:absolute before:top-2/4 before:left-2/4 before:block before:h-10 before:w-10 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity hover:before:opacity-10 peer-checked:translate-x-full peer-checked:border-gray-900 peer-checked:before:bg-gray-900"
                      >
                        <div
                          className="inline-block p-5 rounded-full top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4"
                          data-ripple-dark="true"
                        ></div>
                      </label>
                    </div>
                  </div>
                </div>
                <span className="font-semibold">A</span>
              </div>
              <div className="flex justify-center items-center">
                <p className="font-semibold text-[#858585] text-xs">
                  Passer en automatique
                </p>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        {/* body */}
        <div className="h-20 bg-[#ffffff] rounded-lg flex justify-center items-center align-middle">
          <div className="flex justify-between items-center w-full">
            <div className="h-20 bg-[#F5F5F5] rounded-lg flex justify-around items-center align-middle w-full">
              {measures.map((data, index) => {
                return index < 2 ? (
                  <div key={index} className="flex flex-col items-center">
                    <label htmlFor="" className="text-[#858585] text-sm">
                      {data.title}
                    </label>
                    <p className="font-bold text-[#333333] text-xl ">
                      {data.measureValue && data.measureValue > data.minValue
                        ? data.measureValue
                        : "--"}
                      <span className="font-bold text-lg text-gray-500 ml-2">
                        {data.unite}
                      </span>
                    </p>
                  </div>
                ) : (
                  <IMC
                    weight={measures[0].measureValue}
                    height={measures[1].measureValue}
                    key={index}
                    data={data}
                    measureSelected={measureSelected}
                  ></IMC>
                );
              })}
            </div>
          </div>

          {/* <p className="font-bold">- -<span className="relative top-2 font-bold text-lg text-black">C</span></p> */}
        </div>
        {/* footer */}
        <div className="w-full mt-3 p-0 m-0">
          {(!isChecked ||
            !sensorAvailable ||
            id === connectedBasicDeviceIds.WEIGHT_HEIGHT) &&
          (isConsultation || id === connectedBasicDeviceIds.WEIGHT_HEIGHT) ? (
            <RangeInput
              id={id} // medicale device Id
              twoValues={true} // to display two values range
              deviceId={measures[valueIndex].deviceId} // ex: weight, height, to get there value from the socket sensor (for two values range)
              value={measures[valueIndex].measureValue} // the value of the measure
              setValue={measures[valueIndex].setMeasureValue} // setting the value of the measure
              maxValue={measures[valueIndex].maxValue} // the max value of the measure
              minValue={measures[valueIndex].minValue} // the min value of the measure
              measureName={measures[valueIndex].title} // the title to display on the range
              onDecrement={handleDecrementMeasure} // decrement function
              onIncrement={handleIncrementMeasure} // increment function
              handleBars={handleMeasureChange}
              setSelectedDevice={setSelectedDevice}
              selectedDevice={selectedDevice}
              setStopDevice={setStopDevice}
            />
          ) : (
            <>
              {selectedDevice !== id ? (
                <button
                  className={`w-full py-2 ${sensorAvailable ? "text-white bg-[#335D84]" : " bg-[#F5F5F5] cursor-not-allowed"}  rounded-lg font-semibold mt-2`}
                  onClick={() => {
                    if (sensorAvailable) {
                      setSelectedDevice(id);
                    }
                  }}
                >
                  Commencer
                </button>
              ) : (
                <button
                  className="w-full py-2 text-white bg-[#cb4040] rounded-lg font-semibold mt-2"
                  onClick={() => {
                    if (sensorAvailable) {
                      setStopDevice(true);
                    }
                  }}
                >
                  Arrêter
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CardWithTwoValues;
