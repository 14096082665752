import { useEffect, useMemo, useRef, useState } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import { getConnectedDevices } from "../../helper/mediaDeviceHelper";
import VideoPlayer from "./VideoPlayer";
import devices from "../../helper/devicesInfos.json";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  selectMute,
  selectToggleScreen,
  setShowOthers,
} from "../../features/teleconsultation/CallActionsSlice";

import { updateAvailableMainCameras } from "../../features/Examen/defaultMainCameras";
import { getMainCameraId } from "../../features/teleconsultation/mainCameraIdSlice";
import hotToast from "react-hot-toast";
import { ReactComponent as WebRTCCamera } from "../../assets/webRTCCamera.svg";

const APP_ID = process.env.REACT_APP_APP_ID;
const client = AgoraRTC.createClient({
  mode: "rtc",
  codec: "vp8",
  //TODO: turn server
});



export const AgoraNurseCall = ({
  token,
  channel,
  users,
  setUsers,
  isMute,
  hangUp,
  isToggle,
  setCurrentUser,
  currentUser,
  mainCamera,
}: any) => {


  const [localTracks, setLocalTracks] = useState<any>([]);
  const muteState = useSelector(selectMute);
  const toggleScreenState = useSelector(selectToggleScreen);



  
  const handleUserJoined = async (user: any, mediaType: any) => {
    await client.subscribe(user, mediaType);

    if (mediaType === "video") {
      setUsers((previousUsers: any) => [...previousUsers, user]);
    }

    if (mediaType === "audio") {
      user.audioTrack.play();
    }
  };
  const handleUserLeft = (user: any) => {
    setUsers((previousUsers: any) =>
      previousUsers.filter((u: any) => u.uid !== user.uid)
    );
  };

  useEffect(() => {
    const handellingMainCamera = async () => {
      console.log("*NURSE* mainCamera", mainCamera);

      client.on("user-published", handleUserJoined);
      client.on("user-left", handleUserLeft);


      client
        .join(APP_ID, channel, token, null)
        .then((uid) =>
          Promise.all([
            AgoraRTC.createMicrophoneAndCameraTracks(
              // { AEC: true, ANS: true },
              {},
              { cameraId: mainCamera /**microphoneId: devices[0].deviceId*/ }
            ),
            uid,
          ])
        )
        .then(([tracks, uid]) => {
          const [audioTrack, videoTrack] = tracks;
          setCurrentUser(uid);
          setLocalTracks(tracks);
          setUsers((previousUsers: any) => [
            ...previousUsers,
            {
              kind: "nurseCall",
              uid,
              videoTrack,
              audioTrack,
            },
          ]);
          client.publish(tracks);
        });
    };
    console.log("[*NURSE* tokenn]", token);
    if (token) {
      console.log("[*NURSE* maincamera]", mainCamera);
      if (mainCamera) {
        handellingMainCamera();
      }
      else {
        // dispatch the state for UX test
        console.error
          ("error camera")
      }
    }

    return () => {
      console.log("*NURSE* leave")
      client.off("user-published", handleUserJoined);
      client.off("user-left", handleUserLeft);
      client.unpublish().then(() => client.leave());
    };
  }, []);

  useEffect(() => {
    if (muteState) {
      console.log(`*NURSE* mute`, muteState);
      users.filter((user: any) => {
        if (user.uid === currentUser) {
          user.audioTrack.setEnabled(false);
        }
      });
    } else {
      users.filter((user: any) => {
        if (user.uid === currentUser) {
          user.audioTrack.setEnabled(true);
        }
      });
    }
  }, [muteState]);

  useEffect(() => {
    console.log("*NURSE* toggle the screnn", toggleScreenState);
    if (toggleScreenState)
      users.filter((user: any) => {
        if (user.uid === currentUser) {
          user.videoTrack.setEnabled(false);
          
        }
      });
    else
      users.filter((user: any) => {
        if (user.uid === currentUser) {
          user.videoTrack.setEnabled(true);
        }
      });
  }, [toggleScreenState]);

  return (
    <>
      {" "}
      {users.map((user: any) => {
        if (user.uid === currentUser) {
          return <VideoPlayer key={user.uid} user={user} />;
        }
        // return null; // Or any fallback content if no user matches the condition
      })}
    </>
  );
};