import { io } from "socket.io-client";
import { Howl } from "howler";
import notifSound from "../../assets/notifSound.wav";
import { setAvailableServices } from "../../features/socket/mediotAgents";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { ExeName } from "../../enums/vitalSigns";
import APP_CONSTANTS, { AppUserId } from "../../constants";
import axios, { AxiosError } from "axios";
import { CALL_STATUS } from "../../enums/telemedecine/consultationStatuses.enum";
import { socketEvent, userStatus } from "../../enums/app";

const WebSocketService = (() => {
  let socket: any = null;
  const sound = new Howl({
    src: [notifSound],
  });
  const connect = () => {
    if (!socket) {
      socket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
        auth: { token: localStorage.getItem("vs_token") },
        transports: ["websocket"],
      });

      socket.on("connect", () => {
        console.log("Socket.IO connection established.");
      });

      socket.on("connect_error", (error: any) => {
        console.error("Socket.IO connection error:", error);
      });

      socket.on("disconnect", (reason: any) => {
        console.log("Socket.IO disconnected:", reason);
      });
    }
  };



  /**
   *
   * @param callback
   */
  const subscribeToNotifications = (callback: (data: any) => void) => {
    if (socket) {
      socket.on("new-notification", (data: any) => {
        console.log("socket data", data);
        // sound.play();

        console.log("Received event: new-notification", data);
        callback(data);
      });
    }
  };

  /**
   * inform the user that a new client is connected
   */
  const subscribeToConnectedUsers = (callback: (data: any) => void) => {
    if (socket) {
      socket.on(socketEvent.CONNECTED_USERS, (data: any) => {
        console.log("new connected users", data);
        callback(data);
      });
    }
  };

  /**
   * Get the list of connected users
   * @param callback
   */
  const getConnectedUsers = (callback: (data: any) => void) => {
    if (socket)
      socket.emit(socketEvent.CONNECTED_USERS, "{}", (data: any) => {
        callback(data);
      });
  };

  /**
   *
   * @param data
   * @param callback
   */
  const sendAppointmentNotification = async (
    data: any,
    callback: (data: any) => void
  ) => {
    const {
      stationId,
      patientId,
      generalist,
      specialist,
      consultation,
      centerId,
    } = data;
    console.log("sending call request", consultation);
    if (
      stationId &&
      patientId &&
      specialist &&
      generalist &&
      consultation &&
      centerId
    ) {
      // const res = await createCall({
      //   patient: { id: patientId },
      //   doctor: { id: userId },
      //   stationId,
      //   centerId,
      // });
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/call`,
          {
            // patient: { id: patientId },
            // doctor: { id: Number(userId) },
            consultation,
            generalist,
            specialist,
            stationId,
            centerId,
            status: CALL_STATUS.incoming,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}token`
              )}`,
            },
          }
        );
        console.log("res", res.data.id);
        callback(res.data);
      } catch (error) {
        console.error("sending post call error", error);
      }
    }
  };
  /**
   *   ending call
   * @param ack
   * @param roomId
   * @param callback
   */
  const endCall = async (
    ack: boolean,
    roomId: string,
    callback: (data: any) => void
  ) => {
    if (roomId) {
      console.log(
        "[URL]",
        `${process.env.REACT_APP_SERVER_URL}/call/${roomId}`
      );
      try {
        const res = await axios.patch(
          `${process.env.REACT_APP_SERVER_URL}/call/${roomId}`,
          {
            // patient: { id: patientId },
            endTime: new Date(),
            status: CALL_STATUS.ended,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}token`
              )}`,
            },
          }
        );
        console.log("res", res.status);
        callback({ status: res.status, data: res.data });
      } catch (err: AxiosError | any) {
        if (axios.isAxiosError(err)) {
          if (err.response) {
            // The client was given an error response (5xx, 4xx)
            console.error("[endCall]: server Error", err.response);
          } else if (err.request) {
            // The client never received a response, and the request was never left
            console.error("[endCall]: client Error", err.request);
          } else {
            // Anything else
            console.log("Error", err.message);
          }
        }
      }
    } else {
      console.log("roomId is not setted");
    }
  };
  const joinCall = (callback: (data: any) => void) => {
    if (socket) {
      socket.on("room-created", (data: any) => {
        const { room } = JSON.parse(data);
        if (room.owner !== AppUserId) {
          console.log("room-created", data);
          // sound.play();
          console.log("owner", room.owner);

          console.log("is owner");
          callback(room);
        }
      });
    }
  };

  const sendBaseParamsCallData = (payload: any) => {
    console.log("values to send", payload);

    socket.emit("sendDataTool", payload);
  };

  /**
   *
   * @param newStatus
   * @param userId
   */
  const updateDoctorStatus = (newStatus: userStatus, userId: number) => {
    console.log("updating  the user status to", newStatus);
    if (socket)
      socket.emit(
        "setDoctorStatus",
        JSON.stringify({ status: newStatus, userId })
      );
  };

  const RecvBaseParamsCallData = (callback: (data: any) => void) => {
    if (socket) {
      socket.on("displayTool", (data: any) => {
        console.log("payload recieved", data);
        callback(data);
      });
    }
  };

  const hideBaseParamsCallData = (callback: (data: any) => void) => {
    socket.on("hideDataTool", (data: any) => {
      console.log("listening");
      // sound.play();

      console.log("Received event: hideDataTool", data);
      callback(data);
    });
  };

  const joinRoom = (
    roomId: string,
    userId: string /*consultationId*/,
    callback: (data: any) => void
  ) => {
    // this._room = room;
    // console.log("roomId**", roomId, "userId", userId);
    const payload = JSON.stringify({ roomId, userId /*consultationId*/ });
    socket.emit(
      "joinRoom",
      payload
      // , (data: any) => {
      //   callback(data);
      // }
    );
    callback(true);
    // );
  };

  return {
    connect,
    joinCall,
    joinRoom,
    updateDoctorStatus,
    backeEndsocket: socket,
    sendBaseParamsCallData,
    RecvBaseParamsCallData,
    hideBaseParamsCallData,
    // close,
    sendAppointmentNotification,
    subscribeToNotifications,
    subscribeToConnectedUsers,
    getConnectedUsers,
    endCall,
  };
})();

export default WebSocketService;
