import { Formik, Form, ErrorMessage, Field } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  addPatient,
  getLocation,
  getCountries,
  getCities,
  getRegions,
  getProvinces,
} from "../../axiosAPI/Network";
import { useMutation } from "react-query";
import InputWithButton from "./inputGenerator";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import moment from "moment";
import ConsultationStatus from "../../Components/Steps/CustomComponents/ConsultationStatus";
import APP_CONSTANTS from "../../constants";
import { formatFirstName, formatLastName } from "../../services/utils.service";
import MyInput from "./MyInput";
import MySelect from "./MySelect";
import { RootState } from "src/store";
import { useSelector } from "react-redux";
// import { useQuery } from 'react-query'
// import axios from "axios";
// import { addPatient } from "../../axiosAPI/Network";

const StyledForm = styled.form`
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  .myform {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;
    .line {
      width: 100%;
      display: flex;
      gap: 3rem;
      flex-wrap: wrap;
      .duble-size {
        width: 100%;
      }
      .half-size {
        width: 50%;
      }
    }
  }
`;

const WrraperBotton = styled.div`
  margin-top: 1rem;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  color: #ffff;
  .but {
    background: #858585;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    padding-inline: 4rem;
    padding-block: 0.5rem;
    margin-bottom: 1rem;
  }
`;

interface MyPropsDataType {
  ischild: boolean | undefined;
  iswife: boolean | undefined;
  isSearchAndAdd: boolean | undefined;
  setSearchAndAdd: any;
  setIsChild: any;
  isModif: boolean;
  setIswife: any;
  setIsModif: any;
  userSelected?: any;
}

const AddPatientfrom = ({
  ischild,
  setIsModif,
  iswife,
  setSearchAndAdd,
  isSearchAndAdd,
  isModif,
  setIsChild,
  setIswife,
  userSelected,
}: MyPropsDataType) => {
  const [dataToAdd, setDataToAdd] = useState<any>({});
  const [countryArr, setCountryArr] = useState<any>([]);
  const [provinceArr, setProvinceArr] = useState<any>([]);
  const [selectedProvinceId, setSelectedProvinceId] = useState<any>(null);
  const [regionArr, setRegionArr] = useState<any>([]);
  const [cityArr, setCityArr] = useState<any>([]);
  const [filteredCitiesArr, setFilteredCitiesArr] = useState<any>([]);
  const [citiesArr, setCitiesArr] = useState<any>([]);
  const [provincesArr, setProvincesArr] = useState<any>([]);
  const [stateObject, setStateObject] = useState<any>();
  const [age, setAge] = useState<any>(0);
  const requiredMsg: string = "Ce champs est obligatoire";

  const currentCenter = useSelector((state: RootState) => state.auth.center);

  const nav = useNavigate();
  const validationSchema = Yup.object().shape({
    cine: Yup.string(),
    // .required(requiredMsg)
    // .matches(/^[a-zA-Z]{1,2}\d{1,10}$/, "Format CINE incorrect"),
    lastName: Yup.string().required(requiredMsg),
    // .matches(
    //   /^(?:[a-zA-ZÀ-ÿ'-]+(?:\s[a-zA-ZÀ-ÿ'-]+)*){2,}$/,
    //   "Format Nom incorrect"
    // ),
    firstName: Yup.string().required(requiredMsg),
    // .matches(
    //   /^(?:[a-zA-ZÀ-ÿ'-]+(?:\s[a-zA-ZÀ-ÿ'-]+)*){2,}$/,
    //   "Format Prénom incorrect"
    // ),
    gender: Yup.string().required(requiredMsg),
    birthDate: Yup.date()
      .required(requiredMsg)
      .max(new Date().toISOString().split("T")[0], "Date invalide"),

    address: Yup.string().required(requiredMsg),

    phoneNumber: Yup.string()
      // .required(requiredMsg)
      .matches(
        /^(?:(?:\+|00)212|0)([5-7]\d{8})$/,
        "Format Numero de telephone incorrect"
      ),
    provinceId: Yup.string().required(requiredMsg),
    cityId: Yup.string().required(requiredMsg),
    // Add validation rules for other fields
  });

  useEffect(() => {
    (async () => {
      const [countriesData, citiesData, regionsData, provincesData] =
        await Promise.all([
          getCountries(),
          getCities(),
          getRegions(),
          getProvinces(),
        ]);

      setCitiesArr(citiesData.data.results);
      setProvincesArr(provincesData.data.results);
      setProvinceArr(
        provincesData.data.results.map((province: any) => province?.name)
      );
      setRegionArr(regionsData.data.results.map((region: any) => region.name));

      setCityArr(citiesData.data.results.map((city: any) => city.name));
      setCountryArr(
        countriesData.data.results.map((country: any) => country.name)
      );

      if (userSelected) setStateObject(flattenObject(userSelected));
    })();
  }, [userSelected]);

  useEffect(() => {
    if (selectedProvinceId) {
      const citySelect = document.getElementsByName("cityId")[0];
      const provinceCities = citiesArr.filter(
        (city: any) => city.provinceId == selectedProvinceId
      );
      citySelect.nodeValue = "";
      setFilteredCitiesArr(provinceCities);
    }
  }, [selectedProvinceId]);

  const { mutate, isLoading } = useMutation(
    (data: any) => addPatient(data, isSearchAndAdd),
    {
      onSuccess: (res: any) => {
        // toast.success("Patient created successfully");
        // console.log("response is", res);
        if (res?.data?.id) nav(`/doctor/patient/${res?.data?.id}`);
      },
      onError: (err: any) => {
        // console.log("une erreur est survenue", err?.response?.data);
        //  toast.error("An error occured when submitting form");
      },
    }
  );

  /**
   *
   * @param obj
   * @returns
   */
  function flattenObject(obj: any) {
    let result: any = {};

    function recurse(current: any, path = "") {
      for (const key in current) {
        const value = current[key];
        const newPath = path ? `${key}` : key;

        if (typeof value === "object" && value !== null) {
          recurse(value, newPath);
        } else {
          result[newPath] = value;
        }
      }
    }

    recurse(obj);
    return result;
  }

  const handleCancelCick = () => {
    nav(APP_CONSTANTS.ROUTES.PATIENTS_LIST);
  };

  if (isLoading) return <div>Loading ...</div>;

  const validate = (values: any) => {
    // console.log(values);

    return true;
  };

  return (
    <>
      <Formik
        initialValues={{
          cine: "",
          firstName: "",
          lastName: "",
          address: "",
          gender: null,
          birthDate: null,
          phoneNumber: "",
          cityId: null,
          provinceId: null,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          if (!ischild && !iswife && !isSearchAndAdd)
            // console.log(11);
            // console.log("add patient form values =>", values);
            // console.log(values);

            mutate({ data: values });
          if (isModif) {
            // console.log(22);
            setIsModif(false);
          }
          if (isSearchAndAdd) {
            // console.log(33);
            mutate({ data: { dataToAdd, values } });
            // setDataToAdd({dataToAdd, values});
          }

          if (ischild || iswife) {
            // console.log(44);
            setDataToAdd(values);
            setSearchAndAdd(true);
            setIsChild(false);
            setIswife(false);
          }
        }}
      >
        {({ values, handleChange, handleSubmit }) => (
          <Form className="addPatientForm" onSubmit={handleSubmit}>
            <div className="myform">
              <div className="line">
                <div className="flex flex-col relative">
                  <Field
                    as={MyInput}
                    ismodif={isModif}
                    label={"Nº CINE"}
                    placeholder={"CINE"}
                    isImportant={false}
                    name={"cine"}
                    handleChange={handleChange}
                    hasLeft={false}
                    hasRight={false}
                    type={"text"}
                  />
                  <ErrorMessage
                    className="absolute -bottom-[35px] error-message"
                    name="cine"
                    component="div"
                  />
                </div>
                <div className="flex flex-col relative">
                  <Field
                    autoComplete="off"
                    as={MyInput}
                    ismodif={isModif}
                    label={"Nom"}
                    placeholder={"Nom"}
                    isImportant={true}
                    name={"lastName"}
                    handleChange={(e: any) => {
                      const formattedValue = formatLastName(e.target.value);
                      handleChange(e);
                      handleChange({
                        target: { name: "lastName", value: formattedValue },
                      });
                    }}
                    hasLeft={false}
                    hasRight={false}
                    type={"input"}
                  />
                  <ErrorMessage
                    className="absolute -bottom-[35px] error-message"
                    name="lastName"
                    component="div"
                  />
                </div>
                <div className="flex flex-col relative">
                  <Field
                    autoComplete="off"
                    as={MyInput}
                    ismodif={isModif}
                    label={"Prénom"}
                    placeholder={"Prénom"}
                    isImportant={true}
                    name={"firstName"}
                    handleChange={(e: any) => {
                      const formattedValue = formatFirstName(e.target.value);
                      handleChange(e);
                      handleChange({
                        target: { name: "firstName", value: formattedValue },
                      });
                    }}
                    hasLeft={false}
                    hasRight={false}
                    type={"input"}
                  />
                  <ErrorMessage
                    className="absolute -bottom-[35px] error-message"
                    name="firstName"
                    component="div"
                  />
                </div>
                <div className="flex flex-col relative">
                  <Field
                    as={MySelect}
                    ismodif={isModif}
                    label={"Sexe"}
                    placeholder={"Sexe"}
                    isImportant={true}
                    options={[
                      {
                        id: "",
                        name: "",
                      },
                      { id: "male", name: "Masculin" },
                      { id: "female", name: "Féminin" },
                    ]}
                    name={"gender"}
                    handleChange={handleChange}
                  />
                  <ErrorMessage
                    className="absolute -bottom-[35px] error-message"
                    name="gender"
                    component="div"
                  />
                </div>
                <div className="flex flex-col relative">
                  <Field
                    as={MyInput}
                    ismodif={isModif}
                    label={"Date de naissance"}
                    placeholder={"Date de naissance"}
                    isImportant={true}
                    name={"birthDate"}
                    handleChange={handleChange}
                    hasLeft={false}
                    hasRight={true}
                    type={"date"}
                    isAge={true}
                    maxDate={new Date()}
                  />
                  <ErrorMessage
                    className="absolute -bottom-[35px] error-message"
                    name="birthDate"
                    component="div"
                  />
                </div>
                {!ischild && !iswife && (
                  <div className="flex flex-col relative">
                    <Field
                      as={MySelect}
                      ismodif={isModif}
                      canBeEmpty={false}
                      label={"Couverture"}
                      placeholder={"Couverture"}
                      // isImportant={true}
                      options={[
                        {
                          id: "",
                          name: "",
                        },
                        {
                          id: "CNSS PIPC",
                          name: "CNSS PIPC",
                        },
                        {
                          id: "AMO",
                          name: "AMO",
                        },
                        {
                          id: "CNSS",
                          name: "CNSS",
                        },
                        {
                          id: "CNOPS",
                          name: "CNOPS",
                        },
                        {
                          id: "FAR",
                          name: "FAR",
                        },
                      ]}
                      name={"medicalCover"}
                      handleChange={handleChange}
                    />
                    <ErrorMessage
                      className="absolute -bottom-[35px] error-message"
                      name="medicalCover"
                      component="div"
                    />
                  </div>
                )}

                {!ischild && !iswife && (
                  <div className="flex flex-col relative">
                    <Field
                      as={MyInput}
                      ismodif={isModif}
                      label={"N adhérent"}
                      placeholder={"N adhérent"}
                      // isImportant={true}
                      name={"affiliationNumber"}
                      handleChange={handleChange}
                      hasLeft={false}
                      hasRight={false}
                      type={"text"}
                    />
                    <ErrorMessage
                      className="absolute -bottom-[35px] error-message"
                      name="affiliationNumber"
                      component="div"
                    />
                  </div>
                )}
                {/* <div className="flex flex-col relative">
                  <Field
                    as={MySelect}
                    options={countryArr}
                    ismodif={isModif}
                    label={"Pays"}
                    placeholder={"Pays"}
                    // isImportant={true}
                    name={"country"}
                    handleChange={handleChange}
                  />
                  <ErrorMessage className="absolute -bottom-[35px] error-message" name="country" component="div" />
                </div> */}
                {/* <Field as={MySelect}
                ismodif={isModif}
                label={"Region"}
                placeholder={"Region"}
                isImportant={true}
                name={"Region"}
                handleChange={handleChange}
                options={regionArr}
              /> */}
                <div className="flex flex-col relative">
                  {!ischild && (
                    <Field
                      as={MySelect}
                      canBeEmpty={true}
                      ismodif={isModif}
                      label={"Province"}
                      placeholder={"Province"}
                      isImportant={true}
                      name={"provinceId"}
                      handleChange={(e: any) => {
                        setSelectedProvinceId(e.target.value);
                        handleChange(e);
                        handleChange({
                          target: { name: "cityId", value: "" },
                        });
                      }}
                      options={provincesArr}
                    />
                  )}
                  <ErrorMessage
                    className="absolute -bottom-[35px] error-message"
                    name="provinceId"
                    component="div"
                  />
                </div>

                <div className="flex flex-col relative">
                  <div className={`${ischild ? "half-size pl-7" : ""}`}>
                    <Field
                      as={MySelect}
                      ismodif={isModif}
                      label={"Ville"}
                      canBeEmpty={true}
                      placeholder={"Ville"}
                      isImportant={true}
                      name={"cityId"}
                      handleChange={handleChange}
                      options={filteredCitiesArr}
                    />
                  </div>
                  <ErrorMessage
                    className="absolute -bottom-[35px] error-message"
                    name="cityId"
                    component="div"
                  />
                </div>
                <div className="flex flex-col relative">
                  <div className={`${ischild ? "duble-size" : ""}`}>
                    <Field
                      as={MyInput}
                      ismodif={isModif}
                      label={"Addresse"}
                      placeholder={"Addresse"}
                      isImportant={true}
                      name={"address"}
                      handleChange={handleChange}
                      hasLeft={false}
                      hasRight={false}
                      type={"text"}
                    />
                  </div>
                  <ErrorMessage
                    className="absolute -bottom-[35px] error-message"
                    name="address"
                    component="div"
                  />
                </div>
                {!ischild && (
                  <div className="flex flex-col relative">
                    <Field
                      as={MyInput}
                      ismodif={isModif}
                      label={"Complément d’adresse"}
                      placeholder={"Complément d’adresse"}
                      isImportant={false}
                      name={"address2"}
                      handleChange={handleChange}
                      hasLeft={false}
                      hasRight={false}
                      type={"text"}
                    />
                    <ErrorMessage
                      className="absolute -bottom-[35px] error-message"
                      name="adress2"
                      component="div"
                    />
                  </div>
                )}
                {/* {!ischild && (
                  <div className="flex flex-col relative">
                    <Field
                      as={MyInput}
                      ismodif={isModif}
                      label={"Code postal"}
                      placeholder={"Codepostal"}
                      // isImportant={true}
                      name={"postalCode"}
                      handleChange={handleChange}
                      hasLeft={false}
                      hasRight={false}
                      type={"number"}
                    />
                    <ErrorMessage className="absolute -bottom-[35px] error-message" name="postalCode" component="div" />
                  </div>
                )} */}
                {!ischild && (
                  <div className="flex flex-col relative">
                    <Field
                      as={MyInput}
                      ismodif={isModif}
                      label={"Téléphone mobile"}
                      placeholder={"Téléphone mobile"}
                      isImportant={false}
                      name={"phoneNumber"}
                      handleChange={handleChange}
                      hasLeft={true}
                      hasRight={false}
                      type={"text"}
                    />
                    <ErrorMessage
                      className="absolute -bottom-[35px] error-message"
                      name="phoneNumber"
                      component="div"
                    />
                  </div>
                )}
                {/* {!ischild && (
                  <div className="flex flex-col relative">
                    <Field
                      as={MyInput}
                      ismodif={isModif}
                      label={"Téléphone fixe"}
                      placeholder={"Téléphone fixe"}
                      isImportant={false}
                      name={"phoneNumber2"}
                      handleChange={handleChange}
                      hasLeft={true}
                      hasRight={false}
                      type={"text"}
                    />
                    <ErrorMessage className="absolute -bottom-[35px] error-message" name="phoneNumber2" component="div" />
                  </div>
                )} */}
                {/* {!ischild && (
                  <div className="flex flex-col relative">
                    <Field
                      as={MyInput}
                      ismodif={isModif}
                      label={"Adresse électronique"}
                      placeholder={"Adresse électronique"}
                      isImportant={false}
                      name={"email"}
                      handleChange={handleChange}
                      hasLeft={false}
                      hasRight={false}
                      type={"text"}
                    />
                    <ErrorMessage className="absolute -bottom-[35px] error-message" name="email" component="div" />
                  </div>
                )} */}
              </div>
            </div>
            {isModif && (
              <WrraperBotton>
                <button
                  type="button"
                  className="but"
                  onClick={handleCancelCick}
                >
                  Annuler
                </button>
                <button
                  className="but"
                  type="submit"
                  style={{ background: "#1AA5C0" }}
                >
                  {ischild || iswife ? "Suivant" : "Valider"}
                </button>
              </WrraperBotton>
            )}
          </Form>
        )}
      </Formik>
      <ConsultationStatus />
    </>
  );
};

export default AddPatientfrom;
