/**
 * this component is used whenever we catch an error, it contain a message, and a cta button
*/
import React from "react";

import { BiError } from "react-icons/bi";
 


interface IErrorComponentProps {
    message?: string;
    icon?: any;
    title?: string;
    ctaButton?: {
        text?: string;
        className?: string;
        handler?: () => {};
    }
}




export const ErrorComponent = ({title,message, ctaButton, icon }: IErrorComponentProps) => {



    return (
    <div className="min-h-full pt-16 pb-12 flex flex-col">
        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
           
            <div className="py-16">
                <div className="text-center">
                    <div className="flex justify-center items-center">
                    {/* <BiError size={70} color='red'/> */}
                    {icon && (<img src={icon}/>)}
                    </div>
                    <span className="mt-2 text-lg font-extrabold text-gray-900 tracking-tight sm:text-lg">
                        {title ? title : 'Erreur'}
                        </span>
                    <p className="mt-2 text-base text-gray-500">{message? message : 'Une erreur est survenue, veuillez actualiser'}</p>
                    {ctaButton && <div className="mt-6">
                        <button onClick={ctaButton?.handler ? ctaButton?.handler : ()=>{window.location.reload()}} className="text-base font-medium text-[#1aa5c0]-600 hover:text-[#1aa5c0]-500">
                        {ctaButton?.text ?ctaButton?.text : 'Actualiser'}<span aria-hidden="true"> &rarr;</span>
                        </button>
                    </div>}
                </div>
            </div>
        </main>
  
    </div>
)
}