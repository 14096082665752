export enum advancedMeasuresToolsIds {
    ECG = "Cardiogramme",
    DERMATOSCOPE="dermatoscope",
    OTOSCOPE="otoScope",
    IRISCOPE="iriscope",
    MOBILE_CAMERA="cameraMobile",
    ECHOGRAPHIE="echographie",
    NULL="null",
  }
export enum advancedMeasuresToolsNames {
    ECG = "Cardiogramme",
    DERMATOSCOPE="Dermatoscope",
    OTOSCOPE="OtoScope",
    IRISCOPE="Iriscope",
    MOBILE_CAMERA="Camera Mobile",
    ECHOGRAPHIE="Échographie",
    NULL="null",
  }
  