import { ForwardedRef, forwardRef } from "react";
import styled from "styled-components";

export const DeviceVideo = forwardRef(
  (props: any, deviceRef: ForwardedRef<HTMLVideoElement>) => {
    return (
      <VideoContainer>
        <Video
          ref={deviceRef}
          autoPlay
          muted
          playsInline
          className="w-full h-full border-radius-10 max-h-[60%]"
          style={{ objectFit: "cover" }}

          // maxHeight={props.maxHeight}
        />
      </VideoContainer>
    );
  }
);

const VideoContainer = styled.div`
  box-sizing: border-box;
  max-height: 100%;

  height: 100%;
  padding: 10px;
`;

const Video = styled.video`
  border-radius: 10px;
  width: 100%;
  max-height: 100%;
`;
