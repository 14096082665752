import * as React from 'react';

export interface ICallTimerProps {

  startTime?: number;
}



/**
 * 
 * @param props 
 * @returns 
 */
export default function CallTimer ({startTime = 0}: ICallTimerProps) {
    const [currentTime, setCurrentTime] = React.useState<number>(startTime);
    React.useEffect(() => {
        const updateCurrentTime = () => {
          setCurrentTime((prevTime) => prevTime + 1);
        };
        const timer = setInterval(updateCurrentTime, 1000);
        return () => {
          clearInterval(timer);
        };
      }, [currentTime]);



    
      const hours = String(Math.floor(currentTime / 3600)).padStart(2, "0");
      const minutes = String(Math.floor((currentTime % 3600) / 60)).padStart(
        2,
        "0"
      );



      const seconds = String(currentTime % 60).padStart(2, "0");
    
      const timeString = `${hours}:${minutes}:${seconds}`;
    
    return (
        <div className="absolute top-4 left-4">
        <p className="text-white">{timeString}</p>
      </div>
  );
}
