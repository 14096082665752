import React, { useState, useRef, useEffect } from 'react';
import { TextEditor } from './TextEditor'
import { Tooltip } from "react-tooltip";
import { MdKeyboardVoice } from "react-icons/md";

const SpeechToTextEditor = ({ data, message, callBack = (val) => { } }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [text, setText] = useState('');
  
  const [inttext, setInttext] = useState('');

  const [speechRecognitionAvailable, setSpeechRecognitionAvailable] = useState(false);
  const speechRecognition = useRef(null); // Use useRef to persist the instance without triggering re-renders

  const svgRecording = <><MdKeyboardVoice color="#ffffff" size={20} className="shrink-0" /></>

  useEffect(() => {

    setText(data)
    // Check for browser support for SpeechRecognition
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (SpeechRecognition) {
      speechRecognition.current = new SpeechRecognition();
      speechRecognition.current.continuous = true; // Continuously capture input
      speechRecognition.current.lang = 'fr-FR'; // Set language to French
      speechRecognition.current.interimResults = true; // Whether interim results should be returned
      setSpeechRecognitionAvailable(true);

      speechRecognition.current.onresult = (event) => {
        // Concatenate results to the textarea content
        let intermediate = '';
        for (let i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            setText((prevText) => prevText + event.results[i][0].transcript + ' ');
            setInttext('')
          }
          if (!event.results[i].isFinal) {
            intermediate = intermediate + event.results[i][0].transcript + ' '
          }


        }

        setInttext(intermediate)
      };

      speechRecognition.current.onerror = (event) => {
        console.error('Speech recognition error', event.error);
      };
    } else {
      console.warn('SpeechRecognition is not supported in this browser.');
      setSpeechRecognitionAvailable(false);
    }
  }, []);

  const handleStartRecording = () => {
    if (speechRecognition.current && !isRecording) {
      try {
        speechRecognition.current.start();
        setIsRecording(true);
      } catch (error) {
        console.error('Error starting speech recognition:', error);
      }
    }
  };

  const handleStopRecording = () => {
    if (speechRecognition.current && isRecording) {
      speechRecognition.current.stop();
      setIsRecording(false);
    }
  };


  const handleChange = (e) => {

    // setText(e.replace(/(<p[^>]+?>|<p>|<\/p>)/img, ""))
    setText(e)
    callBack(e)
  }


  return (
    <div className='relative'>
      <h6 className='text-[#50799E]  px-2 mb-2'>{message}</h6>
      <TextEditor className=" px-2 "
        text={text}
        onChange={(val) => { handleChange(val) }}
        placeholder="Start speaking or type here..."
      />
      {speechRecognitionAvailable &&
        <>
          <button onClick={isRecording ? handleStopRecording : handleStartRecording} className={`${isRecording ? 'stopRecording blob red' : 'startRecording'
            } text-white w-[32px] h-[32px]  py-2 px-4 rounded-full focus:outline-none focus:shadow-outline transform transition-colors duration-150 absolute top-[38px] right-[15px] flex justify-center content-center items-center`}

            data-tooltip-id={`${isRecording ? 'tooltip-stopRecording' : 'tooltip-startRecording'}`}
            data-tooltip-html={`${isRecording ? 'Arrêter la transcription ' : 'Démarrer la transcription'}`}
          > {svgRecording}</button>
          {isRecording && <Tooltip id='tooltip-stopRecording' className="z-[9999] w-[200px]" />}
          {!isRecording && <Tooltip id='tooltip-startRecording' className="z-[9999] w-[200px]" />}
        </>
      }
      {isRecording && <p className='text-[#1AA5C0] px-2 '>{inttext}</p>}








    </div>
  );
};

export default SpeechToTextEditor;
