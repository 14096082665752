import React, { useEffect, useState } from "react";
import Moderatedweight from "./../../assets/Moderatedweight.svg";
import PoidSevere from "./../../assets/img/svg/poidSevere.svg";
import PoidMorbide from "./../../assets/poidMorbide.svg";
import PoidSurpoids from "./../../assets/poidSurpoids.svg";
import PoidNormal from "./../../assets/poidNormal.svg";
import toast from "react-hot-toast";
import { IMC_Calculator } from "../../helper/mediaDeviceHelper";


interface IIMC {
  weight: number;
  height: number;
  data: any;
  measureSelected: any;
}
export default function IMC(props: IIMC) {

  const { height, weight, data, measureSelected } = props;
  
  
  const [imc, setIMC] = useState<number>(0);
  const [weightIcon, setWeightIcon] = useState<any>(null);

  // do it in deferent component
  //   Calculate IMC immediately when weight or height changes
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (weight === 0 || height === 0) {
        setIMC(0);
        setWeightIcon(null);
      }
     else{
      console.log("zz weight", weight);
      console.log("zz height", height);
      const heightInMeters = height / 100;
      const imcValue =  IMC_Calculator(weight, heightInMeters);
      setIMC(imcValue);
     }
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [weight, height, measureSelected]);

  useEffect(() => {
    if (weight === 0 || height === 0) {
      setIMC(0);
      // setWeightIcon(null);
    }
    if ( imc <=18.5 && imc <= 20) {
      // setWeightIcon(PoidNormal);
    }else
    if (imc >= 20 && imc <= 25) {
      setWeightIcon(PoidNormal);
    } else if (imc >= 25 && imc <= 30) {
      setWeightIcon(PoidSurpoids);
    } else if (imc >= 30 && imc <= 35) {
      setWeightIcon(Moderatedweight);
    } else if (imc > 35) {
      setWeightIcon(PoidMorbide);
    } else if (imc > 40) {
      imc > 40 &&
        toast.error("Résultat inhabituel. Merci d avoir tenté de recalculer.", {
          id: "01",
        });
    }
  }, [imc]);

  return (
    <div className="flex justify-between items-center">
      <div className="flex py-6 px-8 gap-6 rounded-lg w-full divide-x-4">
        <div className="flex flex-col items-center">
          <label htmlFor="" className="text-[#858585] text-sm">
            {data.title}
          </label>
          <p className="ml-5 font-bold text-gray-500">
            <span
              className={`text-${imc > 40 ? "red-500" : "gray-500"} mr-3 top-2 font-bold text-lg  ${imc > 40 ? "animate-ping" : "none"}`}
            >
              {imc ? imc.toFixed(1) : "--"}
            </span>
            {data.unite}
          </p>
        </div>
      </div>
      <div className=" mx-2  pl-2 border-[#d9d9d9]">
        <img className="w-50 h-50" src={weightIcon} alt="" />
      </div>
    </div>
  );
}
