
import './HostingOptionsStyle.css'

interface IHostingOptions {
  inreaseAmount: number;
  setIncreaseAmount: any;
}
const HostingOptions = (props: IHostingOptions) => {
  const { inreaseAmount: selectedOption, setIncreaseAmount: setSelectedOption } = props;


  const handleToggleChange = () => {
    setSelectedOption(prevOption => (prevOption === 0.01 ? 0.1 : 0.01));
  };

  return (
    <>

      <div className="mt-2 text-white">
        <label className="switch">
          <input type="checkbox" checked={selectedOption === 0.1}
            onChange={handleToggleChange} />
          <span className="slider">
            <span className="title">{selectedOption}</span>
            <span className="ball border-4 border-[#20A8D3]">
             
            </span>
          </span>
        </label>
      </div>






      {/* <input id="checkboxInput" type="checkbox" />
      <label className="toggleSwitch" htmlFor="checkboxInput">
      </label> */}

    </>);
};

export default HostingOptions;
