import React, { useEffect, useRef, RefObject } from "react";
const VideoPlayer = ({ user, size }: any) => {
  const ref: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  useEffect(() => {
   if(user.videoTrack) user.videoTrack.play(ref.current);
  }, []);


  

  return (
    <div
      ref={ref}
      className={`${
        size ? size.toString() : "h-[100%] w-[100%] scale-x-[-1] scale-y-[1]"
      }`}
    >


    </div>
  );
};
export default VideoPlayer;
