import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Toaster, toast } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import handleCategorizeAndSetFiles from "./handleCategorizeAndSetFiles";
import { advancedMeasuresToolsIds } from "./../../enums/advancedParams";

interface ExtendedFile extends File {
  preview: string;
}

interface FileCategories {
  [key: string]: ExtendedFile[];
}

const MAX_FILES = 10;
const MAX_SIZE = 20000000;

export const FileDropZone = (props: any) => {


  // consts
  const { files, setFiles, deviceSelected } = props;

  // useStates
  const [maxFiles, setMAxFiles] = useState(0);

  const initialState: FileCategories = Object.values(
    advancedMeasuresToolsIds
  ).reduce((acc, value) => {
    acc[value] = [];
    return acc;
  }, {} as FileCategories);

  const categorizeAndSetFiles = (propFiles: File[], categoryName?: string) => {
    handleCategorizeAndSetFiles(
      propFiles,
      deviceSelected,
      advancedMeasuresToolsIds,
      setFiles,
      categoryName
    );
  };

  const getMaxFiles = () => {
    const allFiles = files[deviceSelected!];

    if (!files[deviceSelected!]) return MAX_FILES;
    return MAX_FILES - allFiles.length <= 0 ? 0 : MAX_FILES - allFiles.length;
  };

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept:
        deviceSelected == advancedMeasuresToolsIds.ECHOGRAPHIE
          ? {
              "image/*": [],
              "Application/Pdf": [],
              "application/octet-stream": [],
              "application/dicom": [".dcm"],
              "Application/dicom": [],
            }
          : { "image/*": [], "Application/Pdf": [] },
      onDrop: (acceptedFiles: File[]) => {
        if (maxFiles - fileRejections.length <= 0) {
          toast.error(
            `Vous ne pouvez pas ajouter plus de ${MAX_FILES} fichiers !`,
            { id: "DropZone" }
          );
          return false;
        }
        categorizeAndSetFiles(acceptedFiles);
      },
      onError: (err) => {
        //
        return false;
      },
      disabled: maxFiles == 0 ? true : false,
      onDropRejected(fileRejections, event) {
        maxFiles - fileRejections.length <= 0
          ? toast.error(
              `Vous ne pouvez pas ajouter plus de ${MAX_FILES} fichiers !`,
              { id: "DropZone" }
            )
          : toast.error(
              `Le maximum de taille autorisé ${MAX_SIZE / 1000000}Mo a été dépassé !`,
              { id: "DropZone" }
            );
      },

      maxFiles: maxFiles,

      maxSize: MAX_SIZE,
    });

  useEffect(() => {
    setMAxFiles(getMaxFiles());
  }, [deviceSelected]);


  useEffect(() => {
    setMAxFiles(getMaxFiles());
  }, [acceptedFiles]);


  useEffect(() => {
    return () => {
      const allFiles = Object.values(files).flat() as ExtendedFile[];

      allFiles.forEach((file) => {
        URL.revokeObjectURL(file.preview);
      });
    };
  }, [files]);

  
  return (
    <>
      <section className=" w-[100%] h-[100%] p-relative">
        <label
          htmlFor="dropzone-file"
          className={`flex items-center justify-center w-full h-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600 ${maxFiles == 0 ? " opacity-50 cursor-not-allowed " : ""}`}
        >
          <div {...getRootProps({ className: "h-full w-full " })}>
            <input {...getInputProps()} disabled={maxFiles == 0} />

            <div className="flex flex-col items-center justify-center pt-[35%] pb-6">
              <svg
                className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                <span className="font-semibold">
                  Cliquer pour charger les images
                </span>{" "}
                ou glisser déposer ici
              </p>
              <p className="text-xs text-gray-500 dark:text-gray-400">
                {deviceSelected == advancedMeasuresToolsIds.ECHOGRAPHIE
                  ? "DICOM, PDF, PNG, JPG ou GIF"
                  : "PDF, PNG, JPG ou GIF"}
              </p>
              <p>
                {maxFiles == 0 ? (
                  <strong className="text-sm text-[#f44336]">
                    Vous avez chargé le maximum de fichier autorisé
                  </strong>
                ) : (
                  <strong>Max de fihciers autorisé: {maxFiles}</strong>
                )}
              </p>
            </div>
          </div>
        </label>
      </section>
    </>
  );
};
