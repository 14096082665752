import { Card, Spinner } from "@material-tailwind/react";

import { medicalAttachementsType } from "./../../enums/app";
import { IAdvancedAttachement } from "./../../interfaces/attachement/attachement.interface";
import { useLazyGetAttachmentByIdQuery } from "./../../features/Examen/vitalSigns/attachementApi";
import { generateRandomUniqueId } from "./../../utils/numbers.util";
import { getExtension } from "./../../utils";
import { NoRecordFound } from "./../../utils/NoRecordFound";
import FileThumbnail from "./../../FileViewer/FileThumbnail";
import { AdvancedParamsTools } from "../../constants";
import { advancedMeasuresToolsIds } from "../../enums/advancedParams";
import GallerySaving from "../advancedDevices/GallerySaving";
import { useEffect, useState } from "react";
import { useGetConsultationAttachementsQuery } from "../../features/Examen/vitalSigns/fileApi";

interface IReportGallerieProps {
  isRow?: boolean;
  consultationId: number;
  openRight:boolean;
}

const ReportGallerie = ({
  isRow = false,
  consultationId,
  openRight,
}: IReportGallerieProps) => {

  
  const {
    data: attachements,
    isLoading: attachementsisLoading,
    isError: attachementisError,
    refetch: refetchConsultation,
  } = useGetConsultationAttachementsQuery(consultationId);

  useEffect(() => {
    console.log("refetchConsultation");
    if (consultationId) {
      refetchConsultation();
    }
  }, [openRight]);

  //states
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<advancedMeasuresToolsIds>(
    advancedMeasuresToolsIds.ECG
  );
  const [selectedFile, setSelectedFile] = useState<IAdvancedAttachement | null>(
    null
  );

  const [albums, setAlbums] = useState<
    Array<{
      type: advancedMeasuresToolsIds;
      pictures: Array<{ key: string } & IAdvancedAttachement>;
    }>
  >([]);

  //api redux toolkit
  const [
    getAttachmentById,
    {
      data: attachmentData,
      isLoading: isLoadingAttachment,
      isSuccess: isSuccessAttachment,
      isError: isErrorAttachment,
    },
  ] = useLazyGetAttachmentByIdQuery();

  const buildAlbums = async (attachements: Array<IAdvancedAttachement>) => {
    setIsLoading(true);
    const groupedAlbums: Record<
      advancedMeasuresToolsIds,
      Array<{ key: string } & IAdvancedAttachement>
    > = {
      [advancedMeasuresToolsIds.MOBILE_CAMERA]: [],
      [advancedMeasuresToolsIds.DERMATOSCOPE]: [],
      [advancedMeasuresToolsIds.OTOSCOPE]: [],
      [advancedMeasuresToolsIds.ECHOGRAPHIE]: [],
      [advancedMeasuresToolsIds.ECG]: [],
      [advancedMeasuresToolsIds.IRISCOPE]: [],
      [advancedMeasuresToolsIds.NULL]: [],
    };

    // Group attachements based on type and generate a unique random key for each picture
    for (const attachement of attachements) {
      if (attachement.mimetype.includes("pdf")) continue;
      const res = await getAttachmentById({ id: attachement.id });

      const key = generateRandomUniqueId();
      groupedAlbums[attachement.type as advancedMeasuresToolsIds]?.push({
        ...attachement,
        key,
        blobUri: res.data ? URL.createObjectURL(res.data) : null,
        file: new File([res.data], attachement.path, {
          type: res.data.type,
        }),
      });
    }

    // Convert grouped albums to the required format
    const resultAlbums = Object.entries(groupedAlbums).map(
      ([type, pictures]) => ({
        type: type as advancedMeasuresToolsIds,
        pictures,
      })
    );

    console.log("attachements", attachements);
    console.log("resultAlbums", resultAlbums);

    setAlbums(resultAlbums);
    setIsLoading(false);
  };

  const handleClickType = (type: advancedMeasuresToolsIds) => {
    setSelectedType(type);
    // setAlbums(albums.filter((item)=> item.type === type ))
  };

  const handleOnClickPreviewFile = (file: IAdvancedAttachement) => {
    setOpenDialog(true);
    setSelectedFile(file);
  };

  useEffect(() => {
    console.log("Gallery attachements ", attachements);
    if (attachements) {
      buildAlbums(attachements.results);
    }
  }, [attachements]);

  if (isLoading) {
    return (
      (isLoading || isLoadingAttachment) && (
        <div className="w-full h-full flex flex-row justify-center ">
          <Spinner />
        </div>
      )
    );
  }
  if (albums.every((device) => device.pictures.length === 0))
    return (
      <NoRecordFound
        type="custom"
        message="Aucun media disponible pour cette consultation"
      />
    );

  return (
    <>
      {/* <div className="border border-[#1AA5C0] rounded-md m-3"> */}
      <div className="grid grid-cols-4 h-full justify-items-center">
        <div className="col-span-1 h-full">
          <div className="flex flex-col gap-4 w-24 border-r-2 mr-4 justify-around h-[80%]">
            {AdvancedParamsTools.map((item, index) => (
              <img
                src={item.icon}
                key={index}
                onClick={() => {
                  // handelSelectCategory(item.ToolsIds, index);
                  handleClickType(item.ToolsId);
                }}
                className={`${
                  selectedType === item.ToolsId
                    ? "w-16 h-15 ml-4 cursor-pointer bg-[#E9F8F9] p-3 rounded-lg"
                    : "w-16 h-15 ml-4 cursor-pointer  p-3"
                }`}
              />
            ))}
          </div>
        </div>

        {selectedFile && openDialog ? (
          <div className="col-span-3 w-full">
            <GallerySaving
              selectedPicture={selectedFile!}
              detailsPic={openDialog}
              setDetailsPic={setOpenDialog}
            />
          </div>
        ) : (
          <div className="col-span-3 w-full overflow-auto">
            {!isLoading &&
              !isLoadingAttachment &&
              albums
                .filter((item) => item.type === selectedType)
                .map((item) => {
                  return (
                    <div className="">
                      {item.pictures.length === 0 && (
                        <div className="w-full">
                          <NoRecordFound
                            type="custom"
                            title={"Attachements"}
                            message="aucun media"
                          />
                        </div>
                      )}

                      {item.pictures.map((file,index) => {
                        return (
                          <div
                            onClick={() => handleOnClickPreviewFile(file)}
                            className="p-2"
                            key={file.id}
                          >
                            {file.blobUri !== null ? (
                              <div className="relative flex flex-row ">
                                <Card
                                  placeholder={undefined}
                                  className="cursor-pointer  overflow-hidden transition-opacity hover:opacity-90 flex-none relative
                "
                                  // flex overflow-y-auto flex-col mt-40 h-[28rem] gap-2 w-96 items-center
                                  onClick={() => {
                                    handleOnClickPreviewFile(file);
                                  }}
                                >
                                  <span className="bg-[#ffffff70] text-[#505050] text-xs font-medium px-1  rounded absolute bottom-2 left-2 z-10 noSelect">
                                    {getExtension(file["path"]).toUpperCase()}
                                  </span>

                                  <div className="h-32 w-[100%] flex-shrink-0 img-hover-zoom">
                                    <span>
                                      <svg
                                        width="38"
                                        height="38"
                                        viewBox="0 0 37 38"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M18.6667 35.6693C27.8714 35.6693 35.3333 28.2074 35.3333 19.0026C35.3333 9.79786 27.8714 2.33594 18.6667 2.33594C9.46192 2.33594 2 9.79786 2 19.0026C2 28.2074 9.46192 35.6693 18.6667 35.6693Z"
                                          stroke="#FFFFFF"
                                          stroke-width="2.83333"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M18.6641 12.3359V25.6693"
                                          stroke="#FFFFFF"
                                          stroke-width="2.83333"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M12 18.9995H25.3333"
                                          stroke="#FFFFFF"
                                          stroke-width="2.83333"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </span>
                                    <FileThumbnail
                                      File={file.file}
                                      key={"file-thumbnail-" + file.id}
                                    />
                                  </div>
                                </Card>
                              </div>
                              
                            ) : (
                              <></>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
          </div>
        )}
      </div>
    </>
  );
};

export default ReportGallerie;
