// dialogSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SLICE } from "../../enums";
import APP_CONSTANTS from "../../constants";


interface AlertState  {
    closedAlerts: Array<string>;
}

/**
 *
 */
const initialState: AlertState = {
  closedAlerts: JSON.parse(localStorage.getItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}closedAlerts`)  || '[]')
};

const alertSlice = createSlice({
  name: SLICE.ALERT,
  initialState,
  reducers: {
 
    closeAlert: (state, action: PayloadAction<string>) => {
        state.closedAlerts?.push(action.payload);
        localStorage.setItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}closedAlerts`,JSON.stringify(state.closedAlerts));
    },
  }, 
});

export const { closeAlert } = alertSlice.actions;

export default alertSlice.reducer;
