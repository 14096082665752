import React, { useEffect, useState } from "react";
import TermoSvg from "../../../../../assets/img/svg/thermo.svg";

import { useDispatch } from "react-redux";
import { isUpdating, updateExaminations, vitalSignsType } from "../../features/Examen/vitalSigns/ExaminationsSlice";
import { connectedBasicDeviceIds } from "../../enums/vitalSigns";
import RangeInput from "../shared/RangeInput";

interface ICardWithSingleValue {
  icon: any;
  title: string;
  unite: string;
  id: string;
  maxValue: number;
  minValue: number;
  measureValue: number;
  setMeasureValue: (value: number) => void;
  setSelectedDevice: (value: string | null) => void;
  selectedDevice: string | null;
  setStopDevice: (value: boolean) => void;
  sensorAvailable: boolean;
  measureSelected: vitalSignsType;
  isConsultation: boolean;
}

interface ICardWithSingleValue {
  icon: any;
  title: string;
  unite: string;
  id: string;
  maxValue: number;
  minValue: number;
  measureValue: number;
  setMeasureValue: (value: number) => void;
  setSelectedDevice: (value: string | null) => void;
  selectedDevice: string | null;
  setStopDevice: (value: boolean) => void;
  sensorAvailable: boolean;
  measureSelected: vitalSignsType;
  isConsultation: boolean;
  increaseAmountSelected:number;
}

const CardWithSingleValue = ({
  icon,
  title,
  unite,
  id,
  maxValue,
  minValue,
  setMeasureValue,
  measureValue,
  sensorAvailable,
  setSelectedDevice,
  selectedDevice,
  setStopDevice,
  measureSelected,
  isConsultation,
  increaseAmountSelected,
}: ICardWithSingleValue) => {
  // Socket context Values

  // States
  const [isChecked, setIsChecked] = useState(true);


  // Dispatch
  const dispatch = useDispatch();


  // handlers
  const handleSwitchChange = (event: any) => {
    setIsChecked(event.target.checked);
  };

  const handleIncrementMeasure = () => {
    if (measureValue <= minValue) {
      setMeasureValue(
        Math.min(minValue + Number(increaseAmountSelected), maxValue)
      );
    } else {
    if (id === connectedBasicDeviceIds.RESPIRATORY_RATE) {
      console.log("<< here");
      setMeasureValue(Math.min(measureValue + 1, maxValue));
    } else {
      console.log("<< here",measureValue,  );

      setMeasureValue(
        Number(
          Math.min(
            measureValue + Number(increaseAmountSelected),
            maxValue
          ).toFixed(2)
        )
      );
      }
    }
  };

  const handleDecrementMeasure = () => {
    if (id === connectedBasicDeviceIds.RESPIRATORY_RATE)
      setMeasureValue(Math.max(minValue, Math.min(measureValue - 1, maxValue)));
    else
      setMeasureValue(
        Number(
          Math.max(
            minValue,
            Math.min(measureValue - Number(increaseAmountSelected), maxValue)
          ).toFixed(2)
        )
      );
  };

  //useEffects
  // on measure value changes wait for 3sc and disptach the update value of baseParamsDataSlice
  useEffect(() => {
    // dispatch state that is not saved yet
    dispatch(isUpdating(true));

    const timer = setTimeout(() => {

      dispatch(
        updateExaminations({
          index: measureSelected.index,
          measure: id,
          data: measureValue,
        })
      );

    }, 1000);
    return () => clearTimeout(timer);
  }, [measureValue]);

  useEffect(() => {
    setMeasureValue(measureSelected[id]);
  }, [measureSelected]);

  useEffect(() => {
    // console.log("selectedDevice", selectedDevice);
    // console.log("id", id);
  }, [selectedDevice]);

  return (
    <>
      <div className="grid grid-rows-3 border rounded-lg mx-3 px-3 py-1 bg-white">
        <div className="grid grid-cols-2">
          {/* firstColumn */}
          <div className="flex justify-start gap-2 items-center">
            <img src={icon} alt="icon" className="w-10 h-10" />
            <p className="text-[#335D84] text-sm font-semibold">{title}</p>
          </div>
          {/* secondColumn */}
          {sensorAvailable ? (
            <div className="flex flex-col p-0 mt-3 ml-3">
              <div className="flex justify-center gap-5 items-center">
                <span className="font-semibold">M</span>
                <div>
                  <div className="inline-flex items-center">
                    <div className="relative inline-block w-8 h-4 rounded-full cursor-pointer">
                      <input
                        id={id}
                        type="checkbox"
                        className="absolute w-8 h-4 transition-colors duration-300 rounded-full appearance-none cursor-pointer peer bg-blue-gray-100 checked:bg-[#335D84] peer-checked:border-gray-900 peer-checked:before:bg-gray-900"
                        onChange={handleSwitchChange}
                        checked={isChecked}
                      />
                      <label
                        htmlFor={id}
                        className="before:content[''] absolute top-2/4 -left-1 h-5 w-5 -translate-y-2/4 cursor-pointer rounded-full border border-blue-gray-100 bg-white shadow-md transition-all duration-300 before:absolute before:top-2/4 before:left-2/4 before:block before:h-10 before:w-10 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity hover:before:opacity-10 peer-checked:translate-x-full peer-checked:border-gray-900 peer-checked:before:bg-gray-900"
                      >
                        <div
                          className="inline-block p-5 rounded-full top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4"
                          data-ripple-dark="true"
                        ></div>
                      </label>
                    </div>
                  </div>
                </div>
                <span className="font-semibold">A</span>
              </div>
              <div className="flex justify-center items-center w-max">
                <p className="font-semibold text-[#858585] text-xs">
                  Passer en automatique
                </p>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        {/* body */}
        <div className="h-20 bg-[#F5F5F5] rounded-lg flex justify-center items-center align-middle">
          <p className="font-bolder text-[#333333] text-xl">
            {measureValue && measureValue > minValue ? measureValue.toFixed(2) : "--"}
            <span className="font-bold text-lg text-gray-500 ml-2">
              {unite}
            </span>
          </p>
        </div>
        <div className="w-full mt-3 p-0 m-0">
          {(!isChecked || !sensorAvailable) && isConsultation ? (
            <RangeInput
              value={measureValue}
              setValue={setMeasureValue}
              maxValue={maxValue}
              minValue={minValue}
              twoValues={false}
              onDecrement={handleDecrementMeasure}
              onIncrement={handleIncrementMeasure}
            />
          ) : (
            <>
              {selectedDevice !== id ? (
                <button
                  className={`w-full py-2 ${sensorAvailable ? "text-white bg-[#335D84]" : " bg-[#F5F5F5] cursor-not-allowed"}  rounded-lg font-semibold mt-2`}
                  onClick={() => {
                    if (sensorAvailable) {
                      setSelectedDevice(id);
                    }
                  }}
                >
                  Commencer
                </button>
              ) : (
                <button
                  className="w-full py-2 text-white bg-[#cb4040] rounded-lg font-semibold mt-2"
                  onClick={() => {
                    if (sensorAvailable) {
                      setStopDevice(true);
                    }
                  }}
                >
                  Arrêter
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CardWithSingleValue;
