import React, { useEffect } from "react";
import VitalSigns from "./VitalSigns";
// import { EncryptedStorage } from "../../../../shared";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";

// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { FaCheck, FaTrash } from "react-icons/fa";
import { HiOutlineSelector } from "react-icons/hi";
import { FiPlusCircle } from "react-icons/fi";
import { RxCountdownTimer } from "react-icons/rx";
import {
  SelectIsHistoryExists,
  SelectExaminations,
  removeExamination,
  updateExaminations,
  addExamination,
  examinationCurrentStep,
  SelectExaminationsIsUpdating,
} from "../../features/Examen/vitalSigns/ExaminationsSlice";
import { SelectExaminationData } from "../../features/Examen/vitalSigns/ExaminationDataSlice";
import useSharedDynamicModal from "../../hooks/useSharedDynamicModal";
import { RootState } from "../../store";
import VitalSignsHistory from "../shared/VitalSignsHistory";
import HostingOptions from "./HostingOptions";
import { getPriseNum } from "../../helper/mediaDeviceHelper";
import { useParams } from "react-router-dom";
import DebounceAnimation from "./DebounceAnimation";
import { setVitalSignsConsultation } from "../../features/consultationData/consultationDataSlice";
import toast from "react-hot-toast";
import { patchVitalSigns, postVitalSigns } from "../../axiosAPI/params";
import VitalTable from "../vitalSigns/VitalTable";


function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}
interface IBasicDevicesProps {
  setSelectedExamination?: any;
  scrollRef?: any;
  isConsultation: boolean;
  isTodayHistoryexits?: boolean;
  patientId: number;
  patient: any;
  setSelectedPeripherique?: any;
  consultationId: number;
}

interface StructuredSigns {
  [key: string]: Array<{
    createdAt: string;
    value: number | undefined;
  }>;
}

/**
 * BasicDevices main component
 * @param props
 * @returns
 */
const BasicDevices = ({
  scrollRef,
  isConsultation,
  isTodayHistoryexits,
  patientId,
  patient,
  setSelectedPeripherique,
  consultationId,
}: IBasicDevicesProps) => {
  // Props
  const { openSharedDynamicModal, closeSharedDynamicModal } =
    useSharedDynamicModal();

  // Selectors
  const baseParamsData = useSelector(SelectExaminationData);
  const ExaminationsList = useSelector(SelectExaminations);
  // const historyExists = useSelector(SelectIsHistoryExists);
  // const { isMeasuringStarted } = useSelector(
  //   (state: RootState) => state.basicDevicesMeasures
  // );

  // useStates

  const [measureAdded, setMeasureAdded] = useState(false);
  const [openModalMesure, setOpenModalMesure] = useState(false);
  const [increaseAmount, setIncreaseAmount] = useState<number>(0.1);
  const [measureSelected, setMeasureSelected] = useState(ExaminationsList[0]);

  const isUpdating = useSelector(SelectExaminationsIsUpdating);

  // Dispatch
  const dispatch = useDispatch();

  // useEffects
  useEffect(() => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    if (measureAdded) {
      setMeasureSelected(ExaminationsList[ExaminationsList.length - 1]);
    }
  }, [ExaminationsList]);

  // Hnadlers
  const handleClick = async () => {
    setMeasureAdded(true);

    if (ExaminationsList && ExaminationsList.length >= 3) return false;
    {
      dispatch(
        addExamination({
          data: baseParamsData,
        })
      );
    }
  };

  const handleOpenMeasuresHistoryButtonClick = () => {
    if (patientId) {
      openSharedDynamicModal({
        message: "",
        size: "xl",
        component: <VitalTable idOfPatient={patientId} />,
        isFormModal: false,
        title: "Historique de mesures",
        confirmButton: null,
        cancelButton: null,
      });
    }
  };

  const saveVitalSigns = () => {
    const currentVitalSigns = {
      weight: ExaminationsList[0].weight,
      height: ExaminationsList[0].height,
      temperature: ExaminationsList[0].temperature,
      sys: ExaminationsList[0].sys,
      dia: ExaminationsList[0].dia,
      pulse: ExaminationsList[0].pulse,
      spo2: ExaminationsList[0].spo2,
      bloodSugar: ExaminationsList[0].bloodSugar,
      respiratoryRythm: ExaminationsList[0].respiratoryRythm,
      fatMass: null,
      leanMass: null,
    };

    if (localStorage.getItem("currentConsultationVitalSigns")) {
      const storageContent = JSON.parse(
        localStorage.getItem("currentConsultationVitalSigns")!
      );
      patchVitalSigns(currentVitalSigns, storageContent.id).then(
        (response: any) => {
          localStorage.setItem(
            "currentConsultationVitalSigns",
            JSON.stringify({
              ...ExaminationsList[0],
              id: response.data.id,
              patientId: patientId!,
              consultationId: consultationId,
            })
          );
          toast.success("Les signs vitaux ont été modifier avec succès");
        }
      );
    } else {
      postVitalSigns(
        {
          ...currentVitalSigns,
          patientId: patientId!,
          doctorId: parseInt(localStorage.getItem("vs_doctorId")!),
        },
        patientId!
      ).then((response: any) => {
        toast.success("Les signs vitaux ont été enregistrés avec succès");
        localStorage.setItem(
          "currentConsultationVitalSigns",
          JSON.stringify({
            ...ExaminationsList[0],
            id: response.data.id,
            patientId: patientId!,
            consultationId: consultationId,
          })
        );
      });
    }

    // dispatch(
    //   setVitalSignsConsultation({
    //     weight: ExaminationsList[0].weight,
    //     height: ExaminationsList[0].height,
    //     temperature: ExaminationsList[0].temperature,
    //     sys: ExaminationsList[0].sys,
    //     dia: ExaminationsList[0].dia,
    //     pulse: ExaminationsList[0].pulse,
    //     spo2: ExaminationsList[0].spo2,
    //     bloodSugar: ExaminationsList[0].bloodSugar,
    //     respiratoryRythm: ExaminationsList[0].respiratoryRythm,
    //     fatMass: null,
    //     leanMass: null,
    //     patientId: patientId!,
    //     doctorId: parseInt(localStorage.getItem("vs_doctorId")!),
    //   })
    // );

    setSelectedPeripherique(-1);
  };

  return (
    <div style={{ position: "relative", width: "100vw" }}>
      {/* {!isMeasuringStarted &&
        isConsultation &&
        !isTodayHistoryexits &&
        !historyExists && (
          <StartMeasuring isContinue={isTodayHistoryexits ?? false} />
        )} */}

      <div
        className={
          // !isMeasuringStarted &&
          // isConsultation &&
          // !isTodayHistoryexits &&
          // !historyExists
          // ? "blur-sm bg-white border rounded-tr-lg h-[100%] overflow-y-auto rounded-tl-lg":
          "bg-white border p-3 rounded-tr-lg overflow-y-auto rounded-lg"
        }
      >
        <div className="flex flex-col items-center rounded-md p-5  bg-[#F1F8F9]">
          <div className="flex justify-end items-center gap-4 my-2 mr-3">
            {isConsultation ? (
              <HostingOptions
                inreaseAmount={increaseAmount}
                setIncreaseAmount={setIncreaseAmount}
              />
            
            ) : (
              <></>
            )}

            {/* <Listbox value={measureSelected} onChange={setMeasureSelected}> */}
            {/* {({ open }) => (
                <> */}
            {/* <div className=" relative w-96 my-1 shadow-lg rounded-full"> */}
            {/* {isConsultation ? (
                      <Listbox.Button className="rangBtnminus !w-full">
                        <span className="w-full inline-flex justify-between truncate">
                          <span className="ml-2 truncate text-white font-bold">
                            {getPriseNum(measureSelected.index)}
                          </span>
                        </span>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                          <HiOutlineSelector
                            className="h-5 w-5 text-white"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>
                    ) : (
                      ""
                    )} */}

            {/* <Transition
                      show={open}
                      as={Fragment}
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <Listbox.Options
                        style={{ zIndex: 110 }}
                        className="absolute w-full shadow-lg max-h-60 rounded-md bg-[#E0F3FC] py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                      >
                        {ExaminationsList.map((item: any) => (
                          <Listbox.Option
                            key={item.index}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? "text-white bg-[#335D84]"
                                  : "text-gray-900",
                                "cursor-pointer select-none relative py-2 pl-3 pr-9 z-100"
                              )
                            }
                            // onclick if active
                            onClick={() => {
                              dispatch(
                                examinationCurrentStep({ step: item.index })
                              );
                            }}
                            value={item}
                          >
                            {({ selected, active }) => (
                              <>
                                <div className="flex justify-between z-100">
                                  <span
                                    className={classNames(
                                      selected ? "font-bold" : "font-normal",
                                      "truncate"
                                    )}
                                  >
                                    {getPriseNum(item.index)}
                                  </span>
                                  <span
                                    className={classNames(
                                      active
                                        ? "text-[#1aa5c0]-200"
                                        : "text-gray-500",
                                      "ml-2 truncate cursor-pointer"
                                    )}
                                    onClick={() => {
                                      // TODO: remove the object with the id
                                      dispatch(
                                        removeExamination({ index: item.index })
                                      );
                                    }}
                                  >
                                    <FaTrash
                                      color="white"
                                      size={30}
                                      className="bg-red-400 p-2 rounded-full"
                                    />
                                  </span>
                                </div>
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition> */}
            {/* </div> */}
            {/* </>
              )} */}
            {/* </Listbox> */}

            {isConsultation ? (
              <>
                <button
                  onClick={handleOpenMeasuresHistoryButtonClick}
                  className="rangBtnminus px-4 flex gap-3 items-center py-1"
                >
                  <RxCountdownTimer size={20} />
                  Historique
                </button>

                {/* <button
                  className={`rangBtnminus ${
                    ExaminationsList && ExaminationsList.length >= 3
                      ? " rangBtnDisabled"
                      : ""
                  } px-4 flex gap-3 items-center py-1`}
                  onClick={() => {
                    // dispatch the existing data in exaaminationDataSlice to the ExaminationSlice
                    handleClick();
                  }}
                  disabled={ExaminationsList && ExaminationsList.length >= 3}
                >
                  <FiPlusCircle size={20} />
                  Nouvelle prise
                </button> */}
              </>
            ) : (
              <></>
            )}
            {isConsultation && (
              <div>
                {!isUpdating ? (
                  <button
                    style={{ zIndex: "9999" }}
                    onClick={() => {
                      saveVitalSigns();
                    }}
                    className={`${"bg-[#F8809D] "} focus:text-white items-center  px-4 py-1  font-semibold rounded-full text-white mr-3 pb-2`}
                  >
                    {"Terminer les mesures"}
                  </button>
                ) : (
                  <DebounceAnimation />
                )}
              </div>
            )}
          </div>

          <div className="flex w-full h-full pt-3">
            <VitalSigns
              openModalMesure={openModalMesure}
              setOpenModalMesure={setOpenModalMesure}
              measureSelected={measureSelected}
              increaseAmountSelected={increaseAmount}
              isConsultation={isConsultation}
              patientId={patientId}
              patient={patient}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicDevices;
