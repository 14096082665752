import moment from "moment";

/**
 *
 * @param firstName
 * @returns
 */
export function formatFirstName(firstName: string): string {
  return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
}

/**
 *
 * @returns
 */
export function generateUniqueId() {
  const timestamp = new Date().getTime();
  const random = Math.floor(Math.random() * 10000);

  return `${timestamp}-${random}`;
}

/**
 *
 * @param lastName
 * @returns
 */
export function formatLastName(lastName: string): string {
  return lastName.toUpperCase();
}

/**
 *
 * @param timestamp
 * @returns
 */
export function formatTimestamp(timestamp: string) {
  const inputDate = moment.utc(timestamp);
  const currentDate = moment();

  const formattedDate = inputDate.format("DD-MM-YYYY [à] HH:mm");
  const diffInSeconds = currentDate.diff(inputDate, "seconds");
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);
  const diffInMonths = currentDate.diff(inputDate, "months");
  const diffInYears = currentDate.diff(inputDate, "years");

  let diffText = "";
  if (diffInSeconds < 60) {
    diffText = `${diffInSeconds} second${diffInSeconds !== 1 ? "s" : ""}`;
  } else if (diffInMinutes < 60) {
    diffText = `${diffInMinutes} minute${diffInMinutes !== 1 ? "s" : ""}`;
  } else if (diffInHours < 24) {
    diffText = `${diffInHours} heure${diffInHours !== 1 ? "s" : ""}`;
  } else if (diffInDays < 30) {
    diffText = `${diffInDays} jour${diffInDays !== 1 ? "s" : ""}`;
  } else if (diffInMonths < 12) {
    diffText = `${diffInMonths} mois`;
  } else {
    diffText = `${diffInYears} an${diffInYears !== 1 ? "s" : ""}`;
  }

  return `${formattedDate} (${diffText})`;
}

export function getFormattedDateTime() {
  // Array of day names
  const dayNames = [
    "dimanche",
    "lundi",
    "mardi",
    "mercredi",
    "jeudi",
    "vendredi",
    "samedi",
  ];

  // Array of month names
  const monthNames = [
    "janvier",
    "février",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "août",
    "septembre",
    "octobre",
    "novembre",
    "décembre",
  ];

  // Get the current date
  const currentDate = new Date();

  // Get the day of the week (0-6)
  const dayIndex = currentDate.getDay();

  // Get the day of the month (1-31)
  const day = currentDate.getDate();

  // Get the month (0-11)
  const monthIndex = currentDate.getMonth();

  // Create the formatted date string
  const formattedDate =
    dayNames[dayIndex] + " " + day + " " + monthNames[monthIndex];

  // Get the hours (0-23)
  const hours = currentDate.getHours();

  // Get the minutes (0-59)
  const minutes = currentDate.getMinutes();

  // Format the hours and minutes
  const formattedTime =
    ("0" + hours).slice(-2) + ":" + ("0" + minutes).slice(-2);

  // Return the formatted date and time as an array
  return [formattedDate, formattedTime];
}
export function getYears() {
  const currentYear = new Date().getFullYear();

  const yearOptions: [number] = [new Date().getFullYear()];
  for (let i = 1; i < 65; i++) {
    const year: number = currentYear - i;
    yearOptions.push(year);
  }
  return yearOptions;
}

export function updateTime() {
  const currentTime = new Date();
  const minutes = currentTime.getMinutes().toString().padStart(2, "0");
  const seconds = currentTime.getSeconds().toString().padStart(2, "0");
  const formattedTime = `${minutes}:${seconds}`;

  // Update the display
  return formattedTime;
}

/**
 * Computes the age based on the provided birth date.
 * @param {Date | string} birthDate - The birth date of the person.
 * @returns {[number, string]} The age and unit (in years, months, or days) formatted in French.
 */
export const computeAge = (
  birthDate?: Date | string | null
): [number, string] => {
  if (birthDate) {
    // Parse the birthDate using Moment.js
    const parsedBirthDate = moment(birthDate);

    // Check if the birthDate is valid
    if (!parsedBirthDate.isValid()) {
      throw new Error("Invalid birth date");
    }

    // Get the current date
    const currentDate = moment();

    // Calculate the difference between the current date and birth date
    const diffDuration = moment.duration(currentDate.diff(parsedBirthDate));

    // Calculate age in years, months, and days
    const years = diffDuration.years();
    const months = diffDuration.months();
    const days = diffDuration.days();

    // Determine the unit
    let unit = " ans";
    if (years > 0) {
      unit = " an" + (years > 1 ? "s" : "");
    } else if (months > 0) {
      unit = " mois" + (months > 1 ? "" : "");
    } else if (days > 0) {
      unit = " jour" + (days > 1 ? "s" : "");
    }

    // Return age and unit as an array
    return [years || months || days, unit];
  }

  // If birthDate is not provided
  return [0, " ans"]; // Return -1 for age and empty string for unit
};

export function convertSlotsToDatetime(
  slotsData: Record<number, string[]>
): Record<number, string[]> {
  const result: Record<number, string[]> = {};

  for (const day in slotsData) {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + Number(day));
    if (Object.prototype.hasOwnProperty.call(slotsData, day)) {
      const slots = slotsData[day];

      // Check if the array of slots for the current day is not empty
      if (slots.length > 0) {
        const formattedSlots: string[] = [];

        for (const slot of slots) {
          const [startTime, endTime] = slot.split("-").map((time) => {
            const [hours, minutes] = time.split(":").map(Number);

            // Use the current date and set the hours and minutes
            const dateWithTime = new Date(currentDate);
            dateWithTime.setHours(hours);
            dateWithTime.setMinutes(minutes);

            return dateWithTime.toISOString();
          });

          formattedSlots.push(`${startTime}|${endTime}`);
        }

        result[parseInt(day)] = formattedSlots;
      }
    }
  }

  return result;
}

export function convertTimeSlotsToSlotsArray(
  slotsData: Record<number, string[]>
): Record<number, string[]> {
  const result: Record<number, string[]> = {};

  for (const day in slotsData) {
    if (Object.prototype.hasOwnProperty.call(slotsData, day)) {
      const slots = slotsData[day];
      const formattedSlots: string[] = [];

      for (const slot of slots) {
        const [start, end] = slot.split("|").map((time) => {
          const date = new Date(time);
          return `${String(date.getHours()).padStart(2, "0")}:${String(
            date.getMinutes()
          ).padStart(2, "0")}`;
        });

        formattedSlots.push(`${start}-${end}`);
      }

      result[parseInt(day)] = formattedSlots;
    }
  }

  return result;
}

export const filterSlots = (slots: any[]) => {
  return slots.filter((slot: { split: (arg0: string) => [any, any] }) => {
    const [startTime, endTime] = slot.split("-");
    const [hours, minutes] = endTime.split(":").map(Number);

    // Check if the slot ends before 18:00 (6:00 PM)
    return hours < 18 || (hours === 18 && minutes === 0);
  });
};
export function getFirstDayOfMonth(date: Date) {
  const year = date.getFullYear();
  const month = date.getMonth();
  return new Date(year, month, 1);
}

export function getLastDayOfMonth(date: Date) {
  const year = date.getFullYear();
  const month = date.getMonth();
  return new Date(year, month + 1, 1);
}

export function getFirstLastDayOfMonth(date: Date): { from: Date; to: Date } {
  return { from: getFirstDayOfMonth(date), to: getLastDayOfMonth(date) };
}
export function getPassedTime(date: Date): string {
  const dateNow = new Date();
  const difference_In_Time = dateNow.getTime() - date.getTime();
  const diff =
    difference_In_Time < 1000 * 3
      ? " Maintenant"
      : difference_In_Time < 1000 * 60
        ? Math.round(difference_In_Time / 1000) + " s"
        : difference_In_Time < 1000 * 60 * 60
          ? Math.round(difference_In_Time / (1000 * 60)) + " min"
          : difference_In_Time < 1000 * 60 * 60 * 24
            ? Math.round(difference_In_Time / (1000 * 60 * 60)) + " h"
            : Math.round(difference_In_Time / (1000 * 60 * 60 * 24)) + " j";

  return diff;
}

// ✅ Get the first last day of current month
function getFirstLastDayOfWeek(date: Date): { from: Date; to: Date } {
  const start = moment().startOf("week").toDate();
  const end = moment().endOf("week").toDate();
  return { from: start, to: end };
}
// ✅ Get  time passed
function getTimePassed(date: Date): string {
  const dateNow = new Date();
  const difference_In_Time = dateNow.getTime() - date.getTime();
  const diff =
    difference_In_Time < 1000 * 3
      ? " Maintenant"
      : difference_In_Time < 1000 * 60
        ? Math.round(difference_In_Time / 1000) + " s"
        : difference_In_Time < 1000 * 60 * 60
          ? Math.round(difference_In_Time / (1000 * 60)) + " min"
          : difference_In_Time < 1000 * 60 * 60 * 24
            ? Math.round(difference_In_Time / (1000 * 60 * 60)) + " h"
            : Math.round(difference_In_Time / (1000 * 60 * 60 * 24)) + " j";

  return diff;
}

export { getFirstLastDayOfWeek, getTimePassed };
