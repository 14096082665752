// formSlice.ts
import { RootState } from "../../../store";
import { SLICE } from "../../../enums/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";




export type vitalSignsType = {
  id?: number | null;
  index: number;
  weight: number;
  height: number;
  temperature: number;
  sys: number;
  dia: number;
  spo2: number;
  pulse: number;
  bloodSugar: number;
  respiratoryRythm: number;
  //   imc: number;
  //   SMM: number;
  //   graisse: number;
  //   gresse_porcentage: number;
};

interface IVitalSignsType {
  data: vitalSignsType[];
  currentStep: number;
  error: string | null;
  isLoading: boolean;
  isUpdating: boolean;
  isRefetchRequired: boolean;
  isHisotyExists:boolean;
}

const localMeasures =  localStorage.getItem("currentConsultationVitalSigns");
const patientId = localStorage.getItem("consultationInfos") && JSON.parse(localStorage.getItem("consultationInfos")!).patientId;
const consultationId = localStorage.getItem("consultationInfos") && JSON.parse(localStorage.getItem("consultationInfos")!).consultationId;

const initialState: IVitalSignsType = {
  data: [
    localMeasures && JSON.parse(localMeasures).patientId == patientId && JSON.parse(localMeasures).consultationId == consultationId  ? {
     ...JSON.parse(localStorage.getItem("currentConsultationVitalSigns")!)
    }:
    {
      id: null,
      index: 1,
      weight: 0,
      height: 0,
      temperature: 0,
      sys: 0,
      dia: 0,
      spo2: 0,
      pulse: 0,
      bloodSugar: 0,
      respiratoryRythm: 0,
    },
  ],
  currentStep: 1,
  isLoading: false,
  error: null,
  isRefetchRequired: false, // to refetch data after update
  isUpdating: false,
  isHisotyExists:false,
};

const ExaminationsSlice = createSlice({
  name: SLICE.EXAMINATIONS_LIST,
  initialState,
  reducers: {
    // update examinations list, update the object with the passed index if it exists, otherwise add it to the list
    updateExaminations: (
      state,
      action: PayloadAction<{ index: number; measure: string; data: number }>
    ) => {
      // find the object with the corresponding index
      const vitalSign = state.data.find(
        (object) => object.index === action.payload.index
      );

      if (vitalSign) {
        // Use bracket notation to access dynamic keys
        vitalSign[action.payload.measure] = action.payload.data;
      }

      // set is updating to false
      state.isUpdating = false;
    },

    // add examination to the list
    addExamination: (
      state,
      action: PayloadAction<{ data: vitalSignsType }>
    ) => {
      // update the object with the passed, and add new empty object to the list with index = last index + 1
      const index = state.data[state.data.length - 1].index + 1;
      state.data = [...state.data, { ...action.payload.data, index }];
      state.isUpdating = false;
    },

    // remove Examination with the passed it
    removeExamination: (state, action: PayloadAction<{ index: number }>) => {
      // remove the object with the same index from the examinations data list,
      // if the length of the list is 1, then set the list to the initial state
      if (state.data.length === 1) {
        state.data = initialState.data;
      } else {
        state.data = state.data.filter(
          (item) => item.index !== action.payload.index
        );
        state.isUpdating = false;
      }
    },

    // ExaminationCurrentStep set the currentStep value
    examinationCurrentStep: (
      state,
      action: PayloadAction<{ step: number }>
    ) => {
      state.currentStep = action.payload.step;
    },

    // isUpdating state
    isUpdating: (state, action: PayloadAction<boolean>) => {
      state.isUpdating = action.payload;
    },

    isHisotryExists: (state, action: PayloadAction<boolean>) => {
    
      state.isHisotyExists = action.payload;
    },

    resetExaminations: (state) => {
      state.data = initialState.data;
      state.currentStep = initialState.currentStep;
      state.isUpdating = initialState.isUpdating;
      state.isHisotyExists = initialState.isHisotyExists;
    },
    
  },
});

export const {
  updateExaminations,
  removeExamination,
  addExamination,
  examinationCurrentStep,
  isUpdating,
  isHisotryExists,
  resetExaminations,
} = ExaminationsSlice.actions;
export const ExaminationsReducer = ExaminationsSlice.reducer;

// export Selectors
export const SelectExaminations = (state: RootState) =>
  state.examinationsList.data;

export const SelectExaminationsWithoutId = (state: RootState) =>
  state.examinationsList.data.map((item) => {
    const { id, ...rest } = item;
    return rest;
  });
export const SelectExaminationsCurrentStep = (state: RootState) =>
  state.examinationsList.currentStep;
export const SelectExaminationsIsUpdating = (state: RootState) =>
  state.examinationsList.isUpdating;

export const SelectIsHistoryExists = (state: RootState) =>
  state.examinationsList.isHisotyExists;
