export default function handleCategorizeAndSetFiles(
  files: File[],
  deviceSelected,
  advancedMeasuresToolsIds,
  setValues,
  categoryName?: string
) {
  // Determine the category based on the provided inputs
  const category =
    categoryName || deviceSelected || advancedMeasuresToolsIds.ECHOGRAPHIE;

  // Use a function to update the state
  setValues((prev) => {
    const existingFiles = prev[category] || [];
    const existingFileNames = existingFiles.map((file) => file.name);

    // Filter out files that are already in the existingFiles array based on file name
    const newUniqueFiles = files.filter(
      (file) => !existingFileNames.includes(file.name)
    );

    // Create a new array by combining existing files in the category with new unique files
    const newFiles = [...existingFiles, ...newUniqueFiles];

    // Return the updated state with the new array of files for the specified category
    return { ...prev, [category]: newFiles };
  });
}
