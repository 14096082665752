import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { useDispatch } from "react-redux";
import { formReducer } from "./features/form";
import consultationsReducer from "./features/consultations/consultationSlice";
import connectionReducer from "./features/connection/connectionSlice";
import motifsReducer from "./features/motifs/motifsSlice";
import authReducer from "./features/auth/authSlice";
import currentStepReducer from "./features/currentStep/currentStepSlice";
import authApi from "./features/auth/authApi";
import consultationApi from "./features/consultations/consultationApi";
import motifsApi from "./features/motifs/motifsApi";
import chiefComplaintsReducer from "./features/chiefComplaints/chiefComplaintsSlice";
import chiefComplaintsApi from "./features/chiefComplaints/chiefComplaintsApi";
import otherFamiliauxReducer from "./features/otherFamiliaux/otherFamiliauxSlice";
import otherFamiliauxApi from "./features/otherFamiliaux/otherFamiliauxApi";
import selectedFamiliauxReducer from "./features/selectedFamiliaux/selectedFamiliauxSlice";
import selectedFamiliauxApi from "./features/selectedFamiliaux/selectedFamiliauxApi";
import allergyReducer from "./features/allergy/allergySlice";
import allergyApi from "./features/allergy/allergyApi";
import alcoholReducer from "./features/alcoholic/alcoholSlice";
import selectedPrescriptionReducer from "./features/selectedPrescription/selectedPrescriptionSlice";
import analysesRadioReducer from "./features/analysesRadio/analysesRadioSlice";
import bioAnalysesReducer from "./features/bioAnalyses/bioAnalysesSlice";
import medicationConsultsReducer from "./features/medicationConsults/medicationConsultsSlice";
import consultationDataReducer from "./features/consultationData/consultationDataSlice";
import consultationDataApi from "./features/consultationData/consultationDataApi";
import traitementApi from "./features/traitement/traitementApi";
import traitementReducer from "./features/traitement/traitementSlice";
import isVoirAntecedentShownReducer from "./features/isVoirAntecedentShown/isVoirAntecedentShownSlice";
import consultationsStatusReducer from "./features/consultationStatus/consultationStatusSlice";
import consultationCounterReducer from "./features/consultationCounter/consultationCounterSlice";
import consultationMetadataReducer from "./features/consultationMetadata/consultationMetadataSlice";
import selectedDoctorReducer from "./features/selectedDoctor/selectedDoctorSlice";
import appointmentApi from "./features/Appointment/appointmentApi";
import appointmentReducer from "./features/Appointment/appointmentSlice";
import selectedAppointmentReducer from "./features/selectedAppointment/selectedAppointmentSlice";
import calendarFilterReducer from "./features/calendarFilter/calendarFilterSlice";
import dialogReducer from "./features/dialog/dialogSlice";
import notificationsReducer from "./features/notifications/notificationsSlice";
import notifsApi from "./features/notifications/notificationsApi";
import patientReducer from "./features/patient/patientSlice";
import accessReducer from "./features/access/accessSlice";
import patientApi from "./features/patient/patientApi";
import persistedPrescriptionDataReducer from "./features/persistedPrescriptionData/persistedPrescriptionDataSlice";
import sidebarReducer from "./features/sidebar/SidebarSlice";
import tableReducer from "./features/historiqueTable/tableSlice";
import customTableReducer from "./features/table/customTableSlice";
import doctorReducer from "./features/doctor/doctorSlice";
import diagnosticAssocieSlice from "./features/diagnosticAssocie/diagnosticAssocieSlice";
import selectedAdherantReducer from "./features/patientRecord/selectedAdherantSlice";
import currentPatientReducer from "./features/patient/currentPatientSlice";
import mediotAgentsReducer from "./features/socket/mediotAgents";
import { baseParamsReducer } from "./features/Examen/baseParamsSlice";
import Reducer from "./features/Examen/advancedParamsSlice";
import mediaDevicesReducer from "./features/Examen/mediaDevicesSlice";
import defaultMainCameras from "./features/Examen/defaultMainCameras";
import medicalReducer from "./features/medical/medicalSlice";
import surgicalReducer from "./features/surgical/surgicalSlice";
import isTabacConsumingReducer from "./features/isTabacConsuming/isTabacConsumingSlice";
import hasOtherSubsReducer from "./features/hasOtherSubs/hasOtherSubsSlice";
import patientRecordTabReducer from "./features/patientRecord/patientRecordTabSlice";
import compteRenduReducer from "./features/compteRendu/compteRenduSlice";
import teleconsultationApi from "./features/teleconsultation/teleconsultationApi";
import advancedParamsApi from "./features/Examen/advancedParamsApi";
import systemIndicatorsReducer from "./features/indicators/systemIndicatorSlice";
import callActionsReducer from "./features/teleconsultation/CallActionsSlice";
import specialistConclusionReducer from "./features/specialistConclusion/specialistConclusionSlice";
import waitingListReducer from "./features/waitingList/waitingListSlice";
import notifiedConsultationReducer from "./features/call/notifiedConsultationSlice";
import CallInfosReducer from "./features/teleconsultation/CallInfosSlice";
import callReducer from "./features/call/callSlice";
import searcheableSelectReducer from "./features/searcheableSelect/searcheableSelectSlice";
import { basicDevicesMeasuresReducer } from "./features/Examen/vitalSigns/basicDevicesMeasuresSlice";
import apiResponseInterceptor from "./apiResponseInterceptor";
import waitingListApi from "./features/waitingList/waitingListApi";
import specialityApi from "./features/specialitys/specialityApi";
import callApi from "./features/call/callApi";
import doctorApi from "./features/doctor/doctorApi";
import staticDataApi from "./features/staticData/staticDataApi";
import mainCameraIdReducer from "./features/teleconsultation/mainCameraIdSlice";
import { api } from "./features/api/api";
import modalReducer from "./features/shared/modalSlice";
import alertReducer from "./features/shared/alertSlice";
import { availableSensors_ServicesReducer } from "./features/Examen/vitalSigns/availableSensors_ServicesSlice";
import { examinationDataReducer } from "./features/Examen/vitalSigns/ExaminationDataSlice";
import { ExaminationsReducer } from "./features/Examen/vitalSigns/ExaminationsSlice";
import { advancedMeasuresReducer } from "./features/Examen/advancedMeasuresSlice";
import { fileApi } from "./features/Examen/vitalSigns/fileApi";
import { mySkinAiApi } from "./features/Examen/mySkinAiApi";

const store: any = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,

    consultations: consultationsReducer,
    dialog: dialogReducer,
    searcheableSelect: searcheableSelectReducer,
    customTable: customTableReducer,
    patient: patientReducer,
    currentPatient: currentPatientReducer,

    access: accessReducer,
    advancedParamsData: Reducer,
    mediaDevices: mediaDevicesReducer,
    defaultMainCameras: defaultMainCameras,
    callActions: callActionsReducer,
    CallInfos: CallInfosReducer,
    connection: connectionReducer,
    consultationMetadata: consultationMetadataReducer, // Updated slice name
    // callDevicesEvents: callDevicesEventSlice, // devices in the call event handelling
    // callDevicesData: callDevicesDataReducer, // devices in the call data handelling
    form: formReducer,
    motifs: motifsReducer,
    notifiedConsultation: notifiedConsultationReducer,
    baseParamsData: baseParamsReducer,
    systemIndicators: systemIndicatorsReducer,
    patientRecordTab: patientRecordTabReducer,
    auth: authReducer,
    call: callReducer,
    currentStep: currentStepReducer,
    mediotAgents: mediotAgentsReducer,
    chiefComplaints: chiefComplaintsReducer,
    selectedDoctor: selectedDoctorReducer,
    otherFamiliaux: otherFamiliauxReducer,
    selectedFamiliaux: selectedFamiliauxReducer,
    allergy: allergyReducer,
    alcohol: alcoholReducer,
    selectedPrescription: selectedPrescriptionReducer,
    timer: consultationCounterReducer,
    analysesRadio: analysesRadioReducer,
    bioAnalyses: bioAnalysesReducer,
    medicationConsultation: medicationConsultsReducer,
    consultationData: consultationDataReducer,
    waitingList: waitingListReducer,
    traitement: traitementReducer,
    isVoirAntecedentShown: isVoirAntecedentShownReducer,
    consultationStatus: consultationsStatusReducer,
    appointment: appointmentReducer,
    selectedAppointment: selectedAppointmentReducer,
    calendarFilter: calendarFilterReducer,
    notifications: notificationsReducer,
    persistedPrescriptionData: persistedPrescriptionDataReducer,
    sidebar: sidebarReducer,
    table: tableReducer,
    selectedAdherant: selectedAdherantReducer,
    doctor: doctorReducer,
    diagnosticAssocie: diagnosticAssocieSlice,
    medical: medicalReducer,
    surgical: surgicalReducer,
    compteRendu: compteRenduReducer,
    isTabacConsuming: isTabacConsumingReducer,
    hasOtherSubs: hasOtherSubsReducer,
    specialistConclusion: specialistConclusionReducer,
    mainCameraId: mainCameraIdReducer,
    basicDevicesMeasures: basicDevicesMeasuresReducer,

    // components reducers
    modal: modalReducer,
    alert: alertReducer,
    availableSensors_Services: availableSensors_ServicesReducer,
    examinationData: examinationDataReducer,
    examinationsList: ExaminationsReducer,
    advancedMeasures: advancedMeasuresReducer,
    // my skin Ai
    mySkinAiApi: mySkinAiApi.reducer,

    [teleconsultationApi.reducerPath]: teleconsultationApi.reducer,
    [callApi.reducerPath]: callApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [chiefComplaintsApi.reducerPath]: chiefComplaintsApi.reducer,
    [allergyApi.reducerPath]: allergyApi.reducer,
    [appointmentApi.reducerPath]: appointmentApi.reducer,
    [notifsApi.reducerPath]: notifsApi.reducer,
    [patientApi.reducerPath]: patientApi.reducer,
    [specialityApi.reducerPath]: specialityApi.reducer,
    [doctorApi.reducerPath]: doctorApi.reducer,
    [staticDataApi.reducerPath]: staticDataApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      // .concat(apiResponseInterceptor)
      .concat(api.middleware)
      .concat(callApi.middleware)
      .concat(teleconsultationApi.middleware)
      .concat(authApi.middleware)
      .concat(chiefComplaintsApi.middleware)
      .concat(allergyApi.middleware)
      .concat(appointmentApi.middleware)
      .concat(notifsApi.middleware)
      .concat(patientApi.middleware)
      .concat(waitingListApi.middleware)
      .concat(specialityApi.middleware)
      .concat(doctorApi.middleware)
      .concat(staticDataApi.middleware)
      .concat(fileApi.middleware)
      .concat(mySkinAiApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;
