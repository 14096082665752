import { api } from "../../../features/api/api";


const attachmentApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAttachmentById: builder.query<any, any>({
      query: ({ id }) => ({
        url: `/attachement/${id}`,
        headers: {
          Accept: "application/pdf", // Set the Accept header to indicate the expected response type
          "Content-Type": "application/pdf", // Set the Accept header to indicate the expected response type
        },
        responseType: "blob", // Set responseType to 'blob' to handle binary data
        responseHandler: (response: Response) => response.blob(),
      }),
    }),
  }),
});
export const { useGetAttachmentByIdQuery, useLazyGetAttachmentByIdQuery } =
  attachmentApi;
