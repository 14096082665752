// dialogSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SLICE } from "../../enums";
import { IModalProps } from "@interfaces/baseParams/vitalSigns.interface";



interface DialogState extends IModalProps {
  isLoading?: boolean;
}

/**
 *
 */
const initialState: DialogState = {
  confirm: false,
  open: false,
  isLoading: false,
  message: "",
  size: "md",
  component: null,
  fetchHook: null,
  hookParams: null,
  title: "",
  cancelButton: null,
  confirmButton: null,
  isFormModal: false,
  type: "information",
};

const modalSlice = createSlice({
  name: SLICE.MODAL,
  initialState,
  reducers: {
    openDialog: (state, action: PayloadAction<DialogState>) => {
      state.open = true;
      Object.assign(state, action.payload);
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    closeDialog: (state) => {
      state.open = false;
      (state.confirm = false),
        (state.open = false),
        state.isLoading = false,
        (state.message = ""),
        (state.size = "md"),
        (state.component = null),
        (state.fetchHook = null),
        (state.hookParams = null),
        (state.title = ""),
        (state.cancelButton = null),
        (state.confirmButton = null),
        (state.isFormModal = false);
      state.type = "information";
    },
  },
});

export const { openDialog, closeDialog, setIsLoading } = modalSlice.actions;

export default modalSlice.reducer;
