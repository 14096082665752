// useSharedDynamicModal.ts
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { size as sizeEnum } from "@material-tailwind/react/types/components/avatar";
import { closeDialog, openDialog } from "../features/dialog/dialogSlice";
import { IModalProps } from "../interfaces/baseParams/vitalSigns.interface";

interface SharedDynamicModalOptions extends IModalProps {}

/**
 *
 * @returns
 */
const useSharedDynamicModal = () => {
  const dispatch = useDispatch();

  const {
    confirm,
    open,
    message,
    isFormModal,
    component,
    title,
    confirmButton,
    cancelButton,
    size,
    fetchHook,
    hookParams,
    type,
  } = useSelector((state: RootState) => state.modal);

  /**
   *
   * @param options
   */
  const openSharedDynamicModal = (options: SharedDynamicModalOptions) => {
    dispatch(openDialog(options));
  };

  /**
   *
   */
  const closeSharedDynamicModal = () => {
    dispatch(closeDialog());
  };

  return {
    open,
    confirm,
    confirmButton,
    cancelButton,
    size,
    component,
    message,
    title,
    isFormModal,
    fetchHook,
    hookParams,
    type,
    openSharedDynamicModal,
    closeSharedDynamicModal,
  };
};

export default useSharedDynamicModal;
