import { getExtension } from "./../utils";
import pdfIcon from "./../assets/pdffile.svg"
import dicomIcon from "./../assets/dicom.svg"
import dicomParser from "dicom-parser";
import { pdfjs, Document, Page } from "react-pdf";

import styled from "styled-components";
import { useCallback, useEffect, useState } from "react";
export default function FileThumbnail({ File }) {
  const [jpegUrl, setJpegUrl] = useState('');
  const type=getExtension(File["name"]).toLowerCase()

  const readAttribute = useCallback((dataSet, attr) => {
    const element = dataSet.elements[attr];
    if (element !== undefined) {
      if (element.length === 2) {
        return dataSet.uint16(attr);
      } else if (element.length === 4) {
        return dataSet.uint32(attr);
      }
    }
    return undefined;
  }, []);

  const processFile = useCallback((type) => {
    const reader = new FileReader();
    reader.onload = async (File) =>{
      if (reader.result && reader.result instanceof ArrayBuffer) {
        const arrayBuffer = reader.result;
        const byteArray = new Uint8Array(arrayBuffer);

        if(type=="dcm"){
          
          const dataSet = dicomParser.parseDicom(byteArray);
          const pixelDataElement = dataSet.elements.x7fe00010; // Pixel Data tag
          const pixelData = new Uint16Array(dataSet.byteArray.buffer, pixelDataElement.dataOffset, pixelDataElement.length / 2);
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
  
          const columns = readAttribute(dataSet, 'x00280011'); // Columns
          const rows = readAttribute(dataSet, 'x00280010'); // Rows
  
          if (rows === undefined || columns === undefined || !ctx) {
            console.error('DICOM properties missing or canvas context unavailable');
            return;
          }
  
          canvas.width = columns;
          canvas.height = rows;
  
          const imageData = ctx.createImageData(canvas.width, canvas.height);
          const numPixels = pixelData.length;
  
          let minVal = Infinity;
          let maxVal = -Infinity;
  
          for (let i = 0; i < numPixels; i++) {
            minVal = Math.min(minVal, pixelData[i]);
            maxVal = Math.max(maxVal, pixelData[i]);
          }
  
          for (let i = 0; i < numPixels; i++) {
            const normalizedPixel = ((pixelData[i] - minVal) / (maxVal - minVal)) * 255;
            imageData.data[4 * i + 0] = normalizedPixel; // R
            imageData.data[4 * i + 1] = normalizedPixel; // G
            imageData.data[4 * i + 2] = normalizedPixel; // B
            imageData.data[4 * i + 3] = 255; // Alpha
          }
  
          ctx.putImageData(imageData, 0, 0);
          setJpegUrl(canvas.toDataURL('image/jpeg'));
        }

        if(type=="pdf"){
          const loadingTask = pdfjs.getDocument(byteArray);

        const pdf = await loadingTask.promise;
        const page = await pdf.getPage(1); // Get the first page

        const scale = 0.5;
        const viewport = page.getViewport({ scale });
        const canvas = document.createElement('canvas');
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        const context = canvas.getContext('2d');

        if (context) {
          canvas.height = viewport.height;
          canvas.width = viewport.width;

  

        await page.render({
          canvasContext: context,
          viewport: viewport,
        }).promise;
        setJpegUrl(canvas.toDataURL());
     
    
        }
        
      }
      }}
    reader.readAsArrayBuffer(File);
  }, [File, readAttribute]);

  useEffect(() => {
    if (type.toLowerCase() === "dcm") {
      processFile('dcm');
    }
    if (type.toLowerCase() === "pdf") {
      processFile('pdf');
    }
  }, [type, processFile]);
  const StyledImage = styled.img`
    cursor: pointer;

  

    /*animation: screenshot 1s ease-in-out reverse;*/
    /* border-radius: 20%; */

    /* @keyframes screenshot {
      to {
        transform: translate(-40rem, -20rem) scale(2);
      }
    }*/
  `;

 


  return (
    <>
      <StyledImage
        alt="analys"
        className="h-full w-full object-cover object-center"
        src={(type!=="pdf" && type!=="dcm") ? URL.createObjectURL(File): (type==="pdf")?(jpegUrl!=='')?jpegUrl:pdfIcon:(jpegUrl!=='')?jpegUrl:dicomIcon}
      />
    </>
  );
}
