import { useCallback, useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import PdfViewer from "./PdfViewer";
import { getExtension } from "../utils";
import PhotoViewer from "./PhotoViewer";
import DicomViewer from "./DicomViewer";
import EditViewMode from "../utils/editViewMode/EditViewMode";
import DicomAnnotator from "./DicomAnnotator";
import useUndoableState from "./useUndoableState";
import dicomParser from "dicom-parser";
import DicomDatSetParser from "./DicomDatSetParser";
import type {
  Annotation,
  AnnotationMode,
} from "@lunit/insight-viewer/annotation";

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.js",
//   import.meta.url
// ).toString();

export default function FileViewer({
  selectedImage,
  ReF,
}: {
  selectedImage: File;

  ReF: any;
}) {
  const [isDrawing, setIsDrawing] = useState(false);
  const [dicomDataSet, setDicomDataSet] = useState(DicomDatSetParser(null));
  const [annotations, setAnnotation, undo, redo] = useUndoableState<
    Annotation[]
  >([]);

  useEffect(() => {
    if (getExtension(selectedImage["name"]).toLowerCase() === "dcm") {
      const arrayBuffer = selectedImage.arrayBuffer().then((val) => {
        // Here we have the file data as an ArrayBuffer.  dicomParser requires as input a
        // Uint8Array so we create that here
        const byteArray = new Uint8Array(val);

        const kb = byteArray.length / 1024;
        const mb = kb / 1024;
        const byteStr = mb > 1 ? mb.toFixed(3) + " MB" : kb.toFixed(0) + " KB";
        const dataSet = dicomParser.parseDicom(byteArray);

        console.log(' DICOM DATA 1')
        console.log(dataSet);

        setDicomDataSet(DicomDatSetParser(dataSet));
      });
    }
  }, [selectedImage]);

  //useState<Annotation[]>([]);

  return (
    <>
      {getExtension(selectedImage["name"]).toLowerCase() !== "pdf" &&
        getExtension(selectedImage["name"]).toLowerCase() !== "dcm" && (
          <PhotoViewer selectedImage={selectedImage} ReF={ReF} />
        )}

      {getExtension(selectedImage["name"]).toLowerCase() === "pdf" && (
        <>
          <PdfViewer File={URL.createObjectURL(selectedImage)} />
        </>
      )}

      {getExtension(selectedImage["name"]).toLowerCase() === "dcm" && (
        <>
          <div className="relative object-cover object-center w-full rounded-md">
            <div className="absolute top-[-12px] left-[12px] z-10">
              <EditViewMode setValue={setIsDrawing} />
            </div>

            {!isDrawing && (
              <DicomViewer
                File={URL.createObjectURL(selectedImage)}
                dicomDataSet={dicomDataSet}
              />
            )}
            {isDrawing && (
              <DicomAnnotator
                File={URL.createObjectURL(selectedImage)}
                annotations={annotations}
                setAnnotation={setAnnotation}
                undo={undo}
                redo={redo}
                dicomDataSet={dicomDataSet}
              />
            )}
          </div>
        </>
      )}
    </>
  );
}
