import { Spinner } from "@material-tailwind/react";

export default function Loader(){


    return <>
    <div className="p-5 h-full w-full flex items-center justify-center">
    <Spinner />
    </div>
    </>
}