import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface ITextEditorProps {
  onChange: (e: any) => void;
  className?: string;
  text?: string;
}

export function TextEditor({ text, className, onChange }: ITextEditorProps) {
  return (
    <ReactQuill
      className={className}
      theme="snow"
      value={text}
      onChange={onChange}
    />
  );
}
