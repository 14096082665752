import { useRef, useState } from "react";
import InsightViewer, { useImage, useInteraction } from "@lunit/insight-viewer";
import { useViewport } from "@lunit/insight-viewer/viewport";
import panBtn from "../assets/pan.svg";
import adjustBtn from "../assets/adjust.svg";
import resetBtn from "../assets/resetView.svg";
import zoomBtn from "../assets/zoom.svg";
//Cursor

import panCursor from "../assets/cursors/pan.svg";
import zoomCursor from "../assets/cursors/zoom.svg";
import adjustCursor from "../assets/cursors/adjust.svg";
import DicomDatSetParser from "./DicomDatSetParser";
import DicomDataPanel from "./DicomDataPanel";

type Controllers = {
  pan: () => void;
  reset: () => void;
  adjust: () => void;
  zoom: () => void;
};

export default function DicomViewer({
  File,
  dicomDataSet = DicomDatSetParser(null),
}) {
  const MOCK_IMAGE = "wadouri:" + File;

  const viewerRef = useRef<HTMLDivElement | null>(null);
  const { image } = useImage({ wadouri: MOCK_IMAGE });
  const { interaction, setInteraction } = useInteraction({
    mouseWheel: "scale", // Dicom Image scale is changed by mouse wheel events.
    primaryDrag: "pan", // The Dicom Image is moved by the left mouse drag.
  });
  const { viewport, setViewport, resetViewport } = useViewport({
    image,
    viewerRef,
  });

  const [control, setControl] = useState("pan");

  const getCursor = () => {
    let cursor = panCursor;
    switch (control) {
      case "pan":
        cursor = panCursor;
        break;
      case "adjust":
        cursor = adjustCursor;
        break;
      case "zoom":
        cursor = zoomCursor;
        break;

      default:
        cursor = panCursor;
        break;
    }

    return {
      cursor: `url('${cursor}') 8 8, auto`,
    };
  };

  const controllers: Controllers = {
    pan: () => {
      setInteraction((prev) => ({ ...prev, primaryDrag: "pan" }));
      setControl("pan");
    },
    reset: resetViewport, // Set to the initial viewport of the Dicom Image.
    adjust: () => {
      setInteraction((prev) => ({ ...prev, primaryDrag: "adjust" }));
      setControl("adjust");
    },
    zoom: () => {
      setInteraction((prev) => ({ ...prev, primaryDrag: "zoom" }));
      setControl("zoom");
    },
  };

  const viewerProps = {
    image,
    viewerRef,
    viewport,
    interaction,
    onViewportChange: setViewport,
  };

  return (
    <>
      <div className="flex flex-col absolute gap-2 top-4 right-4 z-10 items-end">
        <button onClick={controllers["pan"]}>
          <img src={panBtn} width={control == "pan" ? 32 : 24} />
        </button>
        <button onClick={controllers["zoom"]}>
          <img src={zoomBtn} width={control == "zoom" ? 32 : 24} />
        </button>
        <button onClick={controllers["adjust"]}>
          <img src={adjustBtn} width={control == "adjust" ? 32 : 24} />
        </button>

        <button onClick={controllers["reset"]}>
          <img src={resetBtn} width={24} />
        </button>
      </div>
      <div className="flex flex-col absolute gap-2 bottom-4 left-4 DicomData items-end">
        <DicomDataPanel
          patientInfo={dicomDataSet.patientInfo}
          study={dicomDataSet.study}
        />
      </div>
      <div className={`w-full h-full `} style={getCursor()}>
        <InsightViewer {...viewerProps}></InsightViewer>
      </div>
    </>
  );
}
