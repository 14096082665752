import { ToastOptions, ToastPosition } from "react-hot-toast";
import { toast } from "react-toastify";
import { useAgentSocket } from "../services/socketIOContext";

// const {socketCompagnion} = useAgentSocket();
// Fetch an array of devices of a certain type
export const getConnectedDevices = async (
  videoType: string,
  audioType: string,
  videoErrorMessage: string,
  audioErrorMessage: string
) => {
  try {
    const checkAudio = await navigator?.mediaDevices?.getUserMedia({ audio: true });
    const checkVideo = await navigator?.mediaDevices?.getUserMedia({ video: true });
  } catch (e) {
    toast.error(videoErrorMessage);
  }

 
  const devices = await navigator?.mediaDevices?.enumerateDevices();
  return devices?.filter(
    (device) => device.kind === videoType || device.kind === audioType
  );
};

export const getConnectedVideoDevices = async (videoType: string) => {
  try {
    const checkVideo = await navigator?.mediaDevices?.getUserMedia({ video: true });

  } catch (e) {}

  const devices = await navigator?.mediaDevices?.enumerateDevices();
  return devices?.filter((device) => device.kind === videoType);
};

//get value between parenthesis
// export const getBetweenParenthesis = (str: string) => {
//   const matches = str.match(/\(([^)]+)\)/);
//   return matches ? matches[1] : null;
// };

export const getBetweenParenthesis = (str: string) => {
  if (str.includes("Stethoscope")) {
    const matches = str.match(/\(([^()]+)\)/);
    if (matches && matches.length >= 2) {
      return matches[1]; // Return the first match between parentheses
    }
  } else {
    const matches = str.match(/\(([^()]+)\)$/); // Corrected regular expression
    if (matches) {
      return matches[1]; // Return the last match between parentheses
    }
  }
  return null; // Return null if no match is found
};

// export const toastConstraint: ToastOptions | any = {
//   position: toast.POSITION.BOTTOM_CENTER,
//   autoClose: 4000,
//   hideProgressBar: false,
//   closeOnClick: true,
//   pauseOnHover: true,
//   draggable: true,
//   progress: undefined,
//   theme: "light",
// };

export const getDevicesWithLabel = async (type: string, label: string) => {
  const devices = await navigator?.mediaDevices?.enumerateDevices();
  return devices?.filter(
    (device) => device.kind === type && device.label.includes(label)
  );
};

export const toastConstraint = (
  toastPossition: ToastPosition
): ToastOptions | any => {
  return {
    position: toastPossition,
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  };
};

export const getkeyName = (obj: any) => {
  return Object.keys(obj)[0];
};

export const stopShareScreen = (
  currentShareScreen: any,
  callback: (data: any) => void
) => {
  if (currentShareScreen) {
    const { videoTrack, client } = currentShareScreen;
    console.log("stopShareScreen", currentShareScreen);
    client.unpublish();
    videoTrack.stop();
    videoTrack.close();
    // audioTrack.stop();
    // audioTrack.close();
    client.remoteUsers.forEach((user: any) => {
      if (user.hasVideo) {
        //   removeVideoContainer(user.uid)
      }
      client.unsubscribe(user);
    });
    client.removeAllListeners();
    callback({ status: "success" });
  } else callback({ status: "error" });
};

export function blobToFile(blob: Blob, fileName: string) {
  const file = new File([blob], fileName, {
    type: "image/jpeg",
    lastModified: Date.now(),
  });
  return file;
}

// Utility function to convert base64 to Blob
export function base64ToBlob(base64: any, type = "image/png") {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return new Blob([bytes], { type });
}

export function IMC_Calculator(weight: number, heightInMeters: number) {
  return weight / (heightInMeters * heightInMeters);
}

  // Functions
  export const getPriseNum = (num:number | any) => {
    return num == 1 ? `${num}ère prise` : `${num}ème prise`;
  };