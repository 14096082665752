import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AgoraNurseCall } from "./AgoraNurseCall";
import { AgoraDoctorCall } from "./AgoraDocCall";
import CallActions from "./CallActions";
import VideoPlayer from "./VideoPlayer";
import { AgoraDevice } from "./AgoraDevice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import WebSocketService from "../../services/Notification/socketService";
import { useAvailableCameras } from "../../hooks/useAvailableCameras";
import APP_CONSTANTS, { TABLE_HEAD, videoCallDevices } from "../../constants";
import { io } from "socket.io-client";
import {
  // useGetTeleconsultationQuery,
  useLazyGetTeleconsultationQuery,
} from "../../features/teleconsultation/teleconsultationApi";
import { CircularProgressbar } from "react-circular-progressbar";
import { AgoraScreenShare } from "./AgoraScreenShare";
import { ExeName } from "../../enums/vitalSigns";
import Drawer from "../Drawer";
import { Transition } from "@headlessui/react";
import { updateAgentStatus } from "../../features/indicators/systemIndicatorSlice";
import { AgentStatus } from "../../enums/agent";
import {
  selectShowOthers,
  setMute,
} from "../../features/teleconsultation/CallActionsSlice";
import CallTimer from "./callTimer";
import { stopShareScreen } from "../../helper/mediaDeviceHelper";
import {
  GlobalStyle,
  Wrapper,
  CallDivParent,
  CallDiv,
  MyCameraWrapper,
  DrawerContainer,
  BottonsWrrapper,
  CallTools,
} from "../../StyledComponents";
import { Spinner } from "@material-tailwind/react";
import { role, userStatus } from "../../enums/app";
import { useGetCallQuery } from "../../features/call/callApi";
import userInCall from "../../assets/userInCall.svg";

import voiceIcon from "../../assets/voiceIcon.svg";

const Call = () => {
  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { availableVideoDevices } = useAvailableCameras();
  const [getTeleconsultation] = useLazyGetTeleconsultationQuery({});

  // selectors
  const storedUser = useSelector((state: RootState) => state.auth.user);
  const [isSpecialist, setIsSpecialist] = useState<boolean>(
    storedUser?.roles[0].name === role.SPECIALIST
  );

  // roomId, the unique identifier of the call
  const { id: roomId } = useParams();

  // Queries
  const { data, isLoading, error } = useGetCallQuery(roomId);

  // states
  const [interlocutor, setInterlocutor] = useState<{
    fullname: string;
    speciality?: string;
  }>();
  const [isShareScreen, setIsShareScreen] = useState(false);
  // const [ischat, setIschat] = useState(false);
  const [streamDeviceId, setStreamDeviceId] = useState<string | null>(null);
  const [checkedDevice, setCheckedDevice] = useState(-1);
  const [clicked, setClicked] = useState(false);
  const location = useLocation();
  const [users, setUsers] = useState<any>([]);
  const [toolName, setToolName] = useState<string>("");
  const [availableSensors, setAvailableSensors] = useState<any>([]);
  const [showDevice, setShowDevice] = useState(false);
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [currentDeviceStream, setCurrentDeviceStream] = useState<any>(null);

  const [currentShareScreen, setCurrentShareScreen] = useState<any>(null);
  const [isAllowedAccessCall, setIsAllowedAccessCall] =
    useState<boolean>(false);

  // the array containning the user ids of the particiants
  const [paticipantsUserIds, setParticipantsUserIds] = useState<Array<number>>(
    []
  );

  const [isVideoStream, setIsVideoStream] = useState(false);
  const [callToken, setCallToken] = useState<string | null>(null);
  const [mediotAgent, setMediotAgent] = useState<any>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const hours = String(Math.floor(currentTime / 3600)).padStart(2, "0");
  const minutes = String(Math.floor((currentTime % 3600) / 60)).padStart(
    2,
    "0"
  );
  const seconds = String(currentTime % 60).padStart(2, "0");
  const timeString = `${hours}:${minutes}:${seconds}`;

  // watchers

  // check if user can join the call
  useEffect(() => {
    if (!isLoading) {
      if (isSpecialist) {
        setInterlocutor({ fullname: data.station.center.name });
      } else {
        setInterlocutor({
          fullname: `${data.user.firstName} ${data.user.lastName}`,
          speciality: data?.user?.doctor?.speciality?.name,
        });
      }

      if (error && error.status == 403) setIsAllowedAccessCall(false);
      else setIsAllowedAccessCall(true);

      /* store current call */
      localStorage.setItem(
        `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}currentCall`,
        data.id
      );
    } else {
      setIsAllowedAccessCall(true);
      setParticipantsUserIds([]);
    }
  }, [isLoading]);

  /**
   * on unmount component, update
   */
  useEffect(() => {
    return () => {
      WebSocketService.updateDoctorStatus(
        userStatus.ONLINE,
        storedUser?.userId
      );
    };
  }, []);

  // getting the call token
  useEffect(() => {
    /** get token from service */
    if (roomId) {
      WebSocketService.updateDoctorStatus(
        userStatus.IN_CALL,
        storedUser?.userId
      );

      WebSocketService.joinRoom(roomId, storedUser?.userId!, (data) => {
        getTeleconsultation({
          param1: roomId,
          param2: "0",
        })
          .unwrap()
          .then((res) => {
            console.log("getTeleconsultation", res);

            setCallToken(res.token);
          })
          .catch((err) => {
            console.log("getTeleconsultation", err);
            // setCallToken(err.data);
          });
      });
    } else {
      navigate("/notAllowed");
    }
    if (!isSpecialist) connectMediot();
    // Function to update the time every second
    const updateCurrentTime = () => {
      // console.log("updating timer 2")

      setCurrentTime((prevTime) => prevTime + 1);
    };

    // Start the timer immediately
    const timer = setInterval(updateCurrentTime, 1000);

    // Clear the interval on component unmount

    return () => {
      if (!isSpecialist) mediotAgent?.close();
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (
      (!clicked && users.length > 2) ||
      (!clicked && currentDeviceStream?.audioTrack)
    ) {
      stopStream(currentDeviceStream, (data: any) => {
        if (data.status === "success") setShowDevice(false);
      });
    }

    if (isShareScreen) {
      console.log("[shareScreen] stop share screen");
      //  else if (!deviceIsClicked) {
      //   WebSocketService.exitApp();
      stopShareScreen(currentShareScreen, (data: any) => {
        if (data.status === "success") {
          console.log("[STOP SHARE] share screen success");
        } else {
          console.error(
            "[STOP SHARE] share screen error, stream >",
            currentShareScreen
          );
          //TODO: dispatch message for share screen
        }
        setIsShareScreen(false);
      });
      return;
    }
    // if (deviceRef.current?.srcObject && !deviceIsClicked) {
    //   console.log("remove stream");
    //   stopVideoTrack();
    //   setStreamDeviceId(null);
    //   setMultimediaStream(null);
    // }
    // }

    switch (checkedDevice) {
      case 0:
        // if (deviceIsClicked) {
        //   console.log("ecg_report_cmd", agentSocket);
        //   agentSocket?.emit("ecg_report_cmd");
        //   agentSocket?.on("ecg_report", (data: any) => {
        //     const { link, pdfBase64, absolute } = JSON.parse(data);
        //     console.log(" res ecg_report_cmd", pdfBase64);

        //     //TODO::send data to backend as a file
        //     handleDecodeAndCheck(pdfBase64, link, absolute);
        //     // navigat(link);
        //   });
        // }
        break;

      case 1:
        if (availableVideoDevices.dermatoscope && clicked) {
          // console.log(availableVideoDevices.dermatoscope, deviceIsClicked);
          setShowDevice(true);
          setIsVideoStream(true);
          setStreamDeviceId(availableVideoDevices.dermatoscope);
        }

        break;
      case 2:
        if (availableVideoDevices.otoScope && clicked) {
          setShowDevice(true);
          setIsVideoStream(true);
          setStreamDeviceId(availableVideoDevices.otoScope);
        }
        break;
      case 5:
        if (callToken && clicked) {
          // if (availableServices.includes(ExeName.ECHO) && deviceIsisClicked) {
          //   WebSocketService.showScreen(ExeName.ECHO);
          //   setDeviceIsisClicked(false);
          setIsShareScreen(true);
        }
        break;
      case 3:
        if (availableVideoDevices.iriscope && clicked) {
          setShowDevice(true);
          setStreamDeviceId(availableVideoDevices.iriscope);
          setIsVideoStream(true);
        }
        break;
      case 4:
        if (availableVideoDevices.cameraMobile && clicked) {
          setShowDevice(true);
          setStreamDeviceId(availableVideoDevices.cameraMobile);
          setIsVideoStream(true);
        }
        break;

      case 6:
        //TODO: doing so for base params
        // setInterval(() => {
        //   WebSocketService.sendBaseParamsCallData(
        //     JSON.stringify({ roomId:roomId, message: "hello" })
        //   );
        // }, 1000);
        break;

      case 10:
        if (availableVideoDevices.Stetoscope && clicked) {
          setShowDevice(true);
          setStreamDeviceId(availableVideoDevices.Stetoscope);
          setIsVideoStream(false);
        }
        break;

      default:
        break;
    }

    // if (!deviceIsClicked && toolsIndex === 5) {
    //   toast.success("ECG est bien arrêté");
    //   agentSocket?.off("ecg_report_cmd");
    //   // exitApp();
    // }
  }, [clicked, checkedDevice, availableVideoDevices]);

  useEffect(() => {
    WebSocketService.RecvBaseParamsCallData((data: any) => {
      console.log("RecvBaseParamsCallData", data);
    });
  }, []);

  /** mediot Agent connection*/
  const connectMediot = () => {
    console.log("connection to agent...");
    if (!mediotAgent) {
      const agent = io(`${process.env.REACT_APP_MEDIOT_AGENT_URL}`, {
        reconnection: true,
        reconnectionDelay: 5000,
        reconnectionAttempts: 10,
      });

      setMediotAgent(agent);

      agent.on("connect_error", (reason: any) => {
        dispatch(updateAgentStatus(AgentStatus.Error));
        console.warn("[connection d'agent]", reason);
        // toast.error(
        //   "Veuillez essayer d'exécuter l'Agent sur votre VisioStation."
        // );
      });

      agent.on("connect", () => {
        //TODO: set the availability of the sensors based on the data received
        agent.on("available_sensors", (data: any) => {
          const values = JSON.parse(data);

          // get the name of the only true values
          const sensors = Object.keys(values).filter((key) => values[key]);
          console.log("sensor", sensors);
          setAvailableSensors(sensors);
        });
      });
    }
  };

  useEffect(() => {
    console.log("currentUser", currentDeviceStream);
  }, [currentDeviceStream]);

  /**
   * this function is to check weither the user can access the call or no based on his id
   */
  // const checkCallAccess = () => {
  //   const userId =
  // }

  // handlers
  const handelDeviceClick = (deviceIndex: number) => {
    setCheckedDevice(deviceIndex);
    setClicked(!clicked);
  };

  const stopStream = (currentStream: any, callback: (data: any) => void) => {
    try {
      console.log("stopStream **");
      if (currentStream.videoTrack) {
        currentStream.videoTrack.stop();
        currentStream.videoTrack.close();
        currentStream.client.unpublish();
      }
      if (currentStream.audioTrack) {
        currentStream.audioTrack.stop();
        currentStream.audioTrack.close();
        dispatch(setMute());
        setTimeout(() => {
          dispatch(setMute());
        }, 1000);
      }
      currentStream.client.remoteUsers.forEach((user: any) => {
        if (user.hasVideo) {
          //   removeVideoContainer(user.uid)
        }
        currentStream.client.unsubscribe(user);
      });
      currentStream.client.removeAllListeners();
      console.log(
        "client",
        currentStream.client,
        "currentStream",
        currentStream
      );
      callback({ status: "success" });
    } catch (error) {
      console.log("stop stram error", error);
      callback({ status: "error" });
    }
  };

  const isDeviceExists = (device: any): boolean => {
    const isEchographie = device.name === ExeName.ECHO;
    const isStyto = device.name === "styto";
    return (
      (availableSensors && availableSensors.includes(device.name)) || // basic devices
      (availableVideoDevices && availableVideoDevices[device.name]) ||
      isEchographie ||
      isStyto
    );
  };

  if (isAllowedAccessCall)
    return (
      <>
        <GlobalStyle>
          <div className="relative w-full ">
            <div className="flex">
              <Wrapper>
                <CallDivParent>
                  {users.length <= 1 ? (
                    <div className="flex flex-col gap-3 text-white items-center">
                      <img src={userInCall} />
                      <p className="text-2xl font-semibold">
                        {interlocutor?.fullname}
                      </p>
                      {!isSpecialist && (
                        <p className="text-sm font-thin">
                          {interlocutor?.speciality}
                        </p>
                      )}{" "}
                    </div>
                  ) : (
                    users.map((user: any, index: any) => {
                      // return index !== 0 && user.uid !== deviceUid ? (
                      return (
                        user.uid !== currentUser && (
                          <CallDiv>
                            {user?.kind ? (
                              <div className="pt-3 pb-0 text-white font-bold">
                                {/* {user.uid} */}
                                {user.kind}
                              </div>
                            ) : null}
                            <VideoPlayer
                              key={user.uid}
                              user={user}
                              size={"w-[100%] h-[100%]"}
                            />
                          </CallDiv>
                        )
                      );
                    })
                  )}
                  {/* <CallDivModal>
              <BaseParamsPanel isOpen={isModalOpen}>
                <ProgressCircle progress={progress}>
                  {progress === 100 && <div>Result</div>}
                </ProgressCircle>
              </BaseParamsPanel>
            </CallDivModal> */}
                  {/* <BaseParamsPanel /> */}
                  <CallTimer />
                  <MyCameraWrapper
                    className={!isSpecialist ? "bottom-[10rem]" : "bottom-2"}
                  >
                    {isSpecialist ? (
                      <AgoraDoctorCall
                        token={callToken}
                        channel={roomId}
                        users={users}
                        setUsers={setUsers}
                        setCurrentUser={setCurrentUser}
                        currentUser={currentUser}
                      />
                    ) : !callToken ? (
                      <Spinner />
                    ) : null}

                    {/* {!isSpecialist && Object.keys(availableVideoDevices).length > 0 && ( */}
                    {!isSpecialist &&
                    callToken &&
                    availableVideoDevices.mainCamera ? (
                      <AgoraNurseCall
                        mainCamera={availableVideoDevices.mainCamera}
                        token={callToken}
                        channel={roomId}
                        users={users}
                        setUsers={setUsers}
                        currentUser={currentUser}
                        setCurrentUser={setCurrentUser}
                      />
                    ) : !isSpecialist ? (
                      <>
                        <img src={voiceIcon} className="self-end mr-2" />
                        <img src={userInCall} className="w-20 h-20" />
                        <p className="self-start m-2"></p>
                      </>
                    ) : //  <Spinner />
                    null}
                  </MyCameraWrapper>

                  {showDevice && (
                    <>
                      <AgoraDevice
                        channel={roomId}
                        token={callToken}
                        deviceId={streamDeviceId}
                        deviceUid
                        setCurrentDeviceStream={setCurrentDeviceStream}
                        users={users}
                        setUsers={setUsers}
                        isVideoStream={isVideoStream}
                      />
                    </>
                  )}

                  {isShareScreen && (
                    <>
                      <AgoraScreenShare
                        token={callToken}
                        channel={roomId}
                        deviceId={streamDeviceId}
                        isShareScreen={isShareScreen}
                        deviceUid
                        setCurrentShareScreen={setCurrentShareScreen}
                        users={users}
                        setUsers={setUsers}
                      />
                    </>
                  )}
                </CallDivParent>
              </Wrapper>
              <DrawerContainer>
                <Transition
                  className={"mb-4 w-full"}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  show={drawerOpen}
                >
                  <Drawer setDrawerOpen={setDrawerOpen} open={drawerOpen} />
                </Transition>
              </DrawerContainer>
            </div>
            <BottonsWrrapper isSpecialist={isSpecialist}>
              {!isSpecialist && (
                <CallTools>
                  {videoCallDevices.map((tool, index) => {
                    return (
                      <>
                        <img
                          src={tool.icon}
                          onClick={() => {
                            handelDeviceClick(index);
                            setToolName(tool.name);
                          }}
                          className={`baseParamsIcon ${
                            isDeviceExists(tool)
                              ? ""
                              : "filter grayscale hover:pointer-events-none"
                          }
                  ${
                    clicked &&
                    checkedDevice === index &&
                    "scale-150 brightness-150 animate-pulse"
                  }
                  `}
                          alt=""
                          style={{ cursor: "pointer", minWidth: "30px" }}
                        />
                      </>
                    );
                  }, [])}
                </CallTools>
              )}
              {data && data?.consultationId && (
                <CallActions
                  callInformations={data}
                  consultationId={data?.consultationId}
                  roomId={roomId!}
                  isShareScreen={isShareScreen}
                  setIsShareScreen={setIsShareScreen}
                  isSpecialist={isSpecialist}
                  drawerOpen={drawerOpen}
                  setDrawerOpen={setDrawerOpen}
                />
              )}
            </BottonsWrrapper>
          </div>
        </GlobalStyle>{" "}
      </>
    );
  else if (!isLoading && !isAllowedAccessCall)
    return <>vous n'etes pas autorisé a rejoindre l'appel</>;
  else if (isLoading) return <>Loading call data...</>;
};

export default Call;
