import React, { useEffect } from "react";

interface IHtmlReaderProps {
  htmlContent: string;
}
export const HtmlReader: React.FC<IHtmlReaderProps> = ({
  htmlContent,
}: IHtmlReaderProps) => {
  // Function to sanitize HTML content
  const sanitizeHTML = (html: string) => {

    return { __html: html };
  };

  console.log("htmlContent",htmlContent)
 
  return (
    <div
      dangerouslySetInnerHTML={sanitizeHTML(
       (htmlContent && htmlContent.replace(/(<([^>]+)>)/gi, "").trim() !== "" ) 
          ? htmlContent
          : '<span class="font-semibold text-base my-3">Aucune donnée disponible</span>'
      )}
      className="text-md bg-[#f2f2f275] text-left p-2 w-full rounded max-h-[42vh] overflow-y-auto"
    />
  );
};

export default HtmlReader;
