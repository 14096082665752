import { ErrorComponent } from "./ErrorComponent";
import DataNotFoundIcon from '../assets/customNoData.svg'
interface INoRecordFoundProps {
  message?: string;
  title?: string;
  type?: "simpleText" | "custom";
  icon?: any;
}


/**
 * 
 * @param param0 
 * @returns 
 */
export const NoRecordFound = ({
  message,
  type = "simpleText", 
  title = "Pas de données",
  icon,
}: INoRecordFoundProps) => {
  if (type === "simpleText")
    return (
      <div className="flex justify-center items-center my-3">
        <span className="font-semibold text-xs my-3">
          {message ? message : "Aucune donnée trouvée"}
        </span>
      </div>
    );
  else
    return (
      <>
      <ErrorComponent 
      icon={icon ? icon : DataNotFoundIcon}
      title={title}
      message={message ? message : "Aucune donnée trouvée"}/>
        {/* <div className="flex flex-col justify-center items-center">
          <img src={PerdConnexionIcon} alt="icon" />
          <span className="text-[#1AA5C0]"> {message ? message : "Aucune donnée trouvée"}</span>
        </div> */}
      </>
    );
};
