import React, { useRef, useState } from "react";
import { IoChevronBackOutline } from "react-icons/io5";
import FileViewer from "./../../FileViewer/FileViewer";
import HtmlReader from "../../../src/utils/HtmlReader";
// import { attachementType } from "../../features/Examen/advancedMeasuresSlice";
import { IAdvancedAttachement } from "@interfaces/attachement/attachement.interface";
import { advancedMeasuresToolsIds } from "@enums/advancedParams";

interface IgalleryImages{
  selectedPicture: IAdvancedAttachement;
  setDetailsPic: any;
  detailsPic: boolean;
}


export default function GallerySaving({
  selectedPicture,
  setDetailsPic,
  detailsPic,
}: IgalleryImages) {

  // useStates
  const [inputValue, setInputValue] = useState<string | null>(null);
  const [descriptionTab, setDescriptionTab] = useState<string>("description");
  
  const imgRef = useRef();
  
 
  return (
    <div className="w-full">
      {" "}
      <div className="flex flex-col w-full">
        <div className="w-full flex flex-col items-center justify-center">
          <button
            className="self-start bg-gray-200 rounded-md py-1 px-2"
            onClick={() => setDetailsPic(false)}
          >
            <IoChevronBackOutline />
          </button>
          {/* <ImImage size={300} color="gray" />    <img
            alt="analys"
            className=" w-full object-cover object-center m-[0.5rem]"
            src={URL.createObjectURL(selectedPicture.image)}
          />*/}
          <div className="w-full object-cover object-center m-[0.5rem] relative">
            {" "}
            <FileViewer selectedImage={selectedPicture.file} ReF={imgRef} />
          </div>
        </div>


        <div className="flex justify-between bg-[#E9F8F9] rounded-md align-middle items-center p-2 w-full px-4">
          <p
            className={`text-[#1AA5C0] text-lg border-2 border-b-[#1AA5C0] cursor-pointer px-8 `}
            onClick={() => setDescriptionTab("description")}
          >
            Description
          </p>
          {/* {selectedPicture.type === advancedMeasuresToolsIds.DERMATOSCOPE && ( */}
          {/* {selectedPicture.aiResults && (
            <p
              className={`${descriptionTab === "assistant" ? "text-[#1AA5C0] text-sm border-2 border-b-[#1AA5C0] cursor-pointer px-8" : "text-[#1AA5C0] text-sm cursor-pointer"} `}
              onClick={() =>
                selectedPicture.aiResults
                  ? setDescriptionTab("assistant")
                  : () => {}
              }
            >
              Assistant AI
            </p>
          )} */}
        </div>

        <div className="grid mt-2">
          {descriptionTab === "description" ? (
            <>
              <HtmlReader htmlContent={selectedPicture.comment} />
            </>
          ) :
          <></>
          //  descriptionTab === "assistant" ? (
          //   <div className="flex-1 m-1 gap-2">
          //     <div className="flex justify-between items-center">
          //       <p className="text-[#50799E] text-md font-semibold">
          //         Résultats d’analyse IA
          //       </p>
          //     </div>
          //     <div>
          //       <ul>
          //         {selectedPicture.aiResults &&
          //           selectedPicture.aiResults.map(
          //             (item: any, index: number) => (
          //               <li
          //                 key={index}
          //                 className="text-[#858585] text-sm p-1 font-light"
          //               >
          //                 <div className="flex justify-between items-center">
          //                   <p className="text-[#1AA5C0] w-full border-2 shadow-md font-medium border-[#698DA9] py-1 pl-2 rounded-lg">
          //                     {item[0]}
          //                   </p>{" "}
          //                   <p className="bg-[#698DA9] py-1 px-2 text-white rounded-lg m-2">
          //                     {item[1]}
          //                   </p>
          //                 </div>
          //               </li>
          //             )
          //           )}
          //       </ul>
          //     </div>
          //   </div>
          // ) : (
          //   ""
          // )
          }
        </div>
      </div>
    </div>
  );
}
