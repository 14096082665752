import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  DialogHeader,
  Button,
  Spinner,
  Dialog,
  DialogFooter,
} from "@material-tailwind/react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { VscClose } from "react-icons/vsc";
import { advancedMeasuresToolsIds } from "./../../enums/advancedParams";
import {
  addAdvancedMeasureData,
  selectAdvancedMeasures,
} from "./../../features/Examen/advancedMeasuresSlice";
import { useGetAiResultsMutation } from "./../../features/Examen/mySkinAiApi";
import SpeechToTextEditor from "../../utils/SpeachToText";
import { RootState } from "./../../store";
import { AdvancedParamsTools } from "./../../constants";
import FileViewer from "./../../FileViewer/FileViewer";
import postAttachment from "./../../features/Examen/advancedParamsApi";

interface IDescriptionModal {
  open: boolean;
  setOpen: any;
  deviceSelected: advancedMeasuresToolsIds | null;
  selectedImage: File;
  capturedImages: File[];
  setCapturedImages: any;
  selectedImageIndex: number;
  isConsultation: boolean;
  setFiles: any;
}

export default function AiDescriptionModal(props: IDescriptionModal) {
  // const
  // Selectors
  const GalleryImages = useSelector(selectAdvancedMeasures);
  const user = useSelector((state: RootState) => state.auth.user);

  // consts
  const {
    open,
    setOpen,
    deviceSelected,
    selectedImage,
    capturedImages,
    setCapturedImages,
    selectedImageIndex,
    isConsultation,
    setFiles,
  } = props;


  // useStates
  const [showImage, setshowImage] = useState(false);
  const [aiResults, setAiResults] = useState<[] | null>(null);
  const [inputValue, setInputValue] = useState<string | null>(null);

  // useRef
  const imgRef = useRef<HTMLImageElement | null>(null);

  // useParams
  const { consultationId } = useParams();

  // Dispatch
  const dispatch = useDispatch();

  // Selectors
  const accessToken = useSelector((state: RootState) => state.auth.accessToken);

  // Redux Mutations
  const [getAiResults, { isLoading: isAiResultLoading, isError }] =
    useGetAiResultsMutation();

  // Handleers
  const handleShowImage = () => setshowImage(!showImage);

  const handleInputChange = (value: any) => setInputValue(value);

  const handleCancelModal = () => {
    setInputValue(null);
    setOpen(false);
    setAiResults(null);
  };

  const handelSaveImage = () => {
    console.log("airesult", aiResults);
    console.log("user", user);
    const data = new FormData();
    data.append("file", selectedImage);
    data.append("name", deviceSelected!);
    data.append("type", deviceSelected!);
    data.append("userId", user.id? user.id :user.userId!); // Convert userId to string
    data.append(
      "specialityId",
      user.specialityId
        ? user.specialityId.toString()
        : user.doctor.speciality.id.toString()
    );
    data.append("isScreenShot", "false");
    data.append("consultationId", Number(consultationId!).toString());
    // aiResults
    //   ? data.append(
    //       "aiResult",
    //       aiResults.map((result: any) => result[0]).join(",") ?? ""
    //     )
    //   : null;
    data.append(
      "comment",
      `avis medécin generalist:${inputValue}.\n \r ${
        aiResults && aiResults.length !== 0
          ? "MySkinAI results:" +
            aiResults.map((result: any) => result[0]).join(",")
          : "."
      }.`
    );

    console.log("FormData contents:", data.getAll("aiResult")); // Log FormData contents
    postAttachment(accessToken, data)
      .then((res) => {
        // handling the status of the resquest if trues set state
        toast.success("L'image a été enregistrée avec succès");
        // setShouldFetch(true)
        // setDetailsPic(!detailsPic);
        // setIsSaving(false);
      })
      .catch((err) => {
        console.log("postAttachement", err);
        toast.error("Merci de reessayer plus tard");
        // setIsSaving(false);
      })
      .finally(() => {
        // get attachements of this patient
      });
  };

  const handleSendData = () => {
    handelSaveImage();
    handelDeleteImage(selectedImageIndex);
    handleCancelModal();
  };

  const handelDeleteImage = (index: number) => {
    setCapturedImages((prevState) => {
      // Create a copy of the current state
      const updatedImages = { ...prevState };

      // Check if the deviceSelected! exists and index is valid
      if (
        updatedImages[deviceSelected!] &&
        index >= 0 &&
        index < updatedImages[deviceSelected!].length
      ) {
        // Create a copy of the images array for the deviceSelected!
        const updatedCategoryImages = [...updatedImages[deviceSelected!]];

        // Remove the image at the specified index
        updatedCategoryImages.splice(index, 1);

        // Update the state with the modified deviceSelected! images
        updatedImages[deviceSelected!] = updatedCategoryImages;
      }

      return updatedImages;
    });

    // clear the image from the serFiles
    if (
      deviceSelected === advancedMeasuresToolsIds.ECHOGRAPHIE ||
      deviceSelected === advancedMeasuresToolsIds.ECG
    ) {
      setFiles((prev: any) => {
        const updatedFiles = { ...prev };
        const updatedCategoryFiles = [...updatedFiles[deviceSelected!]];
        updatedCategoryFiles.splice(index, 1);
        updatedFiles[deviceSelected!] = updatedCategoryFiles;
        return updatedFiles;
      });
    }

    // toast notification
    // toast.success("Image transfere vers la Gallerie avec succès", {
    //   toastId: "imagesDelete",
    // });
  };

  const handleGetAiResults = async (image: File) => {
    const form = new FormData();
    form.append("file", image);
    const { data: responseResults, error }: any = await getAiResults(form);

    if (error) {
      toast.error("Erreur lors de l'envoi de l'image", {
        toastId: "MyskinToast",
      });
      console.log("Erreur lors de l'envoi", error);
    }

    if (responseResults?.probs) {
      const myskinAiData = responseResults?.probs.slice(0, 3);
      console.log("show ai", myskinAiData);
      setAiResults(myskinAiData);
    }
  };

  useEffect(() => {
    console.log("setCapturedImages", capturedImages);
  }, [capturedImages]);

  const getDeviceIcon = () => {
    const advancedTools = AdvancedParamsTools.filter(
      (item) => item.ToolsIds === deviceSelected
    );

    return advancedTools[0]?.icon;
  };

  return (
    <>
      <Dialog
        open={open}
        handler={handleShowImage}
        size={`${isConsultation ? "xl" : "lg"}`}
        className="flex flex-col justify-between h-[90%] w-[90%] flex-nowrap items-start"
        placeholder={undefined}
      >
        <DialogHeader
          placeholder={undefined}
          className="flex gap-[12px] text-[#1AA5C0] text-md"
        >
          <img src={getDeviceIcon()} width={32} />
          {isConsultation ? "Enregistrement" : "Affichage"} et analyse des
          imageries
        </DialogHeader>
        <div className="w-full flex items-start justify-between  overflow-y-auto h-full">
          <div
            className={`${
              !isConsultation &&
              deviceSelected !== advancedMeasuresToolsIds.DERMATOSCOPE
                ? "w-full"
                : "w-[50%]"
            } p-2 h-full relative`}
          >
            {selectedImage && (
              <>
                <FileViewer selectedImage={selectedImage} ReF={imgRef} />
              </>
            )}
          </div>
          {isConsultation ||
          deviceSelected === advancedMeasuresToolsIds.DERMATOSCOPE ? (
            <div
              className={`w-[50%] flex flex-col justify-start p-2 overflow-y-auto h-full`}
            >
              {selectedImage && (
                <>
                  {deviceSelected === advancedMeasuresToolsIds.DERMATOSCOPE &&
                  !aiResults ? (
                    <div className="items-center gap-3 px-1 ">
                      <Button
                        variant="outlined"
                        className="flex w-full justify-center text-lg bg-primary text-white border-0 bg-[#F8FEFF] rounded mainBoxShadow focus:border-none"
                        onClick={() => {
                          handleGetAiResults(selectedImage);
                        }}
                        placeholder={undefined}
                      >
                        {!isAiResultLoading || isError ? (
                          <span className="flex gap-[12px] text-[#1AA5C0] items-center">
                            <svg
                              width="35"
                              height="34"
                              viewBox="0 0 35 34"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M34.0848 17.0607C34.0848 26.2908 26.5613 33.8144 17.3312 33.8143C8.07868 33.8141 0.578125 26.3134 0.578125 17.0609V17.0586C0.578322 16.5179 1.02087 16.0754 1.56158 16.0754H3.87347C4.4122 16.0754 4.84884 16.5123 4.84864 17.051L4.84858 17.0609C4.84858 23.955 10.4373 29.5437 17.3313 29.5438C24.2458 29.5439 29.8144 23.9753 29.8143 17.0608C29.8142 10.1668 24.2255 4.57807 17.3314 4.57807H17.3215C16.7842 4.57853 16.346 4.14945 16.346 3.61223V1.29107C16.346 0.750365 16.7884 0.307814 17.3291 0.307617H17.3314C26.584 0.307617 34.0847 7.80824 34.0848 17.0607ZM17.3314 8.84852L17.2942 8.84859C16.7685 8.85096 16.346 9.28326 16.346 9.80892V12.1595C16.346 12.6902 16.7764 13.1223 17.3071 13.119C17.382 13.1186 17.4575 13.1202 17.5333 13.124C19.5464 13.225 21.1762 14.8618 21.2691 16.8753C21.3733 19.1331 19.5667 21.0029 17.3314 21.0029H17.3213C16.7851 21.0015 16.346 21.4265 16.346 21.9627V24.313C16.346 24.8386 16.7685 25.2709 17.2942 25.2733L17.3314 25.2733C21.8671 25.2733 25.5439 21.5965 25.5439 17.0609C25.5439 12.5253 21.8671 8.84852 17.3314 8.84852Z"
                                fill="url(#paint0_linear_3762_29481)"
                              />
                              <path
                                d="M29.8104 16.878C29.9082 23.6806 24.4917 29.3461 17.6911 29.5387C14.1027 29.6404 10.8438 28.2266 8.50626 25.8908C8.11988 25.5047 8.11666 24.8795 8.50291 24.4932L10.1365 22.8597C10.5199 22.4762 11.1382 22.4845 11.5218 22.868C13.0266 24.3729 15.1128 25.2965 17.4147 25.2729C21.8491 25.2277 25.4759 21.6175 25.5404 17.1833C25.6073 12.5917 21.9052 8.84858 17.3289 8.84858H17.3189C16.782 8.84924 16.3434 8.42186 16.3434 7.88503V5.5422C16.3434 5.01365 16.769 4.5795 17.2975 4.57819L17.3289 4.57812C24.1619 4.57812 29.7126 10.0683 29.8104 16.878Z"
                                fill="url(#paint1_linear_3762_29481)"
                              />
                              <path
                                d="M4.66717 4.57812L1.70703 9.70524L4.66717 14.8323H7.6273L8.30324 10.1337L7.6273 4.57812H4.66717Z"
                                fill="#AAE8F3"
                              />
                              <path
                                d="M13.5453 9.70524L10.5851 4.57812H7.625V14.8323H10.5851L13.5453 9.70524Z"
                                fill="#54BBCD"
                              />
                              <path
                                d="M5.41797 9.875H6.64614V11.1032H5.41797V9.875Z"
                                fill="#54BBCD"
                              />
                              <path
                                d="M4.85156 7.09277H6.07973V8.32094H4.85156V7.09277Z"
                                fill="#54BBCD"
                              />
                              <path
                                d="M7.625 11.3896H8.85317V12.6178H7.625V11.3896Z"
                                fill="#1AA5C0"
                              />
                              <defs>
                                <linearGradient
                                  id="paint0_linear_3762_29481"
                                  x1="17.3314"
                                  y1="33.8143"
                                  x2="17.3314"
                                  y2="0.307617"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stop-color="#AAE8F3" />
                                  <stop offset="0.503" stop-color="#EAF6FF" />
                                  <stop offset="1" stop-color="#EAF6FF" />
                                </linearGradient>
                                <linearGradient
                                  id="paint1_linear_3762_29481"
                                  x1="15.1277"
                                  y1="29.5439"
                                  x2="15.1277"
                                  y2="0.176273"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stop-color="#6CDAD5" />
                                  <stop offset="1" stop-color="#2699DA" />
                                </linearGradient>
                              </defs>
                            </svg>
                            MYSKIN AI
                          </span>
                        ) : (
                          <Spinner />
                        )}
                      </Button>
                      {/* {infoMessage} */}
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )}
              <div className="items-center gap-3 ">
                {aiResults &&
                  deviceSelected === advancedMeasuresToolsIds.DERMATOSCOPE && (
                    <>
                      <h6 className="flex items-center justify-between px-2 text-[#50799E]">
                        Résultats de l'analyse IA:{" "}
                        <span
                          className="rounded p-2 flex items-center mainBoxShadow bg-white cursor-pointer"
                          onClick={() => {
                            setAiResults(null);
                          }}
                        >
                          <VscClose color="#FF4B55" size={20} />
                        </span>
                      </h6>
                      <ul>
                        {aiResults &&
                          aiResults.map((item: any, index: number) => (
                            <li
                              key={index}
                              className="text-[#858585] text-sm p-1 font-light"
                            >
                              <div className="flex justify-between items-center">
                                <p className="text-[#1AA5C0] w-full border-2 shadow-md font-medium border-[#698DA9] py-1 pl-2 rounded-lg">
                                  {item[0].toString().toUpperCase()}
                                </p>{" "}
                                <p className="bg-[#698DA9] py-1 px-2 text-white rounded-lg m-2">
                                  {item[1]}
                                </p>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </>
                  )}

                <div className="items-center gap-3">
                  {/* avis Specialist */}
                  {isConsultation ? (
                    <SpeechToTextEditor
                      callBack={handleInputChange}
                      data={inputValue ?? ""}
                      message={"Merci de renseigner un commentaire"}
                      isDebouncedText={false}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <DialogFooter
          id="AiDescriptorModalFooter"
          placeholder={""}
          className="w-full p-2 modalBoxShadow rounded"
        >
          <div className="flex justify-between align-bottom my-2 w-full">
            <Button
              type="button"
              className={` ${
                aiResults ||
                (inputValue &&
                  inputValue!.replace(/(<([^>]+)>)/gi, "").trim() !== "")
                  ? "bg-[#FB3E54] text-white font-semibold"
                  : "bg-[#858585] text-white"
              } w-52 px-5 py-2 rounded font-bold`}
              onClick={() => handleCancelModal()}
              placeholder={undefined}
            >
              {aiResults ||
              (inputValue &&
                inputValue!.replace(/(<([^>]+)>)/gi, "").trim() !== "")
                ? "Ignorer"
                : "Annuler"}
            </Button>
            {isConsultation ? (
              <Button
                type="submit"
                className="bg-[#1AA5C0] text-white w-52 px-5 py-2 rounded font-bold"
                onClick={() =>
                  aiResults ||
                  (inputValue &&
                    inputValue!.replace(/(<([^>]+)>)/gi, "").trim() !== "")
                    ? handleSendData()
                    : toast.error(`Aucune interprétation n'a été donnée !`, {
                        toastId: "inputToast",
                      })
                }
                placeholder={undefined}
              >
                {/* {!isLoading ? "Sauvgarder" : <Spinner />} */}
                {"Confirmer"}
              </Button>
            ) : (
              ""
            )}
          </div>
        </DialogFooter>
      </Dialog>
      {/* </React.Fragment> */}
    </>
  );
}

const StyledImage = styled.img`
  cursor: pointer;

  animation: screenshot 1s ease-in-out reverse;
  /* border-radius: 20%; */

  @keyframes screenshot {
    to {
      transform: translate(-40rem, -20rem) scale(2);
    }
  }
`;
