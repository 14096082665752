/* DEFINING POSSIBLE VALUES FOR LOADING STATE */
export enum LOADING_STATE {
    IDLE = "idle",
    PENDING = "pending",
    SUCCEEDED = "succeeded",
    FAILED = "failed",
  }
  
  /* DEFINING STORE SLICES */
  export enum SLICE {
    APP = "app",
    LANGUAGE = "languageSlice",
  
  
    /* basic slices */
    CONNECTION = "connection", // this slice is to detect if the internet connection is up or down
    SOCKET = "socket",
    FORM = "formSlice", // this is form slice
    USERS = "usersSlice",
    AUTH = "authSlice",
    STATIC_DATA = "staticSlice",
    APPOINTMENT = "appointmentSlice",
    APPOINTMENT_MODAL = "appointmentModalSlice",
    APPOINTMENT_SIDE_BAR = "appointmentSideBarSlice",
    FILES = "filesSlice",
  
    /* components slices */
    TABLE = "tableSlice",
    MODAL = "modalSlice",
    STEPPER = "stepper",
    ALERT = 'alert',
    SHARED_SEARCHABLE_INPUT="sharedSearchableInput",
  
  
    /*  business slices */
    DASHBOARD = "dashboardSlice",
    NOTIFICATION = "notification",
    CALL = "call",
    MEDICAL_DEVICES = "medical_devices",
    PATIENT = "patient",
    CONSULTATION = "consultation",
    CENTER = "center",
    SCREENING = "screening",
    CARE_PROCEDURE = "careProcedure",
    BASIC_DEVICES_MEASURES = "basicDevicesMeasures",
  
    /*  Vital Signs slices */
    SENSORS_SERVICES = "SensorServicesAvailablabilities",
    BASEPARAMS_DATA = "baseParamsData",
    EXAMINATIONS_LIST = "examinationsList",
  
    /** Advanced Measurements */
    MEDICAL_IMAGES = "medicalImages",
  
    /* chat slice */
    CHAT = "chat"
  }
  