import { useState, useEffect } from "react";
import InsightViewer, { useImage } from "@lunit/insight-viewer";
import { AnnotationOverlay } from "@lunit/insight-viewer/annotation";
import polygonBtn from "../assets/polygon.svg";
import areaBtn from "../assets/area.svg";
import rullerBtn from "../assets/ruler.svg";
import arrowBtn from "../assets/linearrow.svg";
import lineBtn from "../assets/line.svg";
import markerBtn from "../assets/markerpoint.svg";
import undoBtn from "../assets/undo.svg";
import redoBtn from "../assets/redo.svg";
import removeBtn from "../assets/erase.svg";
//CURSORS
import areaCursor from "../assets/cursors/area.svg";
import lineArrowCursor from "../assets/cursors/linearrow.svg";
import defaultCursor from "../assets/cursors/default.svg";
import lineCursor from "../assets/cursors/line.svg";
import polygonCursor from "../assets/cursors/polygon.svg";
import rulerCursor from "../assets/cursors/ruler.svg";


import type { 
  Annotation,
  ClickAction,
  AnnotationMode,
} from "@lunit/insight-viewer/annotation";

export default function DicomAnnotator({
  File,
  annotations,

  setAnnotation = (val) => {},
  undo = () => {},
  redo = () => {},
  dicomDataSet={}
}) {
  const MOCK_IMAGE = "wadouri:" + File;
  const [annotationMode, setAnnotationMode] =
    useState<AnnotationMode>("polygon");
  const [clickAction, setClickAction] = useState<ClickAction>("select");
  // const [annotations, setAnnotation] = useState<Annotation[]>([]);

  const { image } = useImage({ wadouri: MOCK_IMAGE });

  const getCursor = () => {
    let cursor = polygonCursor;
    switch (annotationMode.toString()) {
      case "polygon":
        cursor = defaultCursor;
        break;
      case "line":
        cursor = lineCursor;
        break;
      case "arrowLine":
        cursor = lineArrowCursor;
        break;
      case "ruler":
        cursor = rulerCursor;
        break;
      case "area":
        cursor = areaCursor;
        break;

      case "text":
        cursor = defaultCursor;
        break;
      default:
        cursor = defaultCursor;
        break;
    }

    return {
      cursor: `url('${cursor}') 8 8, auto`,
    };
  };

  useEffect(() => {
    if (annotationMode == "text") {
      setClickAction("remove");
    } else {
      setClickAction("select");
    }
  }, [annotationMode]);

  return (
    <>
      <div className="flex flex-col absolute gap-2 top-4 right-4 z-10 items-end">
        <button onClick={() => setAnnotationMode("polygon")}>
          <img src={polygonBtn} width={annotationMode == "polygon" ? 32 : 24} />
        </button>
        <button onClick={() => setAnnotationMode("line")}>
          <img src={lineBtn} width={annotationMode == "line" ? 32 : 24} />
        </button>

        <button onClick={() => setAnnotationMode("arrowLine")}>
          <img src={arrowBtn} width={annotationMode == "arrowLine" ? 32 : 24} />
        </button>
        <button onClick={() => setAnnotationMode("ruler")}>
          <img src={rullerBtn} width={annotationMode == "ruler" ? 32 : 24} />
        </button>
        <button
          onClick={() => {
            setAnnotationMode("point");
          }}
        >
          <img src={markerBtn} width={annotationMode == "point" ? 32 : 24} />
        </button>

        <button onClick={() => setAnnotationMode("area")}>
          <img src={areaBtn} width={annotationMode == "area" ? 32 : 24} />
        </button>

        <button onClick={() => setAnnotationMode("text")}>
          <img src={removeBtn} width={annotationMode == "text" ? 32 : 24} />
        </button>

        <button onClick={undo} className="mt-4">
          <img src={undoBtn} width={24} />
        </button>

        <button onClick={redo}>
          <img src={redoBtn} width={24} />
        </button>
      </div>

      <InsightViewer image={image}>
        <AnnotationOverlay
          isDrawing
          mode={annotationMode}
          annotations={annotations}
          clickAction={clickAction}
          showOutline={false}
          hoveredAnnotation={annotationMode == "text" ? annotations : null}
          onChange={(annotations) => setAnnotation(annotations)}
          className={
            annotationMode == "text" ? "cursor-no-drop	" : "cursor-cell"
          }
          style={getCursor()}
        />
      </InsightViewer>
    </>
  );
}
