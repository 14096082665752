import { toast } from "react-toastify";
import { DeviceVideo } from "./DeviceVideo";
import { FileDropZone } from "./FileDropZone";
import ImagesCaptured from "./ImagesCaptured";
import React, { useCallback, useEffect, useState } from "react";
import CaptureIcon from "../../assets/CaptureIcon.svg";
import PerdConnexionIcon from "../../assets/perdConnexionIcon.svg";
import GalleryMedicaleImages from "./GalleryMedicaleImages";
import devicesInfo from "./../../helper/devicesInfos.json";
import handleCategorizeAndSetFiles from "./handleCategorizeAndSetFiles";
import {
  advancedMeasuresToolsIds,
  advancedMeasuresToolsNames,
} from "../../enums/advancedParams";
import { useCreateMediaStream } from "./../../hooks/useCreateMediaStream";
import WebSocketService from "./../../services/socketService";
import { NoRecordFound } from "./../../utils/NoRecordFound";
import { VideoTypes } from "../types";
import { AdvancedParamsTools } from "../../constants";
import {
  base64ToBlob,
  blobToFile,
  getBetweenParenthesis,
  getConnectedDevices,
  getkeyName,
} from "./../../helper/mediaDeviceHelper";
import { useAvailableCameras } from "../../hooks/useAvailableCameras";

/* TODO: Add interfaces to separated file and share it with FileDrop zone */
interface ExtendedFile extends File {
  preview: string;
}
interface FileCategories {
  [key: string]: ExtendedFile[];
}

interface IAdvancedParams {
  scrollRef?: any;
  isConsultation: boolean;
}

const AdvancedDevices = (props: IAdvancedParams) => {
  const { isConsultation, scrollRef } = props;
  // constants
  const imagerieDevices = [
    "dermatoscope",
    "iriscope",
    "otoscope",
    "cameramobile",
    "caméra mobile",
    "camera mobile",
  ];

  // Socket connection
  WebSocketService.connect();

  // useRef
  const deviceRef = React.useRef<HTMLVideoElement | any>(null);
  useEffect(() => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  // useStates
  const [openRight, setOpenRight] = useState(false);
  const [startRecording, setStartRecording] = useState(false);
  const [Actionitem, setActionItem] = useState<{
    ToolsId: advancedMeasuresToolsIds;
    name: advancedMeasuresToolsNames;
    icon: string;
  }>({
    ToolsId: advancedMeasuresToolsIds.NULL,
    name: advancedMeasuresToolsNames.NULL,
    icon: "",
  });
  const [streamDeviceId, setStreamDeviceId] = useState<string | null>(null);
  const [files, setFiles] = useState<{ [category: string]: File[] }>({});
  const [deviceSelected, setDeviceSelected] =
    useState<advancedMeasuresToolsIds | null>(null);
  const [uncategorizedDevices, setUncategorizedDevices] = useState<any>([]);
  const [canDetect, setCanDetect] = useState(false);

  const initialState: FileCategories = Object.values(
    advancedMeasuresToolsIds
  ).reduce((acc, value) => {
    acc[value] = [];
    return acc;
  }, {} as FileCategories);

  //hooks
  const { availableVideoDevices } = useAvailableCameras();

  const { MultimediaStream, setMultimediaStream } = useCreateMediaStream(
    streamDeviceId,
    deviceRef
  );

  //functions
  const categorizeAndSetFiles = (propFiles: File[], categoryName?: string) => {
    handleCategorizeAndSetFiles(
      propFiles,
      deviceSelected,
      advancedMeasuresToolsIds,
      setFiles,
      categoryName
    );
  };

  // Handlers
  const openDrawerRight = () => setOpenRight(true);

  const handleDeviceClicked = async (item: {
    ToolsId: advancedMeasuresToolsIds;
    icon: string;
  }) => {
    setCanDetect(false);

    // click switching
    if (!deviceSelected) setDeviceSelected(item.ToolsId);
    if (deviceSelected && deviceSelected != item.ToolsId) {
     await handleRemoveStream();
      setDeviceSelected(item.ToolsId);
    }
    if (item.ToolsId == deviceSelected) {
     await handleRemoveStream();
      setDeviceSelected(null);
    }
    //
  };

  const handleRemoveStream =async () => {
    console.log("remove stream");
    stopVideoTrack();
    setStreamDeviceId(null);
    setMultimediaStream(null);
  };

  const stopVideoTrack = () => {
    MultimediaStream?.getTracks().forEach((track: any) => {
      track.stop();
    });
    if (deviceRef.current?.srcObject) deviceRef.current.srcObject = null;
  };

  const handleAction = (item) => {
    setActionItem(item);
  };

  const commands = AdvancedParamsTools.map((item, index) => ({
    command: `activer ${item.ToolsId.toLowerCase()}`, // Command to activate the tool
    action: () => handleAction(item), // Action to perform
  }));


  // useEffects

  // handling devices switching logic
  useEffect(() => {
    console.log('availableVideoDevices',availableVideoDevices);
    
    switch (deviceSelected) {
      case advancedMeasuresToolsIds.ECG:
        // console.log("ecg_report_cmd", agentSocket);
        // agentSocket?.emit("ecg_report_cmd");
        // agentSocket?.on("ecg_report", (data: any) => {
        //   const { link, pdfBase64, absolute } = JSON.parse(data);
        //   console.log(" res ecg_report_cmd", pdfBase64);

        //   //send data to backend as a file
        //   // handleDecodeAndCheck(pdfBase64, link, absolute);
        //   // navigat(link);
        // });
        break;

      case advancedMeasuresToolsIds.DERMATOSCOPE:
        if (availableVideoDevices.dermatoscope) {
          // console.log(availableVideoDevices.dermatoscop);
          console.log("deviceSelected", deviceSelected);
          setStreamDeviceId(availableVideoDevices.dermatoscope);
        }
        break;
      case advancedMeasuresToolsIds.OTOSCOPE:
        if (availableVideoDevices.otoScope) {
          setStreamDeviceId(availableVideoDevices.otoScope);
        }
        break;
      case advancedMeasuresToolsIds.IRISCOPE:
        if (availableVideoDevices.iriscope) {
          setStreamDeviceId(availableVideoDevices.iriscope);
        }
        break;
      case advancedMeasuresToolsIds.MOBILE_CAMERA:
        if (availableVideoDevices.cameraMobile) {
          setStreamDeviceId(availableVideoDevices.cameraMobile);
        }
        break;
      case advancedMeasuresToolsIds.ECHOGRAPHIE:
        // if (WebSocketService) {
        WebSocketService.Emit("usg_cmd", () => {});
        WebSocketService.On("usg", (data) => {
          // Parse the JSON string to get the image data
          const { image } = JSON.parse(
            // data.replace("}", '"}').replace(":", ':"')
            data
          );

          // Convert the base64-encoded image to a file once
          const fileFromImage = blobToFile(
            base64ToBlob(image),
            deviceSelected + Date.now() + ".png"
          );

          // Create a new file object with a preview URL
          const newFile = Object.assign(fileFromImage, {
            preview: URL.createObjectURL(fileFromImage),
          });

          /*setFiles((prevFiles): any => {
            // Add the new file to the existing files
            return [...prevFiles, newFile];
          });*/

          categorizeAndSetFiles(
            [newFile],
            advancedMeasuresToolsIds.ECHOGRAPHIE
          );
        });
        // }

        break;
    }

    if (deviceRef.current?.srcObject && !deviceSelected) {
      handleRemoveStream();
    }
  }, [deviceSelected,availableVideoDevices]);

  // handling voice command
  useEffect( () => {
    console.log(Actionitem.ToolsId);
    console.log(availableVideoDevices);
    console.log("action exécutée");
    if (Actionitem.ToolsId !== advancedMeasuresToolsIds.NULL) {
      if (
        availableVideoDevices[Actionitem.ToolsId] !== null ||
        Actionitem.ToolsId == advancedMeasuresToolsIds.ECHOGRAPHIE ||
        Actionitem.ToolsId == advancedMeasuresToolsIds.ECG
      ) {
         handleDeviceClicked(Actionitem);
        speak(
          `l'onglet ${Actionitem.ToolsId} a été activé ${
            imagerieDevices.includes(Actionitem.ToolsId.toLowerCase())
              ? ". Pour démarrer et arrêter la capture des images, veuillez simplement lever votre main"
              : ""
          }`
        );
        setCanDetect(
          imagerieDevices.includes(Actionitem.ToolsId.toLowerCase())
        );
      } else {
        setCanDetect(false);
        speak("L'onglet demandé est inactif");
      }
    }
  }, [Actionitem]);

  const speak = (str) => {
    const synth = window.speechSynthesis;

    if (synth.speaking) {
      console.error("speechSynthesis.speaking");
      return;
    }

    if (str !== "") {
      const utterThis = new SpeechSynthesisUtterance(str);

      utterThis.onend = function (event) {
        console.log("SpeechSynthesisUtterance.onend");
      };

      utterThis.onerror = function (event) {
        console.error("SpeechSynthesisUtterance.onerror");
      };

      const voices = synth.getVoices().sort(function (a, b) {
        const aname = a.name.toUpperCase();
        const bname = b.name.toUpperCase();

        if (aname < bname) {
          return -1;
        } else if (aname == bname) {
          return 0;
        } else {
          return +1;
        }
      });

      utterThis.pitch = 1;
      utterThis.rate = 1;
      synth.speak(utterThis);
    }
  };

  useEffect(() => {
    // handeling remove the video stram when the stream is ended in any case (ex:when the device is remved or throw an error)
    if (MultimediaStream) {
      // detecting the stream ended 
      console.log("Track - ", MultimediaStream.getTracks()[0]);
      console.log("Ready State - ", MultimediaStream.getTracks()[0].readyState);
      if(MultimediaStream.getTracks()[0].readyState)
      {
        handleRemoveStream();
        setDeviceSelected(null);
      }
    }
  }, [availableVideoDevices]);


  // login handler
  const isDeviceExists = useCallback((deviceItem:any) :boolean=> {
  // const isDeviceExists = (item: any): boolean => {

    const isEchographie = deviceItem.ToolsId === advancedMeasuresToolsIds.ECHOGRAPHIE;
    const isECG = deviceItem.ToolsId === advancedMeasuresToolsIds.ECG;

    return (
      availableVideoDevices[deviceItem.ToolsId] ||
      isEchographie ||
      isECG ||
      (!props.isConsultation && isEchographie)
    );
  }, [availableVideoDevices]);


  return (
    <>
      <div className="w-[100%] h-[65vh] p-[1.2rem] content-center">
        {isConsultation && (
          <div className="flex gap-3 justify-end items-center -mb-5">
            {/* Gallery button to show images saved on the gallery */}
            <button
              onClick={openDrawerRight}
              className="relative bottom-16 font-semibold bg-white rounded-full p-6 text-[#50799E] flex items-center gap-3 text-sm py-2 shadow-lg"
            >
              <img src={CaptureIcon} alt="CaptureIcon" className="h-6 w-6" />
              Galerie
            </button>
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-1 sm:grid-cols-1 lg:grid-cols-5">
          {/* side bare device buttons */}
          <div className="grid sm:col-span-5 md:col-span-1 gap-5">
            {AdvancedParamsTools.map((item, index) => (
              <button
                key={index}
                disabled={isDeviceExists(item) ? false : true}
                className={`flex items-center ${
                  deviceSelected === item.ToolsId
                    ? "border-b-4 border-[#1AA5C0] "
                    : " border bg-[#1AA5C0]"
                } bg-[#ffffff] gap-3 rounded-lg p-3 w-full ${
                  isDeviceExists(item) ? "border-[#09b7da87] focus:border-[#1AA5C0] " : "opacity-50 cursor-not-allowed"
                }`}
                onClick={async () => {
                  if (isDeviceExists(item)) {
                    await handleDeviceClicked(item);
                  }
                }}
              >
                <img src={item.icon} alt="" className="w-10 h-10" />
                <p className="text-[#335D84] font-semibold">{item.name}</p>
              </button>
            ))}
          </div>

          <div className="col-span-4 grid grid-cols-3  justify-center items-center mx-3 gap-2">
            <div className="col-span-2 grid justify-items-center border border-[#09b7da87] bg-white rounded-md h-full w-full 1-1">
              {deviceSelected ? (
                deviceSelected === advancedMeasuresToolsIds.ECHOGRAPHIE ||
                deviceSelected === advancedMeasuresToolsIds.ECG ? (
                  <FileDropZone
                    files={files}
                    setFiles={setFiles}
                    deviceSelected={deviceSelected}
                  />
                ) : (
                  <DeviceVideo ref={deviceRef} />
                )
              ) : (
                <NoRecordFound type="custom" icon={PerdConnexionIcon} />
              )}
            </div>
            <div className="flex flex-col border border-[#09b7da87] rounded-md p-1 bg-white h-full w-full">
              {/* <div className="grid grid-cols-2 gap-2 p-3 h-96 w-full overflow-y-auto"> */}
              <ImagesCaptured
                deviceSelected={deviceSelected}
                deviceRef={deviceRef}
                echographieFiles={files}
                setFiles={setFiles}
                startRecording={startRecording}
                isConsultation={isConsultation}
              />
              {/* </div> */}
            </div>
          </div>
        </div>

        {/* sidebar */}
        {isConsultation && (
          <GalleryMedicaleImages
            openRight={openRight}
            setOpenRight={setOpenRight}
            // open={open}
            // setOpen={setOpen}
          />
        )}
        {/*end sidebar */}
      </div>

      {/* <VoiceCommandListener
        commands={commands}
        startRecordingCallBack={(val) => setStartRecording(val)}
        canDetect={canDetect}
      /> */}
    </>
  );
};

export default AdvancedDevices;
