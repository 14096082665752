import { VscClose } from "react-icons/vsc";
import { MdOutlineCameraAlt } from "react-icons/md";
import AiDescriptionModal from "./AiDescriptionModal";
import { Card, Spinner } from "@material-tailwind/react";
import React, { useState, useEffect, useRef } from "react";
import { NoRecordFound } from "./../../utils/NoRecordFound";  
import NoImageFound from "./../../assets/noImageFound.svg";
import handleCategorizeAndSetFiles from "./handleCategorizeAndSetFiles";
import { advancedMeasuresToolsIds } from "./../../enums/advancedParams";
import FileThumbnail from "./../../FileViewer/FileThumbnail";
import { getExtension } from "./../../utils";

interface IimageCaptured 
  {
    echographieFiles: { [category: string]: File[] };
    setFiles:any;
    deviceSelected: advancedMeasuresToolsIds | null;
    deviceRef: React.RefObject<HTMLVideoElement>;
    startRecording?:boolean;
    isConsultation: boolean;
  }


const ImagesCaptured = ({
  echographieFiles,
  setFiles,
  deviceRef,
  deviceSelected,
  startRecording=true,
  isConsultation,}: IimageCaptured) => {


  // States
  const [open, setOpen] = useState(false);
  const [startCapturing, setStartCapturing] = useState(true);
  const [capturedImages, setCapturedImages] = useState<{ [category: string]: File[]; }>({}); // the captured images
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(-1); // selected Image to Gallery
  const [captureInterval, setCaptureInterval] = useState<NodeJS.Timer | null>(null);

  // useRef
  const containerRef = useRef<any>(null);

  // Handlers
  const categorizeAndSetFiles=(files: File[], categoryName?: string)=>{
    handleCategorizeAndSetFiles(files, deviceSelected, advancedMeasuresToolsIds, setCapturedImages, categoryName );
  } 


  //Effect from gesture detector
  useEffect(()=>{
      setStartCapturing(!startCapturing);
      handStartCapture()
  },[startRecording])

  // handling the capture of medical imaging devices
  const handStartCapture = async () => {
    if (!startCapturing) {
      if (
        capturedImages[deviceSelected!] &&
        capturedImages[deviceSelected!].length > 0
      ) {
       /*  if (
          confirm(
            "current Images will be deleted,if you're sure press enter to continue"
          )
        ) { */
          if (deviceSelected !== null) clearFilesInCategory(deviceSelected!);
       // }
      }

      setStartCapturing(!startCapturing);
      const intervalId = setInterval(() => {
        if (deviceRef.current) {
          const stream = deviceRef.current.srcObject as MediaStream;
          const track = stream?.getVideoTracks()[0];
          const imageCapture = new ImageCapture(track);
          imageCapture
            .takePhoto()
            .then((blob) => {
              const file = new File(
                [blob],
                // `${checkedDevice ? checkedDevice : "screenShot"}-${Date.now()}.png`,
                `${deviceSelected ?? "screenShot"}-${Date.now()}.jpeg`,
                {
                  type: "image/jpeg",
                }
              );
              categorizeAndSetFiles([file], deviceSelected!);
            })
            .catch((error) => {
              console.log("error image capture", error);
            });
        }
      }, 1000);
      setCaptureInterval(intervalId);
    } else {
      if (captureInterval) {
        setStartCapturing(!startCapturing);
        //clear interval
        clearInterval(captureInterval);
        setCaptureInterval(null);
      }
    }
  };


  //useEffect
  useEffect(() => {
    // Scroll to the end when new images are added
    if (containerRef.current && capturedImages) {
      containerRef.current.scrollLeft = containerRef.current.scrollWidth + 10;
    }
  }, [capturedImages]);

  useEffect(() => {
    Object.entries(echographieFiles).forEach(([category, files]) => {
      clearFilesInCategory(category)
      categorizeAndSetFiles(files, category);
    });
  }, [echographieFiles]);

  function clearFilesInCategory(categoryName: string) {
    setCapturedImages((prev) => {
      // If the category doesn't exist, no action is needed
      if (!prev[categoryName]) {
        return prev;
      }

      // Return the updated state with the specified category cleared
      return { ...prev, [categoryName]: [] };
    });
  }

  const handleOpen = async (index: number, image: any) => {
    if (!open) {
      setSelectedImageIndex(index);
      setOpen(true);
    }
  };


  return (
    <>
      {/* header */}
      {deviceSelected !== advancedMeasuresToolsIds.ECHOGRAPHIE &&
        deviceSelected !== advancedMeasuresToolsIds.ECG &&
        deviceSelected !== null && (
          <div className="flex flex-col  gap-2 pt-1 px-2">
            <button
              onClick={handStartCapture}
              className={`flex gap-4 items-center justify-center w-full ${startCapturing ? "border-[#FF4B55] animate-pulse justify-center bg-[#FF4B55] text-white" : " text-white bg-[#1AA5C0]"} rounded-lg py-2`}
            >
              {!startCapturing ? (
                <>
                  <MdOutlineCameraAlt size={20} />
                  Capture d'image
                </>
              ) : (
                <>
                  <VscClose color="#FFFFFF" size={20} />
                  Annuler
                </>
                
              )}
            </button>
          </div>
        )}
      <div className="grid justify-items-center">
        <div
          className={`${capturedImages[deviceSelected!] && capturedImages[deviceSelected!].length > 0 ? "grid grid-cols-2 self-center max-h-[27rem] overflow-y-auto gap-3 p-2 pt-5 masked-overflow" : "grid pt-5"}`}
        >
          {capturedImages[deviceSelected!] &&
          capturedImages[deviceSelected!].length > 0 ? (
            capturedImages[deviceSelected!].map((image: any, index: number) => (
              <Card
                placeholder={undefined}
                className="cursor-pointer  overflow-hidden transition-opacity hover:opacity-90 flex-none relative
                "
                // flex overflow-y-auto flex-col mt-40 h-[28rem] gap-2 w-96 items-center
                onClick={() => {
                  if (image) {
                    handleOpen(index, image);
                  } else {
                    // toast.error("image no disponible");
                  }
                }}
                key={`captured-images-${index}`}
              >
                <span className="bg-[#ffffff70] text-[#505050] text-xs font-medium px-1  rounded absolute bottom-2 left-2 z-10 noSelect" >{getExtension(image['name']).toUpperCase()}</span>

                <div className="h-32 w-[100%] flex-shrink-0 img-hover-zoom"  key={`captured-images-zoom-${index}`}>
                  <span>
                    <svg
                      width="38"
                      height="38"
                      viewBox="0 0 37 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.6667 35.6693C27.8714 35.6693 35.3333 28.2074 35.3333 19.0026C35.3333 9.79786 27.8714 2.33594 18.6667 2.33594C9.46192 2.33594 2 9.79786 2 19.0026C2 28.2074 9.46192 35.6693 18.6667 35.6693Z"
                        stroke="#FFFFFF"
                        stroke-width="2.83333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M18.6641 12.3359V25.6693"
                        stroke="#FFFFFF"
                        stroke-width="2.83333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 18.9995H25.3333"
                        stroke="#FFFFFF"
                        stroke-width="2.83333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  {image ? (
                    <FileThumbnail
                      File={image}
                      key={"file-thumbnail-" + index}
                    />
                  ) : (
                    <Spinner  key={`captured-images-spinner-${index}`} />
                  )}
                </div>
              </Card>
            ))
          ) : (
            <NoRecordFound type="custom" icon={NoImageFound} />
          )}
        </div>
      </div>
      {capturedImages[deviceSelected!] && (
        <AiDescriptionModal
          open={open}
          setOpen={setOpen}
          deviceSelected={deviceSelected}
          selectedImage={capturedImages[deviceSelected!][selectedImageIndex]}
          capturedImages={capturedImages[deviceSelected!]}
          selectedImageIndex={selectedImageIndex}
          setCapturedImages={setCapturedImages}
          isConsultation={isConsultation}
          setFiles={setFiles}
        />
      )}
    </>
  );
};

export default ImagesCaptured;
