// CRUD axios for vital signs
// Path: src\axiosAPI\params.ts
// Compare this snippet from src\axiosAPI\Network.ts:

import APP_CONSTANTS from "../constants";
import { vitalSignsType } from "@interfaces/baseParams";
import instance from "./Network";

export const postVitalSigns = async (
  data: any,
  patientId: number
  // consultationId: number
) => {
  try {
    const res = await instance.post(`/vitalSigns`, data);

    return res;
  } catch (error) {}
};
export const patchVitalSigns = async (
  data: any,
  patientId: number
  // consultationId: number
) => {
  try {
    const res = await instance.patch(`/vitalSigns/${patientId}`, data);

    return res;
  } catch (error) {}
};

export const getVitalSigns = async (patientId: number) => {
  try {
    const res = await instance.get(
      `/vitalSigns?where={"patientId":${patientId}}`
    );
    return res.data;
  } catch (error) {}
};

//get specialite of doctor with id
export const getDoctorSpecialite = async (doctorId: number) => {
  try {
    const res = await instance.get(
      `${process.env.REACT_APP_SERVER_URL}/doctor/${doctorId}?include=speciality`
    );
    return res.data?.speciality;
  } catch (error) {}
};
export {};
