// authSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SLICE } from "../../enums/store";
import { IUser } from "../../interfaces/user";
import APP_CONSTANTS from "../../constants";
import { app } from "../../enums/app";
import { RootState } from "src/store";

interface AuthState {
  accessToken: string | null;
  refreshToken: string | null;
  specialityId: any | null;
  user: any;
  currentCallId: string | null;
  onlineUsers: Array<any>;
  currentApp: string | null;
  isConnected: boolean;
  isOnline: boolean;
  access: Array<string>;
  center: any;
  box: any;
  permissions: any;
  isLoading: boolean;
  error: string | null;
  connectedUser: boolean;
}

interface Accessibility {
  id: number;
  name: string;
  host: string;
}

interface Role {
  name: string;
  accessibilities: Array<{
    permission: string;
    resource: Accessibility;
  }>;
}
const getInitialState = (): AuthState => {
  const storedToken = localStorage.getItem(
    `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}token`
  );
  const currentCenter = JSON.parse(
    localStorage.getItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}center`) || "{}"
  );
  const currentBox = JSON.parse(
    localStorage.getItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}box`) || "{}"
  );
  const storedUserInfos: any = localStorage.getItem(
    `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}storedUser`
  );
  const storedRefreshToken = localStorage.getItem(
    `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}refreshToken`
  );
  const currentCall = localStorage.getItem(
    `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}currentCall`
  );
  const storedRoles = localStorage.getItem(
    `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}roles`
  );
  const storedUserId = localStorage.getItem(
    `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}userId`
  );
  const storedDoctorId = localStorage.getItem(
    `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}doctorId`
  );
  const permissions = [
    {
      name: "generalist",
      accessibilities: [
        {
          permission: "all",
          resource: { id: 1, name: app.PATIENT_RECORD, host: "dp" },
        },
        {
          permission: "all",
          resource: { id: 2, name: app.VISIO_STATION, host: "vs" },
        },
        {
          permission: "all",
          resource: { id: 3, name: app.APPOINTMENTS, host: "rdv" },
        },
      ],
    },
  ];

  const initialState: AuthState = {
    accessToken: storedToken,
    onlineUsers: [],
    refreshToken: storedRefreshToken,
    currentApp: null,
    currentCallId: currentCall,
    isConnected: false,
    isOnline: false,
    user: null,
    permissions,
    center: currentCenter,
    box: currentBox,
    isLoading: false,
    access: [],
    error: null,
    connectedUser: !!storedToken, // Set connectedUser to true if token exists in local storage
  };

  if (storedRoles && storedUserId && storedDoctorId) {
    try {
      const roles = JSON.parse(storedRoles);
      initialState.user = {
        ...JSON.parse(
          localStorage.getItem(
            `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}storedUser`
          ) || "{}"
        ),
        roles: roles,
        specialityId: JSON.parse(storedUserInfos).doctor
          ? JSON.parse(storedUserInfos).doctor.specialityId
          : "",
        userId: storedUserId,
        doctor: { id: storedDoctorId },
      };
    } catch (error) {
      console.error("Error parsing stored user data:", error);
    }
  }

  return initialState;
};

const authSlice = createSlice({
  name: SLICE.AUTH,
  initialState: getInitialState(),
  reducers: {
    setCenter: (state, action: PayloadAction<boolean>) => {
      state.center = action.payload;
    },
    setCurrentCallId: (state, action: PayloadAction<string>) => {
      state.center = action.payload;
    },

    setBox: (state, action: PayloadAction<boolean>) => {
      state.box = action.payload;
    },
    setIsConnected: (state, action: PayloadAction<boolean>) => {
      state.isConnected = action.payload;
    },
    setIsOnline: (state, action: PayloadAction<boolean>) => {
      state.isOnline = action.payload;
    },
    setAccess: (state, action: PayloadAction<string[]>) => {
      state.access = action.payload;
    },
    setOnlineUsers: (state, action: PayloadAction<Array<any>>) => {
      state.onlineUsers = action.payload;
    },
    setCurrentApp: (state, action: PayloadAction<string>) => {
      state.currentApp = action.payload;
    },
    loginRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    loginSuccess: (
      state,
      action: PayloadAction<{
        accessToken: string;
        refreshToken: string;
        user: IUser;
      }>
    ) => {
      state.isLoading = false;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.user = action.payload.user;
      state.error = null;

      ///

      ///
      state.onlineUsers = [];
      state.isConnected = true;
      state.isOnline = true;
      state.connectedUser = true;

      // Update the access field
      const allAccessibilities = action?.payload?.user?.roles?.flatMap(
        (role: Role) =>
          role.accessibilities.map(
            (accessibility) => accessibility.resource.name
          )
      );
      state.access = allAccessibilities;
      state.currentApp = allAccessibilities[0];
      state.center = JSON.parse(
        localStorage.getItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}center`) ||
          "{}"
      );
      state.box = JSON.parse(
        localStorage.getItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}box`) || "{}"
      );
      localStorage.setItem(
        `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}storedUser`,
        JSON.stringify(action.payload.user)
      );
    },
    loginFailure: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    logoutRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    logoutSuccess: (state) => {
      state.accessToken = null;
      state.access = [];
      state.refreshToken = null;
      state.user = null;
      state.isConnected = false;
      state.isOnline = false;
      state.isLoading = false;
      state.error = null;
      state.connectedUser = false;
      localStorage.removeItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}storedUser`);
    },
    logoutFailure: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    registerRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    registerSuccess: (
      state,
      action: PayloadAction<{ token: string; user: IUser }>
    ) => {
      state.isLoading = false;
      state.accessToken = null;
      state.refreshToken = null;
      state.user = action.payload.user;
      state.isConnected = true;
      state.isOnline = true;
      state.error = null;
      state.connectedUser = true;
      localStorage.setItem(
        `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}storedUser`,
        JSON.stringify(action.payload.user)
      );

      // Update the access field
      const allAccessibilities = action?.payload?.user?.roles?.flatMap(
        (role: Role) =>
          role.accessibilities.map(
            (accessibility) => accessibility.resource.name
          )
      );
      state.access = allAccessibilities;
      state.currentApp = allAccessibilities[0];
      localStorage.setItem(
        `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}storedUser`,
        JSON.stringify(action.payload.user)
      );
    },
    registerFailure: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    //added by laila
    setCredentials: (state, action) => {
      const { user, accessToken, refreshToken } = action.payload;
      state.user = user;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.isConnected = true;
      localStorage.setItem(
        `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}storedUser`,
        JSON.stringify(action.payload.user)
      );
      localStorage.setItem(
        `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}token`,
        accessToken
      );
      localStorage.setItem(
        `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}refreshToken`,
        refreshToken
      );
    },
    logOut: (state) => {
      state.user = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.isConnected = false;
      localStorage.removeItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}token`);
      localStorage.removeItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}storedUser`);
      localStorage.removeItem(
        `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}refreshToken`
      );
    },
  },
});

export const {
  loginRequest,
  loginSuccess,
  loginFailure,
  logoutRequest,
  logoutSuccess,
  logoutFailure,
  registerRequest,
  registerSuccess,
  setOnlineUsers,
  setCurrentApp,
  setAccess,
  setCenter,
  setBox,
  registerFailure,

  //added by laila
  setCredentials,
  logOut,
} = authSlice.actions;

export default authSlice.reducer;

//added by laila
export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectCurrentAccessToken = (state: RootState) =>
  state.auth.accessToken;
export const selectCurrentRefreshToken = (state: RootState) =>
  state.auth.refreshToken;
