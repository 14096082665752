import React, { useEffect, lazy, Suspense, useState, useMemo } from "react";
import { GlobalStyle } from "../SearchPatient/SearchPatient";
import {
  Avatar,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Option,
  Select,
  Spinner,
  Tooltip,
} from "@material-tailwind/react";
import MyButton from "../common/Button";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Stepper, Step, Button } from "@material-tailwind/react";
import Motif from "./Motif";
import Antecedent from "./Antecedent";
import Traitement from "../Steps/CustomComponents/Traitement";
import Examen from "./Examen";

import {
  getConsultation,
  getPatient,
  updateConsultation,
} from "../../axiosAPI/Network";
import {
  IUpdateConsultation,
  IConsultation,
} from "../../interfaces/Consultation";
import { ConsultationContextProvider } from "../../services/ConsultationContext";
import Steps from "../Steps";
import Navigation from "../Steps/Navigation";
const Content = lazy(() => import("../Steps/Content"));
import { ConsultationForms } from "../../forms/ConsultationForms";
import { CONTENT_TYPE } from "../../enums/stepper";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../assets/logo.png";
import {
  getLocationStatus,
  updateStatusConsultation,
} from "../../features/consultationStatus/consultationStatusSlice";
import { RootState } from "src/store";
import { CONSULTATION_STATUSES, EXIT_MODES } from "../../enums/consultation";
import useDynamicDialog from "../../hooks/useDynamicDialog";
import { setConsultationMetadata } from "../../features/consultationMetadata";
import {
  ArrowPathIcon,
  CloudArrowDownIcon,
  CloudArrowUpIcon,
  CloudIcon,
} from "@heroicons/react/24/outline";
import {
  useGetConsultationDataQuery,
  useLazyGetConsultationDataQuery,
  useUpdateConsultationDataMutation,
} from "../../features/consultationData/consultationDataApi";
import { setCurretStep } from "../../features/currentStep/currentStepSlice";
import { setTimer } from "../../features/consultationCounter/consultationCounterSlice";
import moment from "moment";
import {
  clearConsultationData,
  setConsultationDataCheck,
  setIsFirstCommmit,
  spliceDignosticPrincipal,
} from "../../features/consultationData/consultationDataSlice";
import { setIsLoading } from "../../features/dialog/dialogSlice";
import { toast } from "react-hot-toast";
import { useGetMedicalActsQuery } from "../../features/staticData/staticDataApi";
import SearchableInput from "../common/SearcheableSelect";
import { CHIEF_COMPLAINT_TYPE } from "../../enums/chiefComplaintConsultations";
import { clearMotifs } from "../../features/motifs/motifsSlice";
import { clearConsultationStore } from "../../clearConsultationstore";

const Stepss = () => {
  const navigate = useNavigate();
  const currentStep = useSelector(
    (state: any) => state.currentStep.currentStep
  );
  const previousStep = useSelector(
    (state: any) => state.currentStep.previousStep
  );
  const { message, title, openDynamicDialog, closeDynamicDialog } =
    useDynamicDialog();
  const [
    getConsultationServerData,
    {
      isLoading: isLoadingConsultationServerData,
      isSuccess: isSuccessConsultationServerData,
      isError: isErrorConsultationServerData,
      isFetching: isFetchingConsultationServerData,
    },
  ] = useLazyGetConsultationDataQuery();
  const [
    setConsultationData,
    { isLoading: isPatchLoading, isError: isPatchError, isSuccess },
  ] = useUpdateConsultationDataMutation();
  const [isFetchOrUpdatConsultData, setIsFetchOrUpdatConsultData] =
    useState<boolean>(isPatchLoading);
  const [activeStep, setActiveStep] = useState(0);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(false);
  const [consultationId, setConsultationId] = useState<number>(0);
  const [returnedStep, setReturnedStep] = useState<boolean>(false);
  const [consultationForm, setConsultationForm] = useState<IUpdateConsultation>(
    {}
  );
  const [isSavingConsultation, setIsSavingConsultation] =
    useState<boolean>(false);

  const [medicalActConsultations, setMedicalActConsultations] = useState<
    Array<{ medicalActId: number }>
  >([]);

  const [consultationInfos, setConsultationInfos] = useState<any>({
    motifComplaint: [],
    medicalHistory: {
      medical: [],
      surgical: [],
      hospitalizations: [],
      toxic: [],
      obstetric: [],
      family: [],
      allergy: [],
      patient: { id: 0 },
    },
    medicationHistory: [],
    conclusion: "",
    chiefComplaintConsultations: [],
    medicationConsultations: [],
    analyseConsultation: [],
    imagingConsultations: [],
  });
  const [consultation, setConsultation] = useState<IConsultation | null>(null);
  const [patientId, setPatientId] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [patient, setPatient] = useState<any>();
  const [exitMode, setExitMode] = useState<string>(EXIT_MODES.NORMAL);
  const [confirm, setConfirm] = useState<any>(false);
  const [showImpression, setShowImpression] = useState<any>(false);
  const location = useLocation();
  const params = useParams();
  const consultationIdParams = params.consultationId;
  const [age, setAge] = useState<number>(0);
  const [ageText, setAgeText] = useState<string>("ans");

  const consultationCheck = useSelector(
    (state: RootState) => state.consultationData.consultationCheck
  );

  const consultationMetadata = useSelector(
    (state: RootState) => state.consultationMetadata
  );

  const isFirstCommit = useSelector(
    (state: RootState) => state.consultationData.isFirstCommit
  );
  const { data: consultationQueryData } =
    Number(params?.consultationId) !== 0
      ? useGetConsultationDataQuery(params.consultationId)
      : { data: undefined };
  const { consultationId: consultId } = useParams();
  const consultationData = useSelector(
    (state: any) => state.consultationData.consultationData
  );
  const motifs = useSelector((state: any) => state.motifs.motifs);
  const storedMedicalConsults = useSelector(
    (state: any) => state.medicationConsultation.medicationConsults
  );
  const locationStatus = useSelector(
    (state: RootState) => state.consultationStatus.locationStatus
  );
  const dispatch = useDispatch();
  const locations = useLocation();
  useEffect(() => {
    setPatientId(parseInt(params.id || "", 10));
    setConsultationId(parseInt(params.consultationId || "", 10));
    dispatch(
      setConsultationMetadata({
        consultationId: parseInt(params.consultationId || "", 10),
        patientId: parseInt(params.id || "", 10),
      })
    );
  }, [location]);

  useEffect(() => {
    (async () => {
      const { data = null } = consultationId
        ? await getConsultation(consultationId)
        : {};
      setConsultation(data);
      dispatch(setConsultationDataCheck(data));
    })();
  }, [consultationId]);

  useEffect(() => {
    (async () => {
      const { data = null } = patientId ? await getPatient(patientId) : {};
      setPatient(data);
    })();
  }, [patientId]);

  // WATCH CONSULTATION CHANGE (WHICH IS COMMING FROM SERVER)
  useEffect(() => {
    if (consultation) {
      const isConsultationClosed = !!consultation.endTime;
      const isTeleexpertiseConsultation = !!consultation.specialistId;

      // if the consultation is already closed, then redirect the user to the patient folder
      // and its not a tele expertise consultation
      if (isConsultationClosed && !isTeleexpertiseConsultation) {
        openDynamicDialog({
          message: `La consultation est déja clôturé, vous serez rediriger vers le dossier du patient`,
          handleConfirm: null,
          handleCancel: null,
          title: "Consultation clôturé",
        });

        setTimeout(() => {
          window.location.href = `/doctor/patient/${consultation.patientId}`;
        }, 3000);
      }
      const currentStep =
        consultation.steps[consultation.steps.length - 1]?.index;
      setActiveStep(currentStep);
    }
  }, [consultation]);

  useEffect(() => {
    // console.log("booster");
    if (patient) {
      const today = new Date();
      const birthDate = new Date(patient.user.birthDate);

      const ageInMonths =
        (today.getFullYear() - birthDate.getFullYear()) * 12 +
        (today.getMonth() - birthDate.getMonth());
      const ageInDays = Math.floor((today - birthDate) / (1000 * 60 * 60 * 24));
      const ageInYears = today.getFullYear() - birthDate.getFullYear();

      if (ageInYears > 0) {
        setAge(ageInYears);
        setAgeText("ans");
      } else if (ageInMonths > 0) {
        setAge(ageInMonths);
        setAgeText("mois");
      } else {
        setAge(ageInDays);
        setAgeText("jours");
      }
    }
  }, [patient]);

  const handleQuitConsultation = async () => {
    dispatch(setIsLoading(true));

    await updateConsultation(
      {
        endTime: new Date(),
        indexStep: currentStep,
        exitMode: exitMode,
        medicalActConsultations: medicalActConsultations,
      },
      consultationId
    );

    dispatch(updateStatusConsultation(CONSULTATION_STATUSES.ENDED));
    dispatch(setCurretStep(0));
    dispatch(setIsLoading(false));
    dispatch(clearConsultationData());
    localStorage.removeItem("currentConsultationVitalSigns")
    localStorage.removeItem("consultationInfos")

    window.location.href = `/doctor/patient/${patientId}`;
    clearConsultationStore(dispatch);
  };

  function debounce<T extends (...args: any[]) => void>(
    func: T,
    delay: number
  ) {
    let timer: ReturnType<typeof setTimeout>;
    return function (this: any, ...args: Parameters<T>) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  const handleExitModeChange = (e: any) => {
    setExitMode(e);
  };

  /**
   *
   */
  const handleCancelButtonClick = async () => {
    setIsSavingConsultation(true);
    if (consultationIdParams) {
      const consultationData = await getConsultationServerData(
        Number(consultationIdParams)
      );
      if (
        consultationData?.isSuccess &&
        (!consultationData?.data?.conclusion ||
          consultationData?.data?.conclusion.split(" ").join("") === "")
      ) {
        toast.error("Veuillez saisir le compte rendu", {
          id: "1",
          duration: 3000,
        });
      } else if (
        consultationData?.isSuccess &&
        consultationData?.data?.conclusion
      ) {
        setIsOpen(true);
      }
    }
    setIsSavingConsultation(false);
  };

  useEffect(() => {
    dispatch(getLocationStatus(locations.pathname));
  }, []);

  useMemo(async () => {
    if (!isFirstCommit) {
      // TO INFORM SYSTEM THAT THERE IS AN ON GOING CONSUTLATION
      dispatch(updateStatusConsultation(CONSULTATION_STATUSES.ON_GOING));
      // SET THE INCOMMING CONSULTATION OBJECT

      //this was throwing server error

      // await setConsultationData({
      //   id: consultId,
      //   body: {
      //     ...consultationData,
      //     indexStep: currentStep,
      //     lastTimeDuration: new Date(),
      //   },
      // });
    } else dispatch(setIsFirstCommmit(false));
  }, [consultationData]);

  // save the consultation button clicked save, an update to avoid the hight number of patch requests
  const saveConsultationData = async () => {
    if (!isFetchOrUpdatConsultData) {
      let step = currentStep;
      setIsSavingConsultation(true);
      setIsFetchOrUpdatConsultData(true);
      let consultationCopy = consultationData;
      if (step === 0)
        consultationCopy = {
          motifComplaint: motifs.length > 0 ? motifs : [],
          // consultationData?.motifComplaint?.length > 0
          //   ? consultationData?.motifComplaint
          //   : [],
        };
      if (step === 6)
        consultationCopy = {
          ...consultationCopy,
          medicationConsultations: storedMedicalConsults,
        };
      dispatch(
        setConsultationDataCheck({ ...consultationCheck, ...consultationCopy })
      );
      console.log(
        "save consultationData { ...consultationCheck, ...consultationCopy }",
        {
          ...consultationCheck,
          ...consultationCopy,
        }
      );
      console.log("save consultationData", consultationCopy);

      await setConsultationData({
        id: consultId,
        body: {
          ...consultationCopy,
          indexStep: step,
          lastTimeDuration: new Date(),
        },
      });
      setIsFetchOrUpdatConsultData(false);
      setTimeout(() => {
        setIsSavingConsultation(false);
      }, 1000);
    }
  };

  const handleLoadingConsultationData = (isLoading: boolean) => {
    // console.log("handleLoadingConsultationData", isLoading);
    setIsFetchOrUpdatConsultData(isLoading);
  };

  useEffect(() => {
    consultationQueryData?.createdAt &&
      dispatch(
        setTimer(
          moment(new Date()).diff(
            moment(consultationQueryData?.createdAt),
            "seconds"
          )
        )
      );
  }, [consultationQueryData?.createdAt]);

  useEffect(() => {
    // console.log("success check", isSuccess, isPatchError);
    if (isSuccess)
      toast.success("Les données ont été bien enregistrées", { id: "1" });
    if (isPatchError)
      toast.error(
        "Une erreur est survenue lors de l'enregistrement, veuillez réessayer",
        { id: "2" }
      );
  }, [isSuccess, isPatchError]);

  return (
    <ConsultationContextProvider consultationId={consultationId}>
      <GlobalStyle>
        <div className="flex flex-col items-center justify-start h-full px-4 py-2  ">
          <div className="header w-full bg-[#20A8D31C] rounded-lg px-2 py-2 border border-[#20A8D3] ">
            <div className="info flex justify-between items-center ">
              <div className="info flex items-center ">
                <Avatar
                  src="/user.svg"
                  alt="avatar"
                  variant="circular"
                  className="mr-5"
                />
                <div className="text flex flex-wrap">
                  <div className="name w-[50%] text-lg text-[#1AA5C0] font-[500]">
                    {`${patient?.user?.firstName} ${patient?.user?.lastName}`}
                  </div>
                  <div className="birth w-[50%] text-[#1AA5C0] font-[500]">
                    Né(e) le {`${patient?.user?.birthDate?.split("T")[0]}`}
                  </div>
                  <div className="sexe w-[50%] text-[#5E5E5E]  font-[500]">
                    Sexe : {patient?.user?.gender == "male" ? "Homme" : "Femme"}
                  </div>
                  <div className="age w-[50%] text-[#1AA5C0] font-[500]">
                    {`${age}`} {ageText}
                  </div>
                </div>
              </div>
              <div className="buttons flex  gap-5 ">
                {/* <MyButton

                  className="bg-[#1AA5C0] w-96"
                  text="Programmer une téléconsultation"
                  color="bg-primary text-white "
                  icon={<DoctorSvg />}
                  rounded={true}
                  onClick={() => navigate(`/doctor/calendar/${patientId}`)}
                /> */}
                <Button
                  disabled={isFetchOrUpdatConsultData}
                  onClick={saveConsultationData}
                  className=" bg-primary"
                >
                  Enregistrer
                </Button>
                {isPatchLoading ? (
                  <div className="flex text-gray-600 justify-center items-center self-center gap-2">
                    <ArrowPathIcon className="w-6 cursor-wait animate-spin" />
                    <p> Enregistrement des données en cours</p>
                  </div>
                ) : isSuccess ? (
                  <div className="flex text-gray-600 justify-center items-center self-center gap-2">
                    <CloudArrowDownIcon className="w-6" />
                    <p> Données mises à jour</p>
                  </div>
                ) : (
                  <div className="flex text-gray-600 justify-center items-center self-center gap-2">
                    <CloudArrowDownIcon className="w-6" />
                    <p> Données non enregistrées</p>
                  </div>
                )}
                {/* this component is over charging the memory cause of the setInterval */}
                {/* <div className="flex items-center gap-2">
                  <Counter width={30} />
                  <h1 className="text-[#5E5E5E] text-xl">
                    <Timer start={consultationCounter} />
                  </h1>
                </div> */}

                <MyButton
                  text="Clôturer la consultation"
                  color="bg-[#FF375E] text-white"
                  // icon={<DoctorSvg />}
                  rounded={true}
                  onClick={handleCancelButtonClick}
                />
              </div>
            </div>
          </div>

          <div className="w-full m-auto h-full py-4 px-8">
            {isSavingConsultation && (
              <div className="absolute left-0 top-0 w-full h-full bg-[#fdfdfd82] flex justify-center items-center bg-bleu z-20">
                <Spinner className="h-10 w-10" color="blue" />
              </div>
            )}
            <Steps
              movingToAnotherStep={() => {
                console.log("ismoving to another step");
                saveConsultationData();
              }}
              loadingConsultationData={handleLoadingConsultationData}
              items={[
                {
                  id: 0,
                  title: "Motif de consultation",
                  component: (
                    <Suspense
                      fallback={
                        <div className="w-full flex justify-center content-center self-center">
                          <img
                            src={Logo}
                            width={300}
                            className="animate-bounce mt-12"
                          />
                        </div>
                      }
                    >
                      <Content
                        type={CONTENT_TYPE.FORM}
                        data={ConsultationForms["motifConsultation"]}
                      />
                    </Suspense>
                  ),
                },
                {
                  id: 1,
                  title: "Antécédents",
                  component: (
                    <Suspense
                      fallback={
                        <div className="w-full flex justify-center content-center self-center">
                          <img
                            src={Logo}
                            width={300}
                            className="animate-bounce mt-12"
                          />
                        </div>
                      }
                    >
                      <Content
                        type={CONTENT_TYPE.FORM}
                        data={ConsultationForms["antecedent"]}
                      />
                    </Suspense>
                  ),
                },
                {
                  id: 2,
                  title: "Traitements en cours",
                  component: (
                    <Suspense
                      fallback={
                        <div className="w-full flex justify-center content-center self-center">
                          <img
                            src={Logo}
                            width={300}
                            className="animate-bounce mt-12"
                          />
                        </div>
                      }
                    >
                      <Content
                        type={CONTENT_TYPE.CUSTOM_COMPONENT}
                        component={
                          <Traitement
                            consultationId={consultationId}
                            consultationInfos={""}
                          />
                        }
                      />
                    </Suspense>
                  ),
                },
                {
                  id: 3,
                  title: "Examen clinique",
                  component: (
                    <Suspense
                      fallback={
                        <div className="w-full flex justify-center content-center self-center">
                          <img
                            src={Logo}
                            width={300}
                            className="animate-bounce mt-12"
                          />
                        </div>
                      }
                    >
                      <Content
                        type={CONTENT_TYPE.CUSTOM_COMPONENT}
                        component={<Examen />}
                      />
                    </Suspense>
                  ),
                },
                {
                  id: 4,
                  title: " Assistant IA",
                  component: (
                    <Suspense
                      fallback={
                        <div className="w-full flex justify-center content-center self-center">
                          <img
                            src={Logo}
                            width={300}
                            className="animate-bounce mt-12"
                          />
                        </div>
                      }
                    >
                      <Content
                        type={CONTENT_TYPE.FORM}
                        data={ConsultationForms["diagnostic"]}
                      />
                    </Suspense>
                  ),
                },
                {
                  id: 5,
                  title: "Compte-rendu et diagnostic",
                  component: (
                    <Suspense
                      fallback={
                        <div className="w-full flex justify-center content-center self-center">
                          <img
                            src={Logo}
                            width={300}
                            className="animate-bounce mt-12"
                          />
                        </div>
                      }
                    >
                      <Content
                        type={CONTENT_TYPE.FORM}
                        data={ConsultationForms["compteRendu"]}
                      />
                    </Suspense>
                  ),
                },
                {
                  id: 6,
                  title: "Prescription",
                  component: (
                    <Suspense
                      fallback={
                        <div className="w-full flex justify-center content-center self-center">
                          <img
                            src={Logo}
                            width={300}
                            className="animate-bounce mt-12"
                          />
                        </div>
                      }
                    >
                      <Content
                        type={CONTENT_TYPE.FORM}
                        data={ConsultationForms["prescription"]}
                      />
                    </Suspense>
                  ),
                },
              ]}
            />

            <Navigation
              isFirstStep={useSelector(
                (state: any) => state.currentStep.isFirstStep
              )}
              isLastStep={useSelector(
                (state: any) => state.currentStep.isLastStep
              )}
            />
          </div>
        </div>

        <Dialog size="lg" open={isOpen} handler={() => {}}>
          <DialogHeader className=" h-10 text-primary text-md">
            Êtes-vous sûr de vouloir clôturer la consultation ?
          </DialogHeader>
          <DialogBody
            style={{ minHeight: "20rem" }}
            className="py-6 text-[#0F2552] font-semibold text-sm"
            divider
          >
            <>
              {/* exit mode */}
              <p> Veuillez choisir le mode de sortie:</p>
              <br />
              <div>
                <Select onChange={handleExitModeChange} label={"Selectionnez le mode de sortie"}>

                  <Option value={EXIT_MODES.NORMAL} defaultChecked>Retour à domicile</Option>
                  <Option value={EXIT_MODES.TRANSFERT}>Transfert</Option>
                  <Option value={EXIT_MODES.EMERGENGY}>Hospitalisation</Option>
                  <Option value={EXIT_MODES.ADDITIONAL_ANALYSIS_OUTSIDE_CABIN}>Examens Complimentaires hors-cabine</Option>
                  <Option value={EXIT_MODES.AGAINST_MEDICAL_ADVICE}>Contre Visite</Option>

                </Select>
              </div>
              {/* end */}

              <div className="mt-5">
                <h2>Veuillez choisir les actes médicaux présentés:</h2>

                <SearchableInput
                  setValue={(values) => {
                    setMedicalActConsultations(
                      values.map((val: number) => ({ medicalActId: val }))
                    );
                  }}
                  multiple={true}
                  hookParams={["search"]}
                  searchHook={useGetMedicalActsQuery}
                  name={"searchMedicalActs"}
                  label={""}
                  dataState={""}
                  style={"h-10 w-full"}
                />
              </div>
            </>
          </DialogBody>
          <DialogFooter className="">
            <Button
              color="red"
              className="mr-1 bg-[#858585]"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <span>Annuler</span>
            </Button>

            <Button
              className="bg-secondary"
              color="teal"
              onClick={handleQuitConsultation}
            >
              <span>Confirmer</span>
            </Button>
          </DialogFooter>
        </Dialog>
      </GlobalStyle>
    </ConsultationContextProvider>
  );
};

export default Stepss;
