import { useCallback, useState } from 'react';

interface UndoableState<T> {
  past: T[];
  present: T;
  future: T[];
}

export default function useUndoableState<T>(initialValue: T): [T, (newState: T) => void, () => void, () => void] {
  const [history, setHistory] = useState<UndoableState<T>[]>([{
    past: [],
    present: initialValue,
    future: []
  }]);

  const setState = useCallback((newState: T) => {
    setHistory(currentHistory => {
      const { past, present } = currentHistory[0];
      return [{ past: [...past, present], present: newState, future: [] }];
    });
  }, []);

  const undo = useCallback(() => {
    setHistory(currentHistory => {
      const { past, present, future } = currentHistory[0];
      if (past.length === 0) return currentHistory; // Nothing to undo

      const newPresent = past[past.length - 1];
      const newPast = past.slice(0, past.length - 1);

      return [{ past: newPast, present: newPresent, future: [present, ...future] }];
    });
  }, []);

  const redo = useCallback(() => {
    setHistory(currentHistory => {
      const { past, present, future } = currentHistory[0];
      if (future.length === 0) return currentHistory; // Nothing to redo

      const newPresent = future[0];
      const newFuture = future.slice(1);

      return [{ past: [...past, present], present: newPresent, future: newFuture }];
    });
  }, []);

  return [history[0].present, setState, undo, redo];
}
