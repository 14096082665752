import React, { ReactNode } from 'react';

interface PatientInfo {
    patientName: string;
    patientId: string;
    patientBD: string;
    patientGender: string;
}

interface Study {
    studyDescription: String,
        protocolName: String,
        studyDate: String,
        studyTime: String,
        bodyPart: String
}

interface DicomDataPanelProps {
    patientInfo: PatientInfo;
    study: Study;
}

const Names={
    patientId:'ID patient',
    patientName:'Nom du patient',
    patientGender:'Sexe',
    studyDescription:'Description',
    studyDate:'Date',
    studyTime:'à',
    bodyPart:'Partie de corps'

}

export default function DicomDataPanel({ patientInfo, study }: DicomDataPanelProps): JSX.Element {


    const parseDateTime=(dateString, timeString) =>{
        // Parse date components
        const year = dateString.substring(0, 4);
        const month = dateString.substring(4, 6);
        const day = dateString.substring(6, 8);
        
        // Parse time components
        const hours = timeString.substring(0, 2);
        const minutes = timeString.substring(2, 4);
        const seconds = timeString.substring(4, 6);
        
        // Create a Date object
        const dateTime = new Date(`${year}-${month}-${day}T${hours}:${minutes}:${seconds}`);
        
        return dateTime.toLocaleDateString()+' à '+dateTime.toLocaleTimeString();
    }

    
    const getPatientInfo = (): JSX.Element[] => {
        const filteredPatientInfo = Object.entries(patientInfo).filter(([key]) => {
            return key === 'patientName' || key === 'patientId' || key === 'patientGender';
        });

        return filteredPatientInfo.map(([key, value], index) => {
            return   (value.trim()=="")?  <></> :   <li key={`patientLi-${index}`}><strong>{Names[key]}: </strong> {value}</li>;
        });
    }

    const getStudy = (): JSX.Element[] => {
        const filteredStudy = Object.entries(study).filter(([key]) => {
            return key === 'studyDate' || key === 'bodyPart';
        });

        return filteredStudy.map(([key, value], index) => {
            return  (value.trim()=="") ?  <></> : ((key=='studyDate')? <li key={`studyLi-${index}`}><strong>{Names[key]}: </strong><> {parseDateTime(value, study.studyTime)}</></li>:<li key={`studyLi-${index}`}><strong>{Names[key]}: </strong> {value}</li> );
        });
    }

    return (
        <>
            <ul >
            {getPatientInfo().map((element, index) => (
                    <React.Fragment key={`patientInfo-${index}`}>
                        {element}
                    </React.Fragment>
                ))}
                {getStudy().map((element, index) => (
                    <React.Fragment key={`study-${index}`}>
                        {element}
                    </React.Fragment>
                ))}
            </ul>
        </>
    );
}
