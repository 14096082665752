// slices/counterSlice.ts

import { SLICE } from "../../../enums/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

//
export interface IBasicDevicesMeasuresState {
  isMeasuringStarted: boolean,
  isMeasuringEnded: boolean
}
 
const initialState: IBasicDevicesMeasuresState = {
  isMeasuringStarted: false,
  isMeasuringEnded: true
};
const basicDevicesMeasuresSlice = createSlice({
    name: SLICE.BASIC_DEVICES_MEASURES,
    initialState,
    reducers: {
      startMeasuring: (state) => {
        state.isMeasuringStarted = true;
      },
      endMeasuring: (state) => {
        state.isMeasuringEnded = true;
        state.isMeasuringStarted = false;

      },
    
  
 
    },
  });

export const { startMeasuring, endMeasuring } =
  basicDevicesMeasuresSlice.actions;
export const basicDevicesMeasuresReducer = basicDevicesMeasuresSlice.reducer;
 