// slices/counterSlice.ts

import { SLICE } from "../../../enums/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SidebarState {
  availableServices: [];
  availableSensors: string[];
}

const initialState: SidebarState = {
  availableServices: [],
  availableSensors: [],
};

const availableSensors_ServicesSlice = createSlice({
  name: SLICE.SENSORS_SERVICES,
  initialState,
  reducers: {
    setAvailableServices(state, action: PayloadAction<[]>) {
      console.log(state.availableServices, action.payload);
      state.availableServices = action.payload;
    },
    setAvailableSensors(state, action: PayloadAction<string[]>) {
      console.log(state.availableServices, action.payload);
      state.availableSensors = action.payload;
    },
  },
});

export const { setAvailableServices, setAvailableSensors } =
  availableSensors_ServicesSlice.actions;
export const availableSensors_ServicesReducer = availableSensors_ServicesSlice.reducer;
// selectors.js
export const selectAvailableSensors = (state: any) =>
  state.availableSensors_Services.availableSensors;
