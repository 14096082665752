export default function DicomDatSetParser(dataset) {
  /**
   *  Patient Name: x00100010
   *  Patient ID: x00100020
   *  Patient Birth Date: x00100030
   *  Patient Gender: x00100040
   *  Study Description: x00081030
   *  Protocol Name: x00181030
   *  Study Date: x00080020
   *  Study Time: x00080030
   *  Body Part: x00180015
   *  Photometric Interpretation: x00280004
   *  Image Type: x00080008
   *  Manufacturer: x00080070
   *  Model: x00081090
   *  Station Name: x00081010
   *  Institution Name: x00080080
   *  Software Version: x00181020
   *  Pixel Data: x7fe00010
   * **/

  const safeToString = (attr) => {
    const element = dataset.elements[attr];
    let text = "";
    /*if (element !== undefined) {
      if (element.length === 2) {
        text += dataset.uint16(attr);
      } else if (element.length === 4) {
        text += dataset.uint32(attr);
      }
    }*/

    if(element !== undefined)
        {
            const str = dataset.string(attr);
            if(str !== undefined) {
                text = str;
            }
        }

    return text
  };

  console.log('DICOM DATA')

  console.log(dataset)

  if (dataset == null) {
    return {
      patientInfo: {
        patientName: "",
        patientId: "",
        patientBD: "",
        patientGender: "",
      },
      study: {
        studyDescription: "",
        protocolName: "",
        studyDate: "",
        studyTime: "",
        bodyPart: "",
      },
      image: {
        pixelData: "",
        photometricInterpretation: "",
        imageType: "",
        manufacturer: "",
        model: "",
        softwareVersion: "",
      },
      location: {
        institution: "",
        station: "",
      },
    };
  }
  return {
    patientInfo: {
      patientName: safeToString("x00100010"),
      patientId: safeToString("x00100020"),
      patientBD: safeToString("x00100030"),
      patientGender: safeToString("x00100040"),
    },
    study: {
      studyDescription: safeToString("x00081030"),
      protocolName: safeToString("x00181030"),
      studyDate: safeToString("x00080020"),
      studyTime: safeToString("x00080030"),
      bodyPart: safeToString("x00180015"),
    },
    image: {
      pixelData: safeToString("x7fe00010"),
      photometricInterpretation: safeToString("x00280004"),
      imageType: safeToString("x00080008"),
      manufacturer: safeToString("x00080070"),
      model: safeToString("x00081090"),
      softwareVersion: safeToString("x00181020"),
    },
    location: {
      institution: safeToString("x00080080"),
      station: safeToString("x00081010"),
    },
  };
}
