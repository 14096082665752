// mySkinAiApi.ts
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Rest of your code remains the same
export const mySkinAiApi = createApi({
  reducerPath: "mySkinAiApi",
  baseQuery: fetchBaseQuery({
    baseUrl:
      process.env.REACT_APP_MYSKINAI_URL || "https://myskinai.visiostation.com",
  }), // Set your base URL here
  endpoints: (builder) => ({
    getAiResults: builder.mutation<any, FormData>({
      query: (formData) => ({
        url: "/upload",
        method: "POST",
        body: formData,
      }),
    }),
  }),
});

export const { useGetAiResultsMutation } = mySkinAiApi;
