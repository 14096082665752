export enum ExeName {
  ECG = "ECG",
  ECHO = "ECHO",
}


export enum connectedBasicDeviceIds {
TEMPERATURE= "temperature",
GLYCEMIE= "bloodSugar",
RESPIRATORY_RATE= "respiratoryRythm",
BLOODPRESURE= "nibp",
OXYMETRY= "spo2",
WEIGHT_HEIGHT="height",
}

export enum BaseParamsDeviceIds {
SYSTOLIC= "sys",
DIASTOLIC= "dia",
SPO2= "spo2",
CARDIAC_RATE= "pulse",
HEIGHT="height",
WEIGHT="weight",
IMC="imc",
}

export enum AgentStatus {
  Idle = "Idle", // The agent is not actively performing any tasks and is ready for new tasks.
  Connecting = "Connecting", // The agent is in the process of establishing connections with devices.
  Connected = "Connected", // The agent has successfully connected to all devices and is ready to perform tasks.
  Disconnected = "Disconnected", // The agent has lost connection to one or more devices.
  Processing = "Processing", // The agent is actively processing data or performing tasks with the connected devices.
  Error = "Error", // An error or issue has occurred in the agent's operation.
  UpdatingFirmware = "Updating Firmware", // The agent is in the process of updating the firmware of connected devices.
  Calibrating = "Calibrating", // The agent is calibrating the devices.
  Maintenance = "Maintenance", // The agent is undergoing maintenance or routine checks.
  Offline = "Offline", // The agent is intentionally taken offline for maintenance or other reasons.
  Unknown = "Unknown", // The status is unknown or not yet determined.
}

export enum AgentNibpStatus {
  test_finished = 0, //the data returned is the final result
  test_ongoing = 1, //the data not finished yet
  test_stopped = 2, //uncklick the button test
  over_pressure_protected = 3, //
  cuff_leak_or_unattached = 4, //fuit ou brassard non attaché
  test_timeout = 5, //echeque de la mesur
  test_error = 6, //echeque de la mesureany
  disturb_detected = 7, //patient est entrain de bouger
  result_OutofRange = 8, // the range depends on the patient's age
  initializing = 9, // NaN
  initialized = 10, // NaN
}
export enum AgentOxyStatus {
  SPO2_is_normal = 0,
  Sensor_is_off = 1,
  No_finger_inserted = 2,
  Searching_for_value = 3, //after the detection of the finger start the measure
  SPO2_is_abnormal_timeOut = 4, //proximetly 7s
  //
}
